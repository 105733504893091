<template>
  <div>
  <div class="row">
    <div class="d-flex w-100 justify-content-between align-items-start flex-vmax">

      <div class='landing-heading sohne-var text-left d-block px-5 d-flex flex-column pb-5 display-0r lh-1' style='margin-top:-0.2em'>
        <span class=''>collect</span>
        <span style='font-size: 97%' class=''><span class='op-50'>&</span>trade </span>
        <span style='font-size: 112%; white-space: nowrap;' class='d-inline-block'>AI art<span class='op-50'>.</span></span> 
        <button class='d-none font-weight-bold mt-auto btn btn-primary btn-lg btn-block w-100 text-uppercase'>reserve your spot ➤➤➤</button>
      </div>

      <swiper class='swiper edition-swiper pb-5 w-100' :options='swiperOption' ref='editionSwiper'>
        <swiper-slide v-for='edition in latest' :key='edition.edition'>
          <gallery-card :edition="edition" :edition-number="edition.edition" :nolazy="true" :show-price="false"></gallery-card>
        </swiper-slide>
        <br />
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <div style="font-size: 5em; display:none !important" class="d-flex flex-column lh-1 bodoni flex-grow-1 align-items-center w-100 mb-2 mt-1">
        <span class="" style="opacity: 0.5; font-size: 90%; left: 0.03em; position: relative;">welcome to</span>
        <span class="" style="font-size: 90%; left: 0.03em; position: relative;"><span style='opacity:0.5'>the</span> market</span>
        <span class="" style="font-size: 90%; left: 0.03em; position: relative;"><span style='opacity:0.5'>for</span> AI ART</span>
      </div>
      <div class='d-none'>
        <div style="font-size: 2.5em" class='flex-grow-1 d-flex flex-column align-items-center w-100 mt-1'>
          <p class='text-uppercase'>
            Limited Supply
          </p>
          <p class='text-uppercase'>
            one-of-a-kind
          </p>
          <span style="font-size: 100%" class='d-none h1r text-uppercase'>Collect</span>
          <p style="font-size: 60%" class='d-none text-center'>
            Unique (1 of 1) AI artpieces are auctioned off every 30 minutes.
          </p>
          <button class='d-none btn btn-primary rounded btn-block w-75'>Start Collecting</button>
        </div>

        <div style="font-size: 2.5em" class='flex-grow-1 d-flex flex-column justify-content-between align-items-center w-100 mb-4'>
          <span style="font-size: 100%" class='text-uppercase'>Open Market</span>
          <span style="font-size: 100%" class='text-uppercase'>benefits charity</span>
          <p style="font-size: 60%" class='text-center d-none'>Artpieces from your collection can be auctioned or sold at any time.</p>
          <button class='d-none btn btn-primary rounded btn-block w-75'>Browse Market</button>
        </div>
      </div>
     
    </div>
  </div>


  <div class='w-100 text-center sohne-var section'>
    <hr class='d-block my-4 w-50' />
    <p class='text-center my-4 align-self-center font-weight-light futura-light h4r'>
      If being a trader and collector of AI art sounds fun to you, <br/>
      sign up to be first to know when the marketplace launches.
    </p>

    <email-form class='col-lg-6 mx-auto' cta="Get in early"></email-form>

    <!--
    <b-input-group class='col-6 mx-auto mb-2'>
      <input v-model='form.email' class='form-control' placeholder='your email here' />
      <b-button @click="saveEmail()" type="button" variant="primary">
        Get in early 
      </b-button>
    </b-input-group>

    <span class='h6r futura-light font-weight-light mt-4 text-muted'>max 1 - 4 emails/month. unsubscribe anytime.</span>
    -->
  </div>

  <div class="row section section-curator">
    <div class="d-flex flex-column w-100 justify-content-between align-items-center">
      <hr class='d-block w-50' />
      <div class='d-flex flex-vmax'>
        <!--<div style='width:100%; max-width: 85vw;' class='fade-edge order-p-1'></div>-->

        <picture class='order-p-1'>
          <source srcset='static/landing/watercolor-frames.webp' type="image/webp">
          <source srcset='static/landing/watercolor-frames.png' type="image/png">
          <img width="400" height="400" src='static/landing/watercolor-frames.webp' />
        </picture>

        <div class='display-2r sohne-var text-left d-block d-flex flex-column lh-1 order-p-0'>
          <span style='white-space: nowrap' class='align-self-end my-3'>2% to curators<span class='op-50'>.</span></span>
          <div class='text-left my-2 align-self-start font-weight-lighter futura' style='font-size: 40%; line-height: 1.25;'>
            Being the first owner of an artpiece makes you its curator. 
            <br/><br/>
            2% of every future sale goes to you and 0.2% will go to charity.
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="row section section-mint mb-2">
    <div class="d-flex flex-column w-100 justify-content-between align-items-center">
      <hr class='d-block my-4 w-50' />

      <div class='d-flex flex-vmax'>

        <div class='display-2r sohne-var text-left d-block d-flex flex-column lh-1 w-100'>
          <span style='white-space: nowrap' class=''><span>limited supply</span><span class='op-50'>.</span></span>
          <div class='text-left mt-4 align-self-start font-weight-lighter futura' style='font-size: 40%; line-height: 1.25;'>
            Only 25 artpieces minted per day. Each piece is one-of-a-kind.  
            <br/><br/>
            Auctions start every ~60 minutes and last for 48 hours.
          </div>
        </div>

        <picture style='margin-bottom: -1em'>
          <source srcset='static/landing/mint.webp' type="image/webp">
          <source srcset='static/landing/mint.png' type="image/png">
          <img width="400" height="400" src='static/landing/mint.webp' />
        </picture>

      </div>
    </div>
  </div>

  <div class="row section">
    <div class="d-flex flex-column w-100 justify-content-between align-items-center">
      <hr class='d-block w-50' />
      <div class='d-flex flex-vmax'>
        <!--<div style='width:100%; max-width: 85vw;' class='fade-edge order-p-1'></div>-->
        <picture class='fade-edge order-p-1'>
          <source srcset='static/landing/whales-many.webp' type="image/webp">
          <source srcset='static/landing/whales-many.png' type="image/png">
          <img  width="400" height="400" src='static/landing/whales-many.webp' />
        </picture>

        <div class='display-2r sohne-var text-left d-block d-flex flex-column lh-1 order-p-0 pl-2'>
          <span style='white-space: nowrap' class='align-self-end my-3'>open market<span class='op-50'>.</span></span>
          <div class='text-left my-2 align-self-start font-weight-lighter futura' style='font-size: 40%; line-height: 1.25;'>
            Sell anytime by auction or fixed price on the community marketplace.
            <br/><br/>
            Your ownership is secure and guaranteed by <a href="https://www.youtube.com/watch?v=TDGq4aeevgY">Ethereum.</a>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="row section">
    <div class="d-flex flex-column w-100 justify-content-between align-items-center">
      <hr style='margin: 2rem 0; padding: 2rem 0;' class='d-block pb-0 mb-0 w-50' />
      <div class='d-flex flex-vmax'>
        <div class='display-2r sohne-var text-left d-block d-flex flex-column lh-1 w-100'>
          <span style='white-space: nowrap' class=''>20% to charity<span class='op-50'>.</span></span>
          <div class='text-left my-3 align-self-start font-weight-lighter futura' style='font-size: 40%; line-height: 1.25;'>
            We donate 20% of minting proceeds to <a href='https://www.givedirectly.org/'>GiveDirectly</a>, a reputable charity.
            
            <br/><br/>

            <span>Donations go to cash grants for people in need, with minimal overhead.</span>
            <br/><br/>

            <span style='font-size: 80%'>(This only applies to minting sales by NotReal, not community marketplace sales.)</span>
          </div>
        </div>

        <picture class='h-100'>
          <source srcset='static/landing/earth-heart.webp' type="image/webp">
          <source srcset='static/landing/earth-heart.png' type="image/png">
          <img width="400" height="400" src='static/landing/earth-heart.webp' />
        </picture>
      </div>
    </div>
  </div>

  <div class="row section section-wave">
    <div class="d-flex flex-column w-100 justify-content-between align-items-center">
      <hr style='margin: 2rem 0; padding: 2rem 0;' class='d-block pb-0 mb-0 w-50' />
      <div class='d-flex flex-vmax'>

        <div class='w-100 d-flex flex-column display-2r futura-light font-weight-lighter justify-content-start mb-4'>
          <span style='white-space: nowrap' class='hide-l sohne-var font-weight-normal'>growing fast<span class='op-50'>.</span></span>
          <span class='lh-1'>
            <span class='op-50 d-inline-block w-100' style='font-size: 80%' >7 day</span><br/>
            ${{this.volume.formatted.sevenDayUSD}}
          </span>

          <span class='lh-1'>
            <span class='op-50 d-inline-block w-100' style='font-size: 80%'>total</span><br/>
            ${{this.volume.formatted.totalUSD}}
          </span>
        </div>

        <div class='display-2r sohne-var text-left d-block d-flex flex-column lh-1 w-100'>
          <span style='white-space: nowrap' class='hide-p mb-5'>growing fast<span class='op-50'>.</span></span>
          <div class='text-left align-self-start font-weight-lighter futura' style='font-size: 40%; line-height: 1.25;'>
            Digital art and collectibles is a trending market. 
            <br/><br/>
            <span>Get in early and ride the wave.</span>
          </div>
          <picture class='pt-4 mt-auto mx-auto'>
            <source srcset='static/landing/wave3.webp' type="image/webp">
            <source srcset='static/landing/wave3.png' type="image/png">
            <img width="400" height="100" style='aspect-ratio: 4/1' src='static/landing/wave3.webp' class='w-100'>
          </picture>
        </div>
      </div>

      <br />
      <span class='flex-fill flex-grow-1 lh-1 w-100 text-center' style='flex-basis:100%; font-size: 90%;'>
        <span>Trading volume of digital art/collectibles/trading cards on Ethereum. </span>
        <br />
        <div class='pt-1'>Based on current ETH/USD price. Updated 3/31. Data from <a href='https://opensea.io/rankings'>OpenSea</a>.</div>
      </span>
    </div>
  </div>

  <!--
  <div class='w-100 text-center sohne-var section'>
    <hr class='d-block my-4 w-50' />
    <p class='text-center my-4 align-self-center font-weight-light futura-light h4r'>
      We are so excited to be launching our community marketplace for AI art. <br />
      Sign up below and we'll let you know when we go live.
    </p>


    <email-form class='mx-auto col-lg-6' cta="Let me know" hide-info="true" placeholder='your email'></email-form>
  </div>
  -->

  </div>
</template>

<script>
  import * as _ from 'lodash';
  import {mapState} from 'vuex';
  import * as mutation from '../../store/mutation';
  import * as localforage from 'localforage'
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import GalleryCard from '../../components/cards/GalleryCard';
  import EmailForm from '../../components/feed/EmailForm';
  import { addResizeListener } from '../../utils';
  import 'swiper/css/swiper.css'

  import { BInputGroup, BButton } from 'bootstrap-vue';

  export default {
    name: 'landing',
    components: {
      Swiper, SwiperSlide, GalleryCard, EmailForm,
      ...{ BInputGroup, BButton }
    },
    props: [],
    computed: {
      ...mapState([
        'feedService',
        'editionLookupService',
        'rankingsService',
        'currentUsdPrice'
      ]),
      ...mapState('kodaV2', [
        'assets',
      ]),
    },
    data() {

      return {
        form: { email: null},
        latest: [],
        trending: [],
        volume: { formatted: {}},
        swiperOption: {
          loop: false,
          initialSlide: 0,

          pagination: {
            el: '.swiper-pagination',
            dynamicBullets: true,
            dynamicMainBullets: 12
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            1024: {
              slidesPerView: 3,
              spaceBetween: 10
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 10
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 10
            }
          }
        },
        /*
        swiperOptionThumbs: {
          loop:true,
          loopedSlides: 5,
          spaceBetween: 18,
          centeredSlides: true,
          slidesPerView: 'auto',
          touchRatio: 0.2,
          slideToClickedSlide: true
        }
        */
      }
    },
    methods: {
      //async getRankings() {
      //  const store = localforage.createInstance({name: 'rankings'})
      //  let rankings = await store.getItem('rankings')
      //  const now = Number(new Date())
      //  if (!rankings || (now - rankings.savedAt  > 1)) {
      //    rankings = await this.rankingsService.rankings()
      //    rankings.savedAt = now
      //    store.setItem('rankings', rankings)
      //  }

      //  return rankings
      //},
      //async fetchRankings() {
      //  return await this.rankingsService.rankings()
      //},

      saveEmail() {
        this.$firebase.auth().signInAnonymously()
          .then((r) => {
            // console.log('signin', r)
          })
          .catch((error) => {
            // console.log('signin error', error)
          })

        this.$firebase.auth().onAuthStateChanged(user => {
          // console.log('authstatechanged', user)
          const random = (+new Date * Math.random()).toString(36).substring(0,20)
          const credential = this.$firebase.auth.EmailAuthProvider.credential(this.form.email, random);

          user.linkWithCredential(credential)
            .then(usercred => {
              // console.log('linked', usercred)
            }).catch(err => {
              // console.log('linkederror', err)
            })
        })
      },
    },
    created() {

      /////////
      // API //
      /////////
      //
      //


      const loadEventData = () => {

        this.editionLookupService.getGalleryEditions({orderBy: 'edition', order: 'asc', limit: 24, active: true})
          .then((results) => {
            if (results.success) {
              const {data} = results;
              this.latest = data;
              //this.$refs.editionSwiper.swiperInstance.slideToLoop(this.latest.length)
              this.$store.commit(`kodaV2/${mutation.SET_EDITIONS}`, data);
            }
          }).catch(e => {
            //alert(JSON.stringify(e))
          })


        // Old code loading latest/trending editions
        /*
        this.feedService.latestCreations(15)
          .then((results) => {
            if (results.success) {
              const {data} = results;
              this.latest = data;
              // This is a necessary hack to get vue/swiper to render the card like buttons without interaction
              this.$refs.editionSwiper.swiperInstance.slideToLoop(this.latest.length)
              this.$store.commit(`kodaV2/${mutation.SET_EDITIONS}`, data);
            }
          }).catch(e => {
            //alert(JSON.stringify(e))
          })

        this.feedService.trendingEditions(12)
          .then((results) => {
            // console.log(results);
            if (results.success) {
              const {data} = results;
              this.trending = _.uniq(_.map(data, 'edition'));
              this.$store.commit(`kodaV2/${mutation.SET_EDITIONS}`, data);
            }
          });
          */
      };

      this.$store.watch(
        () => this.feedService.currentNetworkId,
        () => loadEventData()
      );

      if (this.feedService.currentNetworkId) {
        loadEventData();
      }
    },
    mounted() {
      // Another weird case of setTimeout fixing a problem. Rankings come out as zero sporadically without this.
      setTimeout(() => {
        this.volume = this.rankingsService.formatRankings(this.currentUsdPrice)
      }, 250)

      const {swiperInstance: editionSwiper} = this.$refs.editionSwiper

      const editionSwiperResized = () => {
        const rect = editionSwiper.el.getBoundingClientRect()
        editionSwiper.rect = rect
        editionSwiper.params.width = rect.width
        editionSwiper.params.height = rect.height
      }
      
      addResizeListener({editionSwiperResized})
    }
  };
</script>

<style scoped lang="scss">
  @import '../../ko-card.scss';

  $feed-bg: #f6f9fc;

  @mixin portrait {
    @media screen and (max-aspect-ratio: 1/1) {
      @content;
    }
  }

  @mixin landscape {
    @media screen and (min-aspect-ratio: 1/1) {
      @content;
    }
  }


  .landing-heading {
    width: fit-content;
    margin-top: -0.1em;
    margin-left: auto;
    margin-right: auto;
  }

  @include landscape {
    .edition-swiper {
      width: 100%;
    }

    .landing-heading {
      font-size: 8vw; 
      line-height: .9;
    }
  }

  @include portrait {
    .edition-swiper {
      width: 90vw;
    }

    .landing-heading {
      font-size: 26vw; 
      line-height: .85;
      -ms-flex-item-align: center;
      align-self: center;
    }
  }

  .edition-swiper {
    .card-body, .card-footer {
      padding: 0.2rem;
    }
    .card-title {
      margin: 0;
    }
  }

  .section {
    max-width: 1080px;
    margin: 0 auto;
    img {
      aspect-ratio: 1/1;
    }
    
  }

  

  @include portrait {
    .fade-edge {
      //padding-bottom: 90%;
      img {
        width: 100%;
        margin: 0 auto;
      }
    }

    .section-curator {
      img {
        //width: 80%;
        margin: 0 auto;
      }
    }

    img {
      width: 100%;
    }
  }

  @include landscape {
    .fade-edge {
      margin: 2% 2%;
    }

    .section-mint {
      margin-bottom: -3em;
    }

    .section-wave {
      picture {
        width: 60%;
      }
    }
  }

  .fade-edge {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      height: 102%;
      width: 102%;
      left: -1%;
      top: -1%;
      background: radial-gradient(transparentize($feed-bg, 1), transparentize($feed-bg, 1) 30%, $feed-bg 70%);
    }
    //background-image: url('../../../public/static/landing/whales-many.png');
    //box-shadow: 0 0 12rem 12rem $feed-bg inset;
  }


</style>

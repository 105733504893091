<template>

  <div class="mt-0 mx-4 mb-3">

    <span slot="top-right">
      <font-awesome-icon :icon="['fas', 'times']" @click="closePopup()"
                         class="float-right" size="lg">
      </font-awesome-icon>
    </span>

    <h4 class="text-center pb-3 mt-3">Account Balance</h4>



    <div class='d-flex flex-column w-100 justify-content-between flex-grow-1 neumorph'>
      <div class="text-muted text-center d-flex justify-content-between" style='font-size: 130%'>
        <div>Wallet</div>

        <a href='https://gnosis-safe.io/' target='_blank'>
          <div style='height:30px; position:relative;' class='mb-2'>
              <img src="../../../public/static/partners/gnosis-safe.png" class='px-2' style='max-height: 100%' />
              <font-awesome-icon style='vertical-align: bottom; height: 75%' :icon="['far', 'question-circle']"></font-awesome-icon>
          </div>
        </a>
      </div>


      <!-- this is used just to turn off unneeded HTML5 form validation -->
      <form id='novalidate' novalidate />
      <div class='d-flex flex-grow-1 align-items-center'>

        <div class='d-flex flex-column w-100'>
          <div class='input-group h-100'>
            <a class='input-group-prepend' :href='`${etherscanBaseWallet}/address/${account}`' target='_blank'>
              <div class="input-group-text">🏦</div>
            </a>

            <input type="number" 
                   step="0.1"
                   form="novalidate"
                   class="form-control text-left h-100" 
                   @keyup.enter="transfer"
                   v-model="wallet"/>
          </div>

          <div class='position-relative text-left d-none'>
            {{walletUSD}}
          </div>
        </div>

        <div class='w-20 text-center lh-1' style='font-size: 150%'>
          <span class='text-success op-75' v-if="tokenDeltaFloat > 0"><b style='font-size:200%'>»</b></span> 
          <span class='text-success op-75' v-else-if="tokenDeltaFloat < 0"><b style='font-size:200%'>«</b></span> 
          <span v-else>⇌</span>
        </div>
       
        <div class='d-flex flex-column w-100'>

          <div class='input-group h-100'>
            <span class='input-group-prepend' v-if='hasWalletL2Balance'>
              <button v-b-tooltip.hover title='WETH in Matic wallet but outside of safe' 
                      class='input-group-text btn width-fit input-btn' @click="sweepToSafe()">
                {{balance.walletL2 | toEth | to2Dp}} »
              </button>
            </span>
            <input type="number" 
                   step="0.1"
                   form="novalidate"
                   class="form-control text-right h-100" 
                   @keyup.enter="transfer"
                   v-model="token"/>

            <a class='input-group-append' :href='`${etherscanBase}/address/${smartwallet && smartwallet.address}/tokens`' target='_blank'>
              <div class="input-group-text">💵</div>
            </a>
          </div>

          <div class='position-relative text-right d-none'>
            {{tokenUSD}}
          </div>
        </div>


      </div>

      <div class='d-flex flex-column flex-grow-1 mt-2 align-items-center'>
        <button @click="transfer" class='btn d-flex text-center align-items-center w-100 justify-content-between lh-1 mb-2' 
                :disabled='(!isOnMatic && tokenDeltaFloat < 0) || (isOnMatic && tokenDeltaFloat > 0)'>
          <span :class="{'text-success': walletDeltaFloat > 0, 'text-danger': walletDeltaFloat < 0}">
            <span class='d-flex flex-column text-left'>

              <template v-if="walletDeltaFloat == 0">
                <span>⇧</span>
                <span v-visible='false'>a</span>
              </template>
              <template v-else>
                <span>{{walletDelta}}</span>
                <span>{{walletDeltaUSD}}</span>
              </template>

            </span>
          </span>

          <span class='d-flex flex-column'>
            <span v-if="tokenDeltaFloat > 0">Deposit</span> 
            <span v-else-if="tokenDeltaFloat < 0">Withdraw</span> 
            <span v-else>Change a balance to begin</span> 

            <span v-if="tokenDeltaFloat > 0">~3-7 minutes</span> 
            <span v-else-if="tokenDeltaFloat < 0">~30 minutes</span> 
            <span class='hide' v-else>hide</span> 
          </span>

          <span :class="{'text-success': tokenDeltaFloat > 0, 'text-danger': tokenDeltaFloat < 0}">
            <span class='d-flex flex-column text-left'>
              <template v-if="tokenDeltaFloat == 0">
                <span>⇧</span>
                <span v-visible='false'>a</span>
              </template>
              <template v-else>
                <span>{{tokenDelta}}</span>
                <span>{{tokenDeltaUSD}}</span>
              </template>
            </span>
          </span>

        </button>

        <div class='mt-0 text-center w-50'>


          <p class='mb-0' v-if='isOnMatic && tokenDeltaFloat > 0'>
            Connect to Ethereum for deposits
          </p>

          <p class='mb-0' v-else-if='!isOnMatic && tokenDeltaFloat < 0'>
            Connect to
            <a class='text-info btn-link' href='https://docs.matic.network/docs/develop/metamask/config-matic/' target='_blank'>
              Matic
            </a> 
            <span>to start withdrawals</span>
          </p>

          <p class='mb-0' v-if="statusText">{{statusText}}</p>

          <p v-else class='hide mb-0'>
            hide
          </p>

          <div class='d-flex flex-column'>
            <!--<span>{{statusText}}</span>-->

            <template v-if='status.tx'>
              <b-progress class='m-0' v-if='progress' :value='progress' max='1'></b-progress>
              <!--
              <div class='d-flex justify-content-center'>
                <a :href='`${etherscanBaseWallet}/tx/${status.tx}`' class='text-underline' target='_blank'>
                  Eth Txs
                </a>
                <span class='px-2'> | </span>
                <a :href='`${etherscanBase}/address/${smartwallet && smartwallet.address}`' class='text-underline' target='_blank'>
                  Matic Txs
                </a>
              </div>
              -->
              <!--
              <div>
                Deposit will continue if you close this window 
              </div>
              -->
            </template>
          </div>

          <!--
          <span v-if="tokenDeltaFloat > 0">
            <p class='mb-0'>
              est. ~3-7 minutes
              <font-awesome-icon :icon="['far', 'question-circle']"></font-awesome-icon>
            </p>

            <p class='mb-0' v-if='isOnMatic'>
              You must connect to Ethereum for deposits
            </p>
          </span>

          <span v-else-if="tokenDeltaFloat < 0">
            <p class='mb-0'>
              est. ~3-7 minutes
              <font-awesome-icon :icon="['far', 'question-circle']"></font-awesome-icon>
            </p>

            <p class='mb-0' v-if='!isOnMatic'>
              You must connect to
              <a class='text-info btn-link' href='https://docs.matic.network/docs/develop/metamask/config-matic/' target='_blank'>
                Matic network
              </a> 
              <span>for withdrawals</span>
            </p>
          </span>
          -->

          <!--
          <span v-else>
            <a target='_blank' href="https://docs.matic.network/docs/develop/ethereum-matic/getting-started/#pos-vs-plasma">
              Deposit ~3-7 minutes | Withdraw ~30 minutes 
            </a>
          </span>
          -->


          <!-- Forget this complex bullshit. Someone with wrapped Eth on Matic will be savvy enough to just transfer it to their CPK wallet on their own -->
          <p class='d-none text-muted mt-2 mb-0 h7r' v-if='!isOnMatic && balance.bn.walletL2.gt(0)' style='font-size: 85%'>
            <span>
              To deposit your <b>{{balance.walletL2 | toEth | to2Dp }}</b> eth already on Matic, 
              <a class='text-info btn-link' href='https://docs.matic.network/docs/develop/metamask/config-matic/' target='_blank'>
                change your network
              </a> 
            </span> 
          </p>

          <!--
          <p class='text-muted mt-2' v-if='balance.bn.matic.gt(0) && isOnMatic'>
            You have <b>{{balance.matic | toEth | to2Dp }} MATIC</b> tokens, but this dapp uses Wrapped Ether. <br/>
            You can use <b class='text-info'><a href='https://quickswap.exchange/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619#/swap'>Quickswap</a></b> to trade MATIC for WETH.
          </p>
          -->
        </div>

        <div class='d-flex flex-column justify-content-center w-75 mt-2' v-if='Object.keys(deposits).length'>
          <table class='table mb-0'>
            <thead>
              <tr>
                <th class='py-0 text-center' scope="col" colspan='2'>Deposits</th>
              </tr>
            </thead>
            <tbody>

              <tr v-for='tx in deposits' :key='tx.transactionHash'>
                <td class='w-50 py-0 text-right'>{{tx.amountWei | toEth | to4Dp}}</td>
                <td class='w-50 py-0'>
                  <a :href='`${etherscanBase}/tx/${tx.transactionHash}`' class='text-underline' target='_blank'>
                    <span v-if='tx.complete'>complete ✔️</span>
                    <span v-else>waiting ⏳</span>
                  </a>
                </td>
              </tr>

              <tr>
                <td class='py-0 text-right w-50'>
                  <a :href='`${etherscanBaseWallet}/address/${account}?toaddress=${RootChainManager._address}`' 
                    style='text-decoration:underline' target='_blank'>
                    Eth Txs
                  </a>
                </td>
                <td class='py-0 w-50'>
                  <a :href='`${etherscanBase}/address/${smartwallet && smartwallet.address}/token-transfers`' 
                    style='text-decoration:underline' target='_blank'>
                    Matic Txs
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class='d-flex flex-column justify-content-center w-75 mt-2' v-if='Object.keys(withdrawals).length'>
          <table class='table mb-0'>
            <thead>
              <tr>
                <th class='py-0 text-center' scope="col" colspan='2'>Withdrawals</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for='tx in withdrawals' :key='tx.hash'>
                <td class='w-50 py-0 text-right'>{{tx.input.wad | toEth | to4Dp}}</td>
                <td class='w-50 py-0'>
                  <a :href='`${etherscanBase}/tx/${tx.hash}`' class='text-underline' target='_blank'>
                    <span v-if='tx.checkpointed'>ready ✔️</span>
                    <span v-else>waiting ⏳</span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <button :disabled="isOnMatic || !anyWithdrawalReady" class='btn btn-primary w-100 mb-3' @click="completeWithdrawals()">
              Finalize Withdrawals ✔️ 
            </button>
            <p v-if='isOnMatic' class='text-center mb-0'>Connect to Ethereum to finalize withdrawals</p>
          </div>


          <!--
          <table class='table mb-0'>
            <tbody>
              <tr v-for='tx in withdrawals' :key='tx.hash' v-if='!tx.checkpointed'>
                <td class='py-0 text-right'>{{tx.input.wad | toEth | to4Dp}}</td>
                <td class='py-0'>
                  <a :href='`${etherscanBase}/tx/${tx.hash}`' class='text-underline' target='_blank'>
                    <span>waiting ⏳</span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          -->
          <!--
          <span v-for='tx in exitTxs' :key='tx.hash'>
            {{tx.input.wad | toEth}}
          </span>
          -->
        </div>



        <!--
        <b-tabs pills card class='switch-btns'>
          <b-tab title="ETH" active><b-card-text></b-card-text></b-tab>
          <b-tab title="USD"><b-card-text></b-card-text></b-tab>
        </b-tabs>
        -->


        <p></p>

        <!--
        <input type="number" 
               class="form-control text-left" 
               v-model="form.transfer"/>
        -->

        <!--
        <div class='input-group'>
          <button class='btn '>⇀</button>
          <button class='btn '>↽</button>
        </div>
        -->
      </div>

    </div>

    <div class="row modal-footer position-relative pt-2 px-2 pb-2 mt-0">
      <div class="col-12 text-center m-0 p-0">
        <router-link :to="{ name: 'contracts' }" class="m-0" target='_blank'>
          🔐 Secured By
        </router-link>
      </div>

      <div class='w-100 d-flex justify-content-between align-items-end m-0' style='height:40px'>
        <a href="https://ethereum.org/en/eth/" target="_blank" style='height:35px'>
          <img src="../../../public/static/partners/eth-home-icon.png" />
        </a>
        <a href="https://gnosis-safe.io/" target="_blank" style='height:35px'>
          <img src="../../../public/static/partners/gnosis-owl.png" />
        </a>
        <a href="https://matic.network/" target="_blank" style='height: 35px'>
          <img src="../../../public/static/partners/polygon.png" />
        </a>
        <a href="https://biconomy.io/#key-features" target="_blank" style='height: 25px; bottom: 10%;'>
          <img src="../../../public/static/partners/biconomy.png" />
        </a>
        <a href="https://weth.io/" target="_blank" style='width:60px;'>
          <img src="../../../public/static/partners/weth-logo.png" />
        </a>
      </div>
    </div>

    <p class="text-muted text-center m-0 mt-2">
      We use <a href='https://gnosis-safe.io/' target='_blank'>Gnosis Safe</a> to enable fast and cheap transactions on <a href="https://polygon.technology/" target='_blank'>Polygon</a> (previously Matic).
    </p>
  </div>

</template>

<script>

  import {mapGetters, mapState} from 'vuex';
  import * as actions from '../../store/actions';

  import ViewTransactionDetails from '../generic/ViewTransactionDetails';

  import USDPrice from '../generic/USDPrice';
  import ETHPrice from '../generic/ETHPrice';

  //import Web3 from 'web3';
  import { toBN, BN, fromWei, toWei, toHex, padLeft } from 'web3-utils';
  import {contracts} from 'nrda-contract-tools';
  
  import { cacheObj } from '../../services/workers/cachefetch.worker';
  import _ from 'lodash';

  import {BProgress, BTabs, BTab, VBTooltip} from 'bootstrap-vue';

  const zeroAddress = "0x0000000000000000000000000000000000000000"

  export default {
    name: 'AccountBalance',
    components: {
      USDPrice, ETHPrice, ViewTransactionDetails,
      ...{BProgress, BTabs, BTab}
    },
    props: [],
    directives: {'b-tooltip': VBTooltip},
    data() {
      return {
        exitTxs:[],
        withdrawals: {},
        deposits:{},
        progress: 0,
        timer: null,
        withdrawalTimer: null,
        balanceRefresher: null,
        status: {},
        statusText: '',
        pendingDeposits: 0,
        depositListenerKey: '',
        depositListener: null,
        form: {
          wallet: '0',
          token: '0'
        },
      };
    },
    computed: {
      ...mapState([
        'balance',
        'account',
        'Erc20',
        'Erc20L2',
        'RootChain',
        'RootChainManager',
        'currentUsdPrice',
        'smartwallet',
        'web3',
        'l2Web3',
        'network',
        'walletNetwork',
        'matic',
        'dagger',
        'isOnMatic',
        'currentNetworkId',
        'maticNetworkId',
        'etherscanBase',
        'etherscanBaseWallet',
      ]),
      anyWithdrawalReady() {
        return _.map(this.withdrawals, 'checkpointed').includes(true)
      },
      wallet: {
        set(newBalance) { 
          if(parseFloat(newBalance) <= 0) { newBalance = '0' } // Clip lower
          else if(!newBalance) { newBalance = fromWei(this.balance.wallet, 'ether') } // Reset balance if cleared
          else if(parseFloat(newBalance) > this.totalBalanceEther) { newBalance =  this.totalBalanceEther } // Disallow setting above total

          // Set balance on both sides to add up to total balance
          this.form.wallet = toWei(newBalance, 'ether');
          this.form.token = this.tokenBalance;
        },
        get() { 
          // parseFloat twice because we want to round to six decimals withou unnecessary 0 padding at end
          return parseFloat(parseFloat(fromWei(this.form.wallet, 'ether')).toFixed(6));
        },
      },
      token: {
        set(newBalance) { 
          if(parseFloat(newBalance) <= 0) { newBalance = '0' } // Clip lower
          else if(!newBalance) { newBalance = fromWei(this.balance.token, 'ether') } // Reset balance if cleared
          else if(parseFloat(newBalance) > this.totalBalanceEther) { newBalance =  this.totalBalanceEther } // Clip upper

          // Set balance on both sides to add up to total balance
          this.form.token = toWei(newBalance, 'ether');
          this.form.wallet = this.walletBalance;
        },
        get() { 
          // parseFloat twice because we want to round to six decimals withou unnecessary 0 padding at end
          return parseFloat(parseFloat(fromWei(this.form.token, 'ether')).toFixed(6));
        },
      },
      tokenDeltaWei() {
        return toBN(this.tokenBalance).sub(this.balance.bn.token).toString();
      },
      tokenDeltaFloat() {
        return parseFloat(fromWei(this.tokenDeltaWei, 'ether'));
      },
      tokenDeltaUSD() {
        return '$' + (this.tokenDeltaFloat * this.currentUsdPrice).toFixed(2);
      },
      tokenUSD() {
        return '$' + (parseFloat(fromWei(this.form.token, 'ether')) * this.currentUsdPrice).toFixed(2);
      },
      tokenDelta() {
        const deltaFloat = this.tokenDeltaFloat;
        if (deltaFloat > 0) { return `Ξ+${deltaFloat.toFixed(6)}` } 
        else /*if (deltaFloat < 0)*/ { return `Ξ${deltaFloat.toFixed(6)}` } 
      },
      walletDeltaWei() {
        return toBN(this.walletBalance).sub(this.balance.bn.wallet).toString();
      },
      walletDeltaFloat() {
        return parseFloat(fromWei(this.walletDeltaWei, 'ether'));
      },
      walletDeltaUSD() {
        return '$' + (this.walletDeltaFloat * this.currentUsdPrice).toFixed(2);
      },
      walletUSD() {
        return '$' + (parseFloat(fromWei(this.form.wallet, 'ether')) * this.currentUsdPrice).toFixed(2);
      },
      walletDelta() {
        const deltaFloat = this.walletDeltaFloat;
        if (deltaFloat > 0) { return `Ξ+${deltaFloat.toFixed(6)}` } 
        else /*if (deltaFloat < 0)*/ { return `Ξ${deltaFloat.toFixed(6)}` } 
      },
      walletBalance() {
        return this.totalBalance.sub(toBN(this.form.token)).toString();
      },
      tokenBalance() {
        return this.totalBalance.sub(toBN(this.form.wallet)).toString();
      },
      totalBalanceEther() {
        return fromWei(this.totalBalance, 'ether');
      },
      totalBalance() {
        return this.getTotal(this.balance)
        //return this.balance.bn.wallet.add(this.balance.bn.token);
      },
      hasWalletL2Balance() {
        return this.balance.bn.walletL2.gt(toBN(0))
      }
    },
    mounted() {
      this.form = this.balance;
      this.refreshBalance()
      this.balanceRefresher = setInterval(this.refreshBalance, 2500);
      if (this.Erc20L2) {
        this.startDepositListener()
      } else {
        const unwatchErc = this.$watch('Erc20L2', () => {
          this.startDepositListener()
          unwatchErc()
        })
      }

      const {account} = this


      this.fetchDeposits()
      // Wait until contract finished loading to fetch withdrawals
      this.fetchWithdrawals()
      const unwatchContract = this.$watch('RootChain', () => {
        this.fetchWithdrawals()
        unwatchContract()
      })     


      //this.readyForExitInfo().then(res => {
      //  console.log(res)
      //  this.exitTxs = res
      //})


      //this.dagger.matic.on(`latest:addr/${account}/tx`, res => {
      //  console.log('dagger result matic', res)
      //})
      //window.Erc20L2 = this.Erc20L2


      this.unwatchBalance = this.$watch('balance', (bal, oldBal) => {
        const balanceChanged = !this.getTotal(bal).eq(this.getTotal(oldBal))
        if(balanceChanged) {
          this.form = this.balance;
          //this.unwatchBalance();
        }
      })
    },
    beforeDestroy() {
      //clearInterval(this.balanceRefresher)
      this.clearTimer()
      this.stopDepositListener()
      this.clearDeposits()
    },
    methods: {
      async sweepToSafe() {
        const {smartwallet, balance, account} = this
        const {methods: erc20} = this.Erc20L2
        this.txStatus('Waiting for approval...')()
        await erc20.transfer(smartwallet.address, balance.walletL2).send({from: account, smartwallet: false})
                   .once('transactionHash', this.txStatus('Transferring to safe...'))
        this.txStatus('')()
      },
      stopDepositListener() {
        if(!this.dagger.matic) { return }
        this.dagger.matic.off(this.depositListenerKey, this.depositListener)
      },
      // Using the new method of tracking deposits by getPastLogs in checkDeposits
      // this way of tracking may not be necessary. But keeping it here to keep code change minimal
      startDepositListener() {
        const {_address: tokenAddr} = this.Erc20L2
        const {smartwallet} = this
        const transferABI = '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef'
        const evtKey = `latest:log/${tokenAddr}/filter/${transferABI}/${zeroAddress}/${smartwallet.address}`
        this.depositListenerKey = evtKey
        this.depositListener = (res) => {
          //console.log('depositListener', res)
          this.pendingDeposits -= 1
          // Kind of a hack, to avoid overwriting other statuses
          if(this.pendingDeposits == 0) {
            if(this.statusText.includes('Deposit')) {
              this.statusText = ''
            }
            this.status.tx = null
            this.progress = 0
            clearInterval(this.timer)
          }
          //console.log('dagger result eth', res)
          //this.dagger.matic.off(evtKey)
        }

        this.dagger.matic.on(evtKey, this.depositListener)
      },
      async completeWithdrawals() {
        const {account} = this
        const readyTxs = _.pickBy(this.withdrawals, w => w.checkpointed)
        await this.completeExits(_.map(readyTxs, 'transactionHash'))
        // Clear by saving the completed withdrawals keys, but don't save their values
        // TODO: Probably don't actually need to hold on to withdrawal keys, but it could make
        // debugging user complaints and stuck txs in the future easier possibly
        const savedWithdrawals = await cacheObj(`${account}-withdrawals`, _.mapValues(readyTxs, t => null), {merge: true})
        const withdrawals = _.pickBy(savedWithdrawals, _.identity)
        await this.checkWithdrawals(withdrawals)
        this.withdrawals = withdrawals
        //const { account } = this
        //const { transactionHash } = withdrawTx
        //await this.completeExits([transactionHash])
        //// Clear from cache
        //await cacheObj(`${account}-withdrawals`, {[transactionHash]: null}, {merge: true})
      },
      async completeTransfer(withdrawTx) {
        const { account } = this
        const { transactionHash } = withdrawTx
        await this.completeExits([transactionHash])
        // Clear from cache
        await cacheObj(`${account}-withdrawals`, {[transactionHash]: null}, {merge: true})
      },
      async clearDeposits() {
        const { account } = this
        const pendingDeposits = _.pickBy(this.deposits, d => !d.complete)
        //console.log(pendingDeposits)
        await cacheObj(`${account}-deposits`, pendingDeposits, {overwrite: true})
      },
      async clearWithdrawals() {
        const { account } = this

        await cacheObj(`${account}-withdrawals`, {}, {overwrite: true})
      },
      async fetchWithdrawals() {
        if(!this.RootChain) { return }
        const {methods: rootChain} = this.RootChain;
        const {account} = this
        const savedWithdrawals = (await cacheObj(`${account}-withdrawals`)) || {}
        const withdrawals = _.pickBy(savedWithdrawals, _.identity)
        await this.checkWithdrawals(withdrawals)
        // Remove empty values
        this.withdrawals = withdrawals
        this.startCheckWithdrawals()
      },
      async fetchDeposits() {
        const {account} = this
        const savedDeposits = (await cacheObj(`${account}-deposits`)) || {}
        this.deposits = await this.checkDeposits(savedDeposits)
        this.startCheckDeposits()
      },
      async saveWithdrawal(tx) {
        const {account} = this
        // Save tx details not present on original tx object for convenience and compatibility with getTransaction
        tx.input = tx.events.Transfer.returnValues
        tx.hash  = tx.transactionHash

        const savedWithdrawals = await cacheObj(`${account}-withdrawals`, {[tx.hash]: tx}, {merge: true})
        const withdrawals = _.pickBy(savedWithdrawals, _.identity)
        await this.checkWithdrawals(withdrawals)
        this.withdrawals = withdrawals
      },
      async saveDeposit(tx) {
        const {account, l2Web3} = this
        //console.log(tx)
        tx.amountWei = toBN(tx.events[0].raw.data).toString()
        tx.l2BlockNumber = await l2Web3.eth.getBlockNumber()

        const savedDeposits = await cacheObj(`${account}-deposits`, {[tx.transactionHash]: tx}, {merge: true})
        this.deposits = await this.checkDeposits(savedDeposits)
        //this.deposits = deposits
        //tx.input = tx.events.Transfer.returnValues
        //tx.hash  = tx.transactionHash
        //const savedWithdrawals = await cacheObj(`${account}-withdrawals`, {[tx.hash]: tx}, {merge: true})
        //const withdrawals = _.pickBy(savedWithdrawals, _.identity)
        //await this.checkWithdrawals(withdrawals)
        //this.withdrawals = withdrawals
      },

      // TODO cache and track deposits like with withdrawals
      // The problem is that tying deposit history on account
      // is only through amounts, which seems error prone
      //async fetchDeposits() {
      //  const {account} = this
      //  const savedDeposits = (await cacheObj(`${account}-deposits`)) || {}
      //  const deposits = _.pickBy(savedDeposits, _.identity)
      //  await this.checkDeposits(deposits)
      //  // Remove empty values
      //  this.deposits = deposits
      //  this.startCheckDeposits()
      //},
      //async saveDeposit(depositTx) {

      //  const {account} = this
      //  console.log('depositTx', depositTx)
      //  depositTx.hash = depositTx.transactionHash
      //  const savedDeposits = await cacheObj(`${account}-deposits`, {[depositTx.hash]: depositTx}, {merge: true})
      //  const deposits = _.pickBy(savedDeposits, _.identity)
      //  this.deposits = deposits
      //},
      async checkDeposits(deposits) {
        const {matic, l2Web3, smartwallet} = this
        if(_.size(deposits) == 0) { return {} }

        deposits = _.sortBy(deposits, 'blockNumber')

        const depositHistory = await l2Web3.eth.getPastLogs({fromBlock: deposits[0].l2BlockNumber, topics: [null, padLeft(toHex(0), 64), padLeft(smartwallet.address, 64)]})

        // Key deposits & history by 'data', which holds the deposit amount
        // because No other information is available to correlate l1 deposits
        // with received l2 deposits
        const depositHistoryMap = _.groupBy(depositHistory, 'data')
        const depositMap = _.groupBy(deposits, 'events[0].raw.data')

        _.forEach(depositMap, (ds, amt) => {
          const numDepositsComplete = (depositHistoryMap[amt] || []).length

          // Deposits should be in order of blockNumber, 
          // assign complete to earliest deposits first
          ds.slice(0, numDepositsComplete).forEach(d => d.complete = true)
        })

        return _(depositMap).flatMap().keyBy('transactionHash').value()

        //const completeDeposits = _.mapValues(depositMap, (ds, amt) => {
        //  const numDepositsComplete = (depositHistoryMap[amt] || []).length
        //  return ds.slice(0, numDepositsComplete)
        //})


        //console.log(depositHistory)
        //const block = await l2Web3.eth.getBlock(deposits[0].blockNumber, true)
        //console.log(block.transactions)

        //debugger

        //console.log(depositHistory)

        //debugger

        //debugger
        //const status = await Promise.all(_.map(deposits, 'hash').map(matic.depositStatusFromTxHash))
      },
      async startCheckDeposits() {
        if(this.depositTimer) { clearInterval(this.depositTimer) }

        this.depositTimer = setInterval(() => this.checkDeposits(this.deposits), 2500)
      },
      async startCheckWithdrawals() {
        if(!this.RootChain) { return }
        const {methods: rootChain} = this.RootChain;
        if(this.withdrawalTimer) { clearInterval(this.withdrawalTimer) }

        this.withdrawalTimer = setInterval(() => this.checkWithdrawals(this.withdrawals), 2500)
      },
      async checkWithdrawals(withdrawals) {
        if(!this.RootChain) { return }
        const {methods: rootChain} = this.RootChain;
        if (_.size(withdrawals) == 0) { return }

        const lastBlock = parseInt(await rootChain.getLastChildBlock().call())
        _.forEach(withdrawals, tx => {
          withdrawals[tx.hash] = {
            ...tx,
            checkpointed: tx.blockNumber < lastBlock
          }
        })
      },
      async progressBar(getProgress, target, onFinished) {
        const start = parseFloat(await getProgress())
        target = parseFloat(target)
        const duration = target - start 

        //console.log('lastchild', start)
        //console.log('target', target)
        //console.log('duration', duration)

        if (duration > 0) {
          this.timer = setInterval(async () => {
            const p = parseFloat(await getProgress())
            this.progress = (p - start) / duration

            // console.log(p)
            // console.log(this.progress)

            if (this.progress >= 1) {
              clearInterval(this.timer)
              this.progress = 0
              if(onFinished) { onFinished() }
            }
          }, 2500)
        } else {
          this.progress = 1
        }

      },
      refreshBalance() {
        return this.$store.dispatch(actions.GET_BALANCE, this.account);
      },
      getTotal(balance) {
        return balance.bn.wallet.add(balance.bn.token);
      },
      txStatus(text, seconds, txInfo) {
        return (tx) => {
          this.statusText = text

          if (tx) {
            this.status.tx   = tx.transactionHash || tx
          }

          if (seconds) {
            this.progress = 0.000001
            const refresh = 1
            if (!this.timer) {
              this.timer = setInterval(() => {
                // Balance updated in separate interval
                //this.refreshBalance()
                // Text cleared and interval cleared by dagger
                //if (this.progress >= seconds) {
                //  this.statusText = ''
                //  return this.clearTimer()
                //}
                this.progress += (refresh/seconds)
              }, refresh*1000)
            }


            // if (txInfo) {
            //   const {account} = txInfo
            //   console.log('setting up dagger', account)
            //   const stopTracking = this.trackMatic(account, (res) => {
            //     console.log('dagger evt', res)
            //   })
            // }

            //if (txInfo) {
            //  const {account, token, amt} = txInfo
            //  console.log(txInfo)
            //  this.trackMaticDeposit(account, token, amt).then(res => {
            //    console.log('matic deposit done!')
            //    this.statusText = ''
            //    this.clearTimer()
            //    this.progress = 0
            //  })
            //}
          }

          return tx
        }
      },
      clearTimer() {
        clearInterval(this.timer)
        clearInterval(this.withdrawalTimer)
        clearInterval(this.depositTimer)
        clearInterval(this.balanceRefresher)
        this.progress = 0
      },
      // Exit any pending withdrawals
      async exitStatus(txHashes) {
        const {account, matic, smartwallet} = this;
        const {methods: erc20, _address: erc20Address} = this.Erc20;
        const {methods: rootChainManager, _address: rootChainManagerAddress} = this.RootChainManager;
        const {methods: rootChain} = this.RootChain;

        //const txHashes = Object.keys(withdraws)

        const isProcessed = Object.fromEntries(await Promise.all(txHashes.map(async tx => {
          try {
            return [tx, await matic.isERC20ExitProcessed(tx)]
          } catch (err) {
            return [tx, 'pending']
          }
        })))

        //const _.zip(txHashes, isProcessed)
        //console.log('isProcessed', isProcessed)

        return isProcessed
      },
      async readyForExit() {
        const withdraws = await cacheObj(this.smartwallet.address, {})
        const txHashes  = Object.keys(withdraws)
        const txStatus  = await this.exitStatus(txHashes)
        const readyTxs  = Object.keys(_.pickBy(txStatus, (v,k) => v == false))
        return readyTxs
      },
      async readyForExitInfo() {
        const abi = this.web3.eth.abi
        const {methods: erc20} = this.Erc20;
        const readyTxs = await this.readyForExit()
        return await Promise.all(readyTxs.map(async txHash => {
          const tx = await this.l2Web3.eth.getTransaction(txHash)
          const decodedInput = this.decodeInput(this.Erc20, 'withdraw', tx.input)
          tx.input = decodedInput
          return tx
        }))
      },
      decodeInput(contract, method, input) {
        const abiCoder = this.web3.eth.abi;
        const methodAbi = contract.options.jsonInterface.find(m => m.name == method)
        // Remove method signature from tx input
        return abiCoder.decodeParameters(methodAbi.inputs, input.slice(10))
      },
      async completeExits(txHashes) {
        const exits    = await this.exitHashes(txHashes, this.account)
        const exitDone = await this.submitExitProofs(exits)
        return exitDone
      },
      async exitHashes(txHashes, from) {
        const {matic} = this
        
        //console.log('exitHashes', txHashes)

        let exits = await Promise.all(txHashes.map(txHash => {
          return matic.exitERC20(txHash, { from, encodeAbi: true })
        }))

        //console.log('exits', exits)

        return exits
      },
      async submitExitProofs(exits) {
        const { account } = this
        const {_address: rootChainManagerAddress} = this.RootChainManager;
        //console.log('exits', exits)

        // Submit burn proof to main chain
        //this.txStatus(`Transferring to wallet...`)()
        const exitResult = await Promise.all(exits.map(exit => {
          return this.web3.eth.sendTransaction({
            from: account,
            to: rootChainManagerAddress,
            data: exit.data
          })
        }))

        //this.txStatus('')()

        //console.log('exitResult', exitResult)

        return exitResult
      },
      async transfer() {
        const {
          tokenDeltaWei, 
          walletDeltaWei, 
          balance, 
          account, 
          smartwallet, 
          matic, 
          isOnMatic 
        } = this;

        if (tokenDeltaWei > 0) {
          // -- Wallet => Smartwallet --//
          const {methods: erc20, _address: erc20Address} = this.Erc20;
          
          // -- Methods used for depositing -- //
          const transfers = {
            deposit: async (amt) => {
              //const isDeployed = await this.smartwallet.isProxyDeployed()

              if (!this.smartwallet.isDeployed) {
                // Deploys smartwallet without running any txs
                this.txStatus('Creating your safe wallet...')()
                const deployResult = await this.smartwallet.execTransactions([])

                //console.log(deployResult)

                this.smartwallet.isDeployed = true
              }

              // gas buffer
              const gas = toBN(toWei('0.015', 'ether'))
              if(balance.bn.walletNative.add(balance.bn.walletToken).lt(gas)) {
                this.txStatus("Min balance of 0.015 ETH required for gas fees")()
              }
              // See if we need to withdraw from user's WETH
              let tokenAmt = toBN(amt).sub(balance.bn.walletNative)
              // Can't spend more WETH than we have
              tokenAmt = BN.min(tokenAmt, balance.bn.walletToken)
              if(tokenAmt.gt(toBN(0))) {
                this.txStatus('Unwrapping some WETH...')()
                try {
                  await erc20.withdraw(tokenAmt)
                    .send({from: account})
                    .once('sent', this.txStatus('Unwrapping some WETH... Sent'))
                    .then(this.txStatus('Unwrapping some WETH... Done'))
                } catch (err) {
                  this.txStatus('Unwrapping WETH failed or rejected')()
                }
              }

              //const txAmt = toBN(amt).sub(gas).toString()
              this.pendingDeposits += 1
              this.txStatus('Starting Deposit...')()
              //const {l2Web3} = this
              //l2Web3.eth.subscribe('pendingTransactions', (error, txHash) => {
              //  console.log('pending', error, txHash)
              //})
              try {

                const onTransactionHash = this.txStatus('Deposit is sending...')
                const tx = await matic.depositEtherForUser(smartwallet.address, amt, {from: account, onTransactionHash})
                  .then(this.txStatus('Deposit is Transferring', 8*60))

                this.saveDeposit(tx)
                // Refresh balance in ten minutes, just in case the user closes
                // the account balance window
                setTimeout(() => {
                  this.$store.dispatch('GET_BALANCE', account);
                }, 10*60*1000)
              } catch (err) {
                console.error(err)
                this.txStatus('Deposit failed or rejected')()
              }

              // this.saveDeposit(tx)
              // Status is cleared by dagger listener
              //this.txStatus('')()
            },
            /*
            walletToken: async (amt) => {
              console.log('transfer walletToken')
              this.txStatus('Approving transfer...')()

              try {
                await erc20.withdraw(amt)
                  .send({from: account})
                  .once('sending', this.txStatus('Please wait...'))
                await matic.depositEtherForUser(smartwallet.address, amt, {from: account})
                  .then(this.txStatus('Depositing...', 60*10, {account: smartwallet.address, token: erc20Address, amt}))
              } catch (e) {
                this.txStatus('')()
              }

              //this.txStatus('')()
            }, 
            walletNative: async (amt) => {
              console.log('transfer walletNative', balance.walletNative)
              this.txStatus('Approving transfer...')()
              try {
                await matic.depositEtherForUser(smartwallet.address, amt, {from: account})
                  .then(this.txStatus('Depositing...', 60*10, {account: smartwallet.address, token: erc20Address, amt}))
              } catch (e) {
                this.txStatus('')()
              }

              //this.txStatus('')()
            },
            walletL2: async (amt) => {
              console.log('transfer walletL2', balance.walletL2)
              this.txStatus('Waiting for approval...')()
              await erc20.transfer(smartwallet.address, tokenDeltaWei).send({from: account})
              this.txStatus('')()
            }
            */
          }

          await transfers.deposit(tokenDeltaWei)

          /*
          // -- Sources of wallet balance -- //
          let balSrc
          if (isOnMatic) {
            // Deposits no longer allowed on Matic
            // We can't use Matic networks native MATIC token directly,
            // point the user to uniswap to make it weth
            // balSrc = ['walletL2', 'walletToken']
          } else {
            // We can't transfer the user's Matic WETH while we are connected
            // to an ethereum network (that's why we need the gnosis safe)
            // Direct user to instructions on changing to Matic network if they want to transfer it
            //balSrc = ['walletToken', 'walletNative']
            balSrc = ['walletNative', 'walletToken']
          }

          // -- Transfer until we can't any more -- //
          let remainingWei = toBN(tokenDeltaWei)
          let bal, amt, txr;
          // Buffer for gas fees
          let gas = toBN(toWei('0.015', 'ether'))
          // Transfer each token type in turn
          for(let i=0; i<balSrc.length; i++) {
            if (!remainingWei.gt(toBN(0))) { break }
            bal = toBN(balance[balSrc[i]]).sub(gas)
            if (!bal.gt(toBN(0))) { continue }
            txr = transfers[balSrc[i]]

            amt = BN.min(remainingWei, bal)
            await txr(amt)
            remainingWei = remainingWei.sub(amt)
          }
          */

        } else if (walletDeltaWei > 0) {
          // -- Smartwallet => Wallet --//

          if (!this.isOnMatic) {
            console.log('Withdrawals only supported on Matic network (for now)')
            return
          }

          const transfers = {
            withdrawMatic: async (amt) => {
              //const {methods: erc20L1, _address: erc20L1Address} = this.Erc20;
              const {methods: erc20, _address: erc20Address} = this.Erc20L2;
              const {methods: rootChain} = this.RootChain

              let result
              this.txStatus('Withdrawing from safe...')()
              result = await erc20.transfer(account, amt).send({from: smartwallet.address})

              //console.log(result)
              this.txStatus('Withdrawing from Matic...')()
              const withdrawTx = await erc20.withdraw(amt).send({from: account, smartwallet: false})
              this.saveWithdrawal(withdrawTx)
              this.txStatus('')()

              //this.txStatus('Matic is transferring...')()
              ////console.log(result)
              //const { transactionHash } = result
              //// Get all localstorage saved withdrawals, and also add this latest one
              //const withdraws = await cacheObj(smartwallet.address, {[transactionHash]: result}, {merge: true})

              ////this.txStatus('Withdrawing... 3/4', 30*60)()

              //const txStatus = await this.exitStatus(Object.keys(withdraws))
              //
              //console.log('txStatus', txStatus)

              //// Tokens burned, but not yet exited
              //const needsExit = Object.keys(_.pickBy(txStatus, (v,k) => v == false || v == 'pending'))
              //// Checkpoint is passed, ready for full exit onto main chain
              //const readyForExit = Object.keys(_.pickBy(txStatus, (v,k) => v == false))

              ////const saveWithdraws = _.pick(withdraws, needsExit)
              ////console.log('saveWithdraws', saveWithdraws)
              //console.log('needsExit,readyForExit', needsExit, readyForExit)

              //const exits = await this.exitHashes(readyForExit, account)
              //const doneExiting = await this.submitExitProofs(exits)
              //console.log('doneExiting', doneExiting)

              //const doneTransfer = await promiEvent
              //console.log('doneTransfer', doneTransfer)


              //this.exitCheckpointed(withdraws)


                
              //console.log(withdraws)
              //console.log(result)
              //const pendingExit = cacheGet(smartwallet.address, async () => result)

              //const exit = await matic
              //  .exitERC20(transactionHash, { from: smartwallet.address, encodeAbi: true })
              //  .then(this.txStatus('Withdrawing...'))

              //console.log(exit)
              //const pendingExit = cacheGet(smartwallet.address, async () => exit)
              //console.log(pendingExit)


              //this.txStatus('Withdrawing...', 60*30)()

              //this.txStatus('Waiting for withdraw... (tx2/3)')()
              //const exit = await matic.exitERC20(transactionHash, { from: smartwallet.address, encodeAbi: true })
              //  .then(this.txStatus('Withdraw confirming... (tx2/3)'))
              //// It will take 30 minutes before this freshly burned tx is ready for exit

              //const exitResult = await this.web3.eth.sendTransaction({
              //  from: account,
              //  to: contracts.getMaticRootAddress(this.walletNetwork),
              //  data: exit.data
              //}).then(this.txStatus('Withdraw confirming... (tx3/3)'))

              //this.txStatus('')()

              //return exitResult
            },
            withdrawToSmartwallet: async (amt) => {
              const {methods: erc20L1, _address: erc20L1Address} = this.Erc20;
              const {methods: erc20, _address: erc20Address} = this.Erc20L2;


              //this.txStatus('Withdrawing...')()

              // Transfer erc20 from SW to account on L2 side to save gas
              //await erc20.transfer(account, amt).send({from: smartwallet.address})

              // If the smartwallet withdraws - the token will belong to the mainnet smartwallet
              // It will have to be transferred to the mainnet SW to the owners account on main...
              // And creating a mainnet smartwallet is very expensive... ($40 at current gas price)...

              // But if we transfer and then the main account withdraws,
              // that's a problem because the erc20 token is not enabled for meta transactions...
              // I can't approve the remote SW either because that also requires a remote meta TX...

              // Burn the intended token amount
              // console.log(erc20Address)

              const result = await erc20.withdraw(amt).send({from: smartwallet.address})
              const { transactionHash } = result
              // Get all localstorage saved withdrawals, and also add this latest one
              const withdraws = await cacheObj(smartwallet.address, {[transactionHash]: result}, {merge: true})

              //this.txStatus('Withdrawing... 3/4', 30*60)()

              const txStatus = await this.exitStatus(Object.keys(withdraws))
              
              //console.log('txStatus', txStatus)

              // Tokens burned, but not yet exited
              const needsExit = Object.keys(_.pickBy(txStatus, (v,k) => v == false || v == 'pending'))
              // Checkpoint is passed, ready for full exit onto main chain
              const readyForExit = Object.keys(_.pickBy(txStatus, (v,k) => v == false))

              //const saveWithdraws = _.pick(withdraws, needsExit)
              //console.log('saveWithdraws', saveWithdraws)
              //console.log('needsExit,readyForExit', needsExit, readyForExit)

              const exits = await this.exitHashes(readyForExit, smartwallet.address)
              const doneExiting = await this.submitExitProofs(exits)

              //console.log('doneExiting', doneExiting)


              //const doneTransfer = await promiEvent
              //console.log('doneTransfer', doneTransfer)


              //this.exitCheckpointed(withdraws)


                
              //console.log(withdraws)
              //console.log(result)
              //const pendingExit = cacheGet(smartwallet.address, async () => result)

              //const exit = await matic
              //  .exitERC20(transactionHash, { from: smartwallet.address, encodeAbi: true })
              //  .then(this.txStatus('Withdrawing...'))

              //console.log(exit)
              //const pendingExit = cacheGet(smartwallet.address, async () => exit)
              //console.log(pendingExit)


              //this.txStatus('Withdrawing...', 60*30)()

              //this.txStatus('Waiting for withdraw... (tx2/3)')()
              //const exit = await matic.exitERC20(transactionHash, { from: smartwallet.address, encodeAbi: true })
              //  .then(this.txStatus('Withdraw confirming... (tx2/3)'))
              //// It will take 30 minutes before this freshly burned tx is ready for exit

              //const exitResult = await this.web3.eth.sendTransaction({
              //  from: account,
              //  to: contracts.getMaticRootAddress(this.walletNetwork),
              //  data: exit.data
              //}).then(this.txStatus('Withdraw confirming... (tx3/3)'))

              //this.txStatus('')()

              //return exitResult
            }, 
            debug: async () => {


              const blocks12h = (12*60*60/5)
              const latest = await this.l2Web3.eth.getBlockNumber()
              const evts = await this.Erc20L2.getPastEvents('Transfer', {
                filter: {from: smartwallet.address},
                fromBlock: latest - blocks12h
              })
              const burnEvts = evts.filter(e => e.returnValues.dst == zeroAddress)

              //console.log(burnEvts)
              //const latest = await this.l2Web3.eth.getBlockNumber()
              //console.log(latest-5000)
              //const logs = await this.l2Web3.eth.getPastLogs({
              //  fromBlock: latest-10000,
              //  address: smartwallet.address
              //})
              //console.log(logs)
              //const { contract } = smartwallet.contractManager.contract
              //debugger
              //const evts = await contract.getPastEvents('ExecutionSuccess', {
              //  filter: {},
              //  fromBlock: 0
              //})

              //console.log(evts)
            }
          }

          await transfers.withdrawMatic(walletDeltaWei)
          //await transfers.debug()

          //const txr = transfers.token
          //const exit = await txr(walletDeltaWei)

          //const result = await erc20.balanceOf(smartwallet.address).send()
          //console.log(result)
          // -- Smartwallet => Wallet--//
          //const tx = {
          //  to: erc20Address, 
          //  data: erc20.balanceOf(smartwallet.address).encodeABI()
          //}
        
          //const exec = await this.smartwallet.execTransactions([tx]);
          //console.log('exec sw', exec)

          //const result = await exec.promiEvent;
          //console.log('exec sw result', result)
          //  // Token => Wallet

          //  // Transfer
          //  const tx = {
          //    to: erc20Address, 
          //    data: erc20.transfer(account, walletDeltaWei).encodeABI()
          //  }
          //  await this.smartwallet.execTransactions([tx]);

          //  // Unwrap
          //  await erc20.withdraw(walletDeltaWei).send({from: account});
        } else {
          // No-op
        }

        // Refresh balance
        await this.$store.dispatch('GET_BALANCE', account);

        // Update form with refreshed balance 
        this.form = this.balance;

        // Setting wallet v-model to null resets form state
        this.wallet = null;

      },
      async wrapErc20() {
        const {methods: erc20} = this.Erc20;
        // Deposit amount that needs be wrapped

      },
      closePopup() {
        // Close current popup
        this.$emit('close');
      },
      async stuckWithdraws() {
        const {smartwallet, matic, account} = this
        const {methods: rootChain} = this.RootChain;
        // Try to finish exits by investigating logs
        // getPastEvents is unreliable, the filter doesn't
        // seem to work, so we can't use this'

        // Get logs of burn transactions that may not have exited yet
        const zeroAddress = "0x0000000000000000000000000000000000000000"
        const blocks12h = (12*60*60/5)
        const latest = await this.l2Web3.eth.getBlockNumber()
        const evts = await this.Erc20L2.getPastEvents('Transfer', {
          filter: {from: smartwallet.address},
          fromBlock: latest - blocks12h
        })

        //console.log(evts)

        const burnEvts = evts.filter(e => e.returnValues.dst == zeroAddress)

        //console.log('burnEvts', burnEvts)

        // Check which burn transactions in the log have not been exited
        let needsExit = await Promise.all(burnEvts.map(async burn => {
          const exit = await matic.exitERC20(burn.transactionHash, { from: smartwallet.address, encodeAbi: true })
          //console.log('exit', exit)

          const didExit = await rootChain.methods.processedExits(exit.data)
          //console.log('didExit', didExit)

          if (didExit) {
            return null
          } else {
            return exit
          }
        }))
        
        needsExit = needsExit.filter(tx => tx)

        //console.log('needsExit', needsExit)

        const runExits = await Promise.all(needsExit.map(exit => {
          return this.web3.eth.sendTransaction({
            from: account,
            to: contracts.getMaticRootAddress(this.walletNetwork),
            data: exit.data
          })
        }))

        //console.log('runExits', runExits)
        //console.log('Completed exiting all pending burns')
      },
      // This doesn't work. I hate Matic so much
      async trackMatic(address, cb) {
	//const evt = `latest:addr/${address}/tx/in`
        const { _address: token } = this.Erc20L2;
        //const topicPrefix = '0x000000000000000000000000'
        //const tokenAddr = token.toLowerCase()
        //const zeroTopic = zeroAddress.replace('0x', topicPrefix).toLowerCase()
        //const addrTopic = address.replace('0x', topicPrefix).toLowerCase()

        //const evt = `latest:log/${token.toLowerCase()}/filter/${zeroAddress}/${address.toLowerCase()}`
        const evt = `latest:log/${token.toLowerCase()}`
        //console.log(evt)

	this.dagger.matic.on(evt, cb)
        return (() => this.dagger.matic.off(evt))
      },
      // Attempting to use matic sample code failed here
      async trackMaticDepositFuckThis(userAccount,rootToken,depositAmount) {

        const childChainManagerProxy = contracts.getMaticChildChainManagerAddress(this.maticNetworkId)
        const { web3, maticWs: ws } = this;
        const abiCoder = web3.eth.abi;

        return new Promise((resolve, reject) => {
          ws.onopen = () => {
            ws.send(
              `{"id": 1, "method": "eth_subscribe", "params": ["newDeposits", {"Contract": ${childChainManagerProxy}}]}`
            );

            ws.onmessage = (msg) => {
              const parsedMsg = JSON.parse(msg);
              //console.log(parsedMsg)
              if (
                parsedMsg &&
                parsedMsg.params &&
                parsedMsg.params.result &&
                parsedMsg.params.result.Data
              ) {
                const fullData = parsedMsg.params.result.Data;
                const { 0: syncType, 1: syncData } = abiCoder.decodeParameters(
                  ["bytes32", "bytes"],
                  fullData
                );

                // check if sync is of deposit type (keccak256("DEPOSIT"))
                const depositType =
                  "0x87a7811f4bfedea3d341ad165680ae306b01aaeacc205d227629cf157dd9f821";
                if (syncType.toLowerCase() === depositType.toLowerCase()) {
                  const {
                    0: userAddress,
                    1: rootTokenAddress,
                    2: depositData,
                  } = abiCoder.decodeParameters(
                    ["address", "address", "bytes"],
                    syncData
                  );

                  // depositData can be further decoded to get amount, tokenId etc. based on token type
                  // For ERC20 tokens
                  const { 0: amount } = abiCoder.decodeParameters(
                    ["uint256"],
                    depositData
                  );

                  if(userAddress.toLowerCase() === userAccount.toLowerCase()) {
                    console.log('ws msg', userAddress, rootTokenAddress, depositData)
                  }

                  if (
                    userAddress.toLowerCase() === userAccount.toLowerCase() &&
                    rootToken.toLowerCase() === rootTokenAddress.toLowerCase() &&
                    depositAmount === amount
                  ) {
                    resolve(true);
                  }
                }
              }
            };

            ws.onerror = () => {
              reject(false);
            };
            ws.onclose = () => {
              reject(false);
            };
          };
        });
      }
    }
  };
</script>

<style scoped lang="scss">
  @import '../../ko-card.scss';

  .modal-footer a {
    position: relative;
    display: block;
    padding: 0 3%;
  }

  .modal-footer a img {
    max-height: 100%;
    max-width: 100%;
  }




</style>

<style lang="scss">
  .switch-btns {
    .card-header {
      background: none !important;
      padding: 0;
      .nav-item {
        padding: 0.25em !important;
      }
    }
    .card-body {
      display: none !important;
    }
  }

  .v--modal {
    height: auto !important;
  }

  .btn.input-btn {
    box-shadow: none;
    transition: all 0.1s ease-in-out;
  }
</style>

<template>

  <div class="m-4">
    <span slot="top-right">
      <font-awesome-icon :icon="['fas', 'times']" @click="closePopup()"
                         class="float-right" size="lg">
      </font-awesome-icon>
    </span>

    <a class="e-widget no-button" :href="url" rel="nofollow">test</a>
    
  </div>

</template>

<script>

  import {mapGetters, mapState} from 'vuex';
  import * as actions from '../../store/actions';
  import gleam from './Gleam.js';

  export default {
    name: 'Giveaway',
    components: {},
    props: ['gleam'],
    computed: {
      url() {
        return `https://gleam.io/${this.gleam}`
      }
    },
    methods: {
      closePopup() {
        this.$ga.event('signup-popup', 'popup-displayed', 'top-right-close');
        // Close current popup
        this.$emit('close');
      }
    },
    mounted() {
      gleam()
    }
  };
</script>

<style scoped lang="scss">
  @import '../../ko-card.scss';

</style>

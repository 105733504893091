<template>
  <div class='d-flex flex-column'>
    <b-input-group class='mx-auto my-1'>
      <input v-model='form.email' class='form-control' :placeholder='placeholder || "your email here"' />
      <b-button @click="saveEmail()" type="button" variant="primary" class='width-fit'>
        {{ cta || "Join the club" }} <span v-if="isLoading" class='h6r futura-light font-weight-light mt-4 text-muted'>⏳</span>
      </b-button>
    </b-input-group>
    <span v-if="success" class='h6r futura-light font-weight-light mt-4 text-muted'>Thank you SO much! Stay tuned for the upcoming launch.</span>
    <span v-if="error" class='h6r futura-light font-weight-light mt-4 text-muted'>Darn... something went wrong. Try again?</span>
    <span v-if="!hideInfo" class='h6r futura-light font-weight-light mt-2 text-muted lh-1'>
      <span class='d-inline-block px-1'>spam sucks.</span>
      <span class='d-inline-block px-1'>we respect your inbox.</span>
    </span>
  </div>
</template>

<script>
  import * as _ from 'lodash';
  import LoadingSpinner from "../generic/LoadingSpinner";
  import {BInputGroup, BButton} from 'bootstrap-vue'

  export default {
    name: 'email-form',
    components: {
      LoadingSpinner,
      ...{BInputGroup, BButton}
    },
    props: ['hideInfo', 'cta', 'placeholder'],
    data() {
      return {
        form: { email: null},
        isLoading: false,
        success: false,
        error: false
      }
    },
    methods: {
      saveEmail() {
        this.isLoading = true
        this.error = false
        this.success = false
        this.$firebase.auth().signInAnonymously()
          .then((r) => {
            // console.log('signin', r)
          })
          .catch(err => {
            this.isLoading = false
            this.error = true
            //console.log('signin error', err)
          })

        let linking = false
        this.$firebase.auth().onAuthStateChanged(user => {
          if(!user) { return }
          if(linking) { return }
          linking = true
          //console.log('authstatechanged', user)
          const random = (+new Date * Math.random()).toString(36).substring(0,20)
          const credential = this.$firebase.auth.EmailAuthProvider.credential(this.form.email,random);
          user.linkWithCredential(credential)
            .then(usercred => {
              this.success = true
              this.isLoading = false
              // console.log('signin error', error)
              // console.log('linked', usercred)
            }).catch(err => {
              //console.log('signin error', err)
              this.isLoading = false
              switch(err.code) {
                case 'auth/provider-already-linked':
                  this.success = true
                  return
                case 'auth/email-already-in-use':
                  this.success = true
                  return
                default:
                  this.error = true
                  return
              }
            })
        })
      }
    }
  };
</script>

<style scoped>
  img {
    width: 35%;
  }

  a {
    color: inherit;
  }
</style>

<template>
  <span class="text-mute">
    <span>Ξ</span><span v-if="showZero || ethPrice">{{ ethPrice }}</span>
  </span>
</template>

<script>
  /* global web3:true */

  import {mapState} from 'vuex';

  export default {
    name: 'ethPrice',
    props: ['priceInUsd', 'showZero'],
    computed: {
      ...mapState([
        'currentUsdPrice',
      ]),
      ethPrice: function () {
        if (this.currentUsdPrice && (this.showZero || this.priceInUsd)) {
          const value = this.priceInUsd / this.currentUsdPrice;
          return value.toFixed(4);
        }
        return null;
      }
    }
  };
</script>

<style scoped>
  small {
    font-size: 0.75rem;
  }
</style>

 <template>
  <div class="no-gutter-left h-100vh touch-action-y" id="studio-picker" keep-alive>
    <pre class='d-none text-white bg-black z-10 position-absolute t-0 r-0' style='max-width: 100vw; overflow: scroll; height:100px; font-size: 8px'>{{bidEvents}}</pre>

    <div class="h-100vh d-flex position-relative flex-column flex-landscape-row bg-white usr-select-none">
      <div v-on:click="videoClick" 
           :class="{'bg-after-none' : mintMode }"
           id='video-container'
           class="w-video h-video ml-auto mr-auto video-container z-2 pr-5 position-relative">
        <loading-spinner v-if="isLoading" class='position-absolute' style='z-index:1000; right:15px; bottom:20vmin;'></loading-spinner>

        <!-- Top of screen overlays -->
        <!--<router-link :to="{ name: 'account', params: {address: account} }" target='_blank'><u> view in gallery</u></router-link>-->
        <!--
        <div class='status-overlay tl text-center text-success p-1 font-weight-bolder futura-light h7r' 
             v-if="selected && accountIsHighestBidder(selected) && auctionStatus(selected) != 'mintsold'">
          🥇 Your bid is the highest!
        </div>

        <div class='status-overlay tl text-center text-success p-1 font-weight-bolder futura-light h7r' 
             v-if="selected && account && accountIsEditionWinner(selected) && editionStatus(selected) == 'past'">
          <span>🏆 This one is yours!</span>
        </div>
        -->

        <div class='status-overlay tr d-flex align-items-center futura-light' v-if='mintMode'>
          <div class='d-flex flex-column justify-content-between text-right px-2'>
            <span v-if="auctionStatus(selected) == 'burned'">
              No Bids {{emojiStatus(selected)}}
            </span>

            <template v-else-if="auctionStatus(selected) == 'scheduled'">
              <span>Auction Scheduled {{emojiStatus(selected)}}</span>
              <span style='white-space: pre' class='b612'>
                {{ dateTimeStatus(selected) }}
              </span>
            </template>

            <template v-else-if="auctionStatus(selected) == 'mint'">
              <span>Minting Auction {{emojiStatus(selected)}}</span>
              <span style='white-space: pre' class='b612'>
                {{ dateTimeStatus(selected) }}
              </span>
              <span>
                High Bid <u-s-d-price :price-in-ether="editionHighBid(selected)" :show-zero="true"></u-s-d-price> 
              </span>
              <span v-if='accountIsHighestBidder(selected)'>
                Your bid is highest 🥇
              </span>
            </template>

            <template v-else-if="auctionStatus(selected) == 'sold'">
              <span>
                Owned by <router-link :to="{ name: 'account', params: {address: editionOwner(selected)} }" target='_blank'><u>{{ editionOwner(selected) || "none"  | truncate(8) }} </u></router-link>
                {{emojiStatus(selected)}}
              </span>
              <span>
                Last Sold for <u-s-d-price :price-in-ether="acceptedBid(selected)" :show-zero="false"></u-s-d-price> 
              </span>
              <span>
                High Bid <u-s-d-price :price-in-ether="editionHighBid(selected)" :show-zero="true"></u-s-d-price> 
              </span>
              <span v-if='accountIsEditionWinner(selected)'>
                This one is yours 🏆
              </span>
              <span v-else-if='accountIsHighestBidder(selected)'>
                Your bid is highest 🥇
              </span>
            </template>

            <template v-else-if="auctionStatus(selected) == 'mintsold'">
              <span>
                Owned by <router-link :to="{ name: 'account', params: {address: editionOwner(selected)} }" target='_blank'><u>{{ editionOwner(selected) || "none"  | truncate(8) }} </u></router-link>
                {{emojiStatus(selected)}}
              </span>
              <span>
                Won by <router-link :to="{ name: 'account', params: {address: editionHighBidder(selected)} }" target='_blank'><u>{{ editionHighBidder(selected) || "none"  | truncate(8) }} </u></router-link>
                🥇
              </span>
              <span>
                Last Sold for <u-s-d-price :price-in-ether="editionHighBid(selected)" :show-zero="false"></u-s-d-price> 
              </span>
            </template>

          </div>
        </div>



        <video style="visibility:visible;" preload="auto"
               webkit-playsinline playsinline id="main-vid"
               class="z-1 w-video h-video" muted autoplay paused></video>
        <video style="visibility:hidden; position: absolute;" preload="auto"
               webkit-playsinline playsinline id="main-vid-2"
               class="z-1 w-video h-video" muted autoplay paused></video>

        <!-- Bottom row buttons -->
        <span class='m-1 l-0 b-0 position-absolute z-10 neumorph futura-light'>

          <span style='width: auto; font-family: inherit; line-height:1; box-shadow: none;' 
            v-on:click="scrollNextSection"
            class="lh-1 px-1 py-1 flex-grow-1 btn btn-primary text-right text-center ptr-evts-auto align-self-center">
            more<br/>
            <span style='line-height: 1;'>▼</span>
          </span>

          <span class='btn btn-primary ml-2 flex-grow-1' 
                style='width:auto; box-shadow: none;'
                v-on:click="startTour()">
            <font-awesome-icon :icon="['far', 'question-circle']"></font-awesome-icon>
          </span>
        </span>

        <b-popover show target='toggle-market' placement='top'>
          Start Here
        </b-popover>

        <span id='toggle-market'  
              class='d-flex flex-column position-relative neumorph futura-light z-10 position-absolute b-0 r-0 m-1' 
              v-if="$remoteConfig.version != 'nomarket'">
          <span style='width: auto; font-size: 80%;' class="d-flex align-items-center flex-column lh-1_2 font-weight-lighter p-1 flex-grow btn btn-primary text-right ptr-evts-auto" :class="mintMode ? 'active' : ''" v-on:click.stop="toggleMintMode(null)">
            <span style='font-size: 140%'>⚖️</span>
            <span>Auctions</span>
          </span>
        </span>



      </div>


      <div v-on:click="infoClick" 
           class="grid-info mix-difference d-flex justify-content-between
                  flex-column usr-select-none bg-white h-100">





        <!--justify-content-between d-flex flex-row flex-wrap">-->

        <div v-on:click="headerClick" id='landing-header' 
          :class="{'bg-after-none' : mintMode }"
          class="landing-header position-relative h-100 d-flex justify-content-center align-items-center flex-column w-100 bg-transparent" style="font-size: 40em;">



          <div v-if="!mintMode" class="landing-text bodoni font-weight-light ptr-evts-none" style="line-height: 0.585; letter-spacing: 0.05em;">
            <span class="w-100 txt-not c-near-white color-dodge z-2 position-relative d-inline-block">
              not
            </span>
            <br/>
            <span class="txt-real c-near-white z-2 position-relative d-inline-block pb-5">
              <q>real</q><q style='font-size: 0.8em' class='ptr-evts-auto d-inline-block' v-on:click="togglePunctuation">{{punctuation}}</q>
            </span>
          </div>

          <div class="overlay-grid-outer" 
               style='font-size: 35vmin' :class="{offscreen: !mintMode}">
              <!-- Passing in all of these methods is a cluster but the only way to avoid a painful re-factor -->
            <edition-page  
              v-for="offset in [-1,0,1]" 
              :key="offset"
              :class="{'offscreen': cachedPageLabel(page, offset) != 'cur'}"
              :editionsPage="editionsPageCache(cachedPageLabel(page, offset))"
              :locked="locked"
              :selected="selected"
              :emojiStatus="emojiStatus"
              :accountIsEditionWinner="accountIsEditionWinner"
              :editionStatus="editionStatus"
              :editionUnclaimed="editionUnclaimed"
              :auctionStatus="auctionStatus"
              :editionHighBid="editionHighBid"
              :acceptedBid="acceptedBid"
              :dateTimeStatus="dateTimeStatus">
            </edition-page>
          </div>


        </div>

        <div class="b-0 horiz-hint futura font-weight-bolder 
                     p-0 z-2 w-100 usr-select-none text-left 
                     d-flex align-items-end justify-content-between" 
              v-bind:class="{ 'op-50': !mintMode, 'op-75': mintMode }"
              style="font-size: 3em;"> 



          <div class='d-flex justify-content-between flex-grow-1 text-center align-items-center control-bar w-100 neumorph p-1'>


            <div class='d-flex flex-column pr-2' id='pagination'>
              <button class='btn d-flex justify-content-center w-100 px-1 py-0 br-b-0'>
                <span>{{page+1}}/{{numPages}} 📖</span>
              </button>

              <div class='btn-group br-t-0'>
                <button v-on:click="pagePrev" style='font-size:150%' class="text-center d-flex align-items-center width-fit py-0 px-3 btn btn-primary">
                  ⬅
                </button>


                <button v-on:click="pageNext" style='font-size:150%; paddding' class="text-center d-flex align-items-center width-fit py-0 px-3 btn btn-primary">
                  ➡
                </button>
              </div>
            </div>




            <template v-if='!mintMode'>
              <span v-on:click="invertCanvas" class="flex-grow-1 text-center ptr-evts-auto lh-1_2 my-auto h7r" style='font-size: 2.5vmin'>
                <span style='font-size: 65%'>👆⏳ hold</span><br/><span>invert</span>
              </span>
              <span class="flex-grow-1 text-center lh-1_2 my-auto h7r" style='font-size: 2.5vmin'>
                <span style='font-size: 65%'>👆 tap</span><br/><span>splat</span>
              </span>

              <span v-on:click="changeFluid" class="flex-grow-1 text-center ptr-evts-auto lh-1_2 my-auto h7r" style='font-size: 2.5vmin'>
                <span style='font-size: 65%'>👆👆 tap 2x</span><br/><span>change</span>
              </span>

              <!-- Mobile capture screenshot testing-->
              <!-- Mobile capture isn't working, it is tedious to fix and also screenshots are common and more readily available for mobile users anyway -->
              <!-- Disabling screenshot entirely for now. It's a little bit touchy -->
              <!--
              <span class="flex-grow-1 text-center ptr-evts-auto lh-1_2 my-auto h7r" v-on:click="captureScreenshot">
                <span style='font-size: 65%'>👌 pinch</span><br/><span>save📸</span>
              </span>

              <span class="flex-grow-1 text-right text-center ptr-evts-auto lh-1_2 my-auto h7r" v-on:click="captureScreenshot" v-if="!isMobile" style='font-size: 2.5vmin'>
                <span style='font-size: 65%'>press (s)</span><br/>save📸
              </span>
              -->


            </template>

            <div v-if="mintMode" class='flex-shrink-1 d-flex align-items-center mint-control'>

              <!--
              <span v-if="editionUnclaimed(selected)" class='d-flex justify-content-center align-items-center flex-column'>
                <span class='mb-2'>
                  You won! Unwrap to transfer to your wallet.
                </span>
                <button v-on:click="mintToken" class='btn btn-primary px-0 py-1' style='font-size: 120%'>
                  🎁 Unwrap
                </button>
              </span>
              -->
              <div class='d-flex flex-column align-items-center py-0' v-if="editionUnclaimed(selected)">

                <button class='btn w-100 br-b-0 pt-0 pb-1 justify-content-between' style='font-size: 0.9em'>
                  You won! Unwrap to transfer.
                </button>

                <button v-on:click="mintToken" class='w-100 btn btn-primary br-t-0 py-1'>
                  🎁 Unwrap
                </button>

              </div>

              <div class='futura-light font-weight-lighter h5r' v-else-if="auctionStatus(selected) == 'burned'">
                This auction had no bidders
              </div>

              <div class='d-flex flex-column align-items-center py-0 text-center' v-else-if="auctionStatus(selected) == 'giveaway'">

                <template v-if='!accountIsOwner(selected)'>
                  <!--
                  <button class='btn w-100 pt-0 pb-1 justify-content-center d-flex br-b-0' style='font-size: 0.9em'>
                    This is a giveaway!
                  </button>
                  -->

                  <a v-on:click="openGiveaway(selected)" target='_blank' class='w-100 btn btn-primary py-1 px-1'>
                     ⭐ Enter giveaway ⭐ 
                  </a>
                </template>

                <template v-else>
                  <button class='btn w-100 pt-0 pb-1 justify-content-center d-flex' style='font-size: 0.9em'>

                    <form autocomplete='false'><input v-model='giveawayAddress' type='text' /></form>
                  </button>

                  <a @click='sendGiveaway(giveawayAddress, selected)' class='w-100 btn btn-primary br-t-0 py-1'>
                    Send giveaway to address
                  </a>
                </template>

              </div>

              <div class='d-flex flex-column align-items-center py-0' v-else-if="editionStatus(selected) == 'past' && accountIsOwner(selected)">

                <button class='btn w-100 br-b-0 pt-0 pb-1 justify-content-between d-flex' style='font-size: 0.9em'>
                  <b>high bid</b>
                  <u-s-d-price :price-in-ether="editionHighBid(selected)" :show-zero="true"></u-s-d-price>
                  <router-link :to="{ name: 'account', params: {address: editionHighBidder(selected)} }" target='_blank'>
                    <u>{{ editionHighBidder(selected) || "none"  | truncate(8) }}</u>
                  </router-link>
                </button>

                <button v-on:click="acceptHighBid" class='w-100 btn btn-primary br-t-0 py-1'>
                  👍 Accept high bid
                </button>

              </div>

              <!--

              <div class='past-status d-flex' v-else-if="editionStatus(selected) == 'past' && accountIsOwner(selected)">
                <p class='d-flex flex-column pr-2 h6r'>
                  <span class='font-weight-bold'>
                    high bid
                    <router-link :to="{ name: 'account', params: {address: editionHighBidder(selected)} }" target='_blank'>
                      <u>{{ editionHighBidder(selected) || "none"  | truncate(8) }}</u>
                    </router-link>
                  </span>
                  <template v-if="editionHighBid(selected)">
                    <span> 
                      <u-s-d-price :price-in-ether="editionHighBid(selected)" :show-zero="true"></u-s-d-price>
                      
                    </span>
                  </template>
                  <span v-else>
                    No bids yet
                  </span>
                </p>

                <button v-if="editionHighBid(selected)" v-on:click="acceptHighBid" class='btn btn-primary mx-auto px-0 my-auto' style='height: fit-content'>
                  👍 Accept high bid
                </button>
              </div>
              -->

              <!--
              <div class='past-status d-flex' v-else-if="editionStatus(selected) == 'past' && !accountIsOwner(selected) && accountIsHighestBidder(selected)" style='font-size: 75%'>
                <p class='d-flex flex-column pr-2'>
                  <span class='font-weight-bold'>
                    high bid
                    <router-link :to="{ name: 'account', params: {address: editionHighBidder(selected)} }" target='_blank'>
                      <u>{{ editionHighBidder(selected) || "none"  | truncate(8) }}</u>
                    </router-link>
                  </span>
                  <template v-if="editionHighBid(selected)">
                    <span> 
                      <u-s-d-price :price-in-ether="editionHighBid(selected)" :show-zero="true"></u-s-d-price>
                      / Ξ{{ editionHighBid(selected) || 0 | to4Dp }}
                    </span>
                  </template>
                  <span v-else>
                    None yet
                  </span>
                </p>

                <button v-if="editionHighBid(selected)" disabled="true" class='btn btn-primary mx-auto px-0 my-auto' style='height: fit-content; cursor: default;'>
                  🥇 Your bid is highest! 
                </button>
              </div>
              -->


              <div class='align-items-center futura-light font-weight-lighter' v-else-if="editionStatus(selected) == 'past' && !accountIsOwner(selected) && artistIsOwner(selected)">
                This 🌱 mint auction was won by
                <router-link :to="{ name: 'account', params: {address: editionHighBidder(selected)} }" target='_blank'>
                  <u>{{ editionHighBidder(selected) || "none"  | truncate(12) }}</u>
                </router-link>
              </div>


              <!--
              <div class='align-items-center futura-light font-weight-lighter' v-else-if="editionStatus(selected) == 'past' && !accountIsOwner(selected) && artistIsOwner(selected)">
                This 🌱 mint auction was won by
                <router-link :to="{ name: 'account', params: {address: editionHighBidder(selected)} }" target='_blank'>
                  <u>{{ editionHighBidder(selected) || "none"  | truncate(12) }}</u>
                </router-link>
                but they still need to transfer it to their wallet.
              </div>
              -->

              <!-- User marketplace edition -->
              <!--
              <div class='d-flex align-items-center' v-else-if="editionStatus(selected) == 'past' && !accountIsOwner(selected) && !artistIsOwner(selected)">

                <place-edition-bid :edition="selected" :inline="true" :in-usd="true" class='px-1 flex-grow-1'></place-edition-bid>
                <div class='d-flex flex-column justify-content-between b612 text-right'>
                  <u-s-d-price :price-in-ether="editionHighBid(selected)" :show-zero="true"></u-s-d-price> 
                </div>
              </div>
              -->

              <!-- User Marketplace edition -->
              <div class='d-flex flex-column align-items-center py-0' v-else-if="editionStatus(selected) == 'past' && !accountIsOwner(selected) && !artistIsOwner(selected)">

                <button class='align-self-start no-radius-r d-flex btn w-100 br-b-0 pb-0 pt-1 justify-content-between'>
                  <span>
                    <u-s-d-price :price-in-ether="editionHighBid(selected)" :show-zero="true" ></u-s-d-price>
                    <span v-if='accountIsHighestBidder(selected)'>🥇</span>
                  </span>

                  <span v-if="acceptedBid(selected)">
                    <u-s-d-price :price-in-ether="acceptedBid(selected)" :show-zero="true" ></u-s-d-price> 🖼️
                  </span>

                  <!--
                  <span style='white-space: pre'>
                    {{ formatTimeDiff(selected.endDate,3,9) }} ⏳
                  </span>
                  -->
                </button>

                <place-edition-bid :edition="selected" :inline="true" :in-usd="true" class='w-100 br-t-0' :min-bid="editionMinBid(selected)">
                  <template v-slot:prepend>

                    <div class="input-group-prepend d-flex flex-column">
                      <div class="input-group-text py-1">$</div>
                    </div>

                    <!--
                    <div class="input-group-prepend d-flex flex-column">
                      <u-s-d-price :price-in-ether="editionHighBid(selected)" :show-zero="true" class='input-group-text'></u-s-d-price>
                    </div>
                    -->
                  </template>
                </place-edition-bid>

              </div>

              <!-- Mint marketplace edition (timed auction) -->
              <div class='d-flex flex-column align-items-center py-0' v-else-if="editionStatus(selected) == 'active'">

                <button class='align-self-start no-radius-r d-flex btn w-100 br-b-0 pb-0 pt-1 px-1 justify-content-between' style='font-size: 1.8vmin; white-space: nowrap;'>
                  <span>
                    <u-s-d-price :price-in-ether="editionHighBid(selected)" :show-zero="true" ></u-s-d-price> 🥇
                  </span>
                  <span class='b612'>
                    {{ formatTimeDiff(editionEndDate(selected),2,9) }} ⏳
                  </span>
                </button>

                <place-edition-bid :edition="selected" :inline="true" :in-usd="true" class='w-100 br-t-0' :min-bid="editionMinBid(selected)">
                  <template v-slot:prepend>

                    <div class="input-group-prepend d-flex flex-column">
                      <div class="input-group-text py-1">$</div>
                    </div>

                    <!--
                    <div class="input-group-prepend d-flex flex-column">
                      <u-s-d-price :price-in-ether="editionHighBid(selected)" :show-zero="true" class='input-group-text'></u-s-d-price>
                    </div>
                    -->
                  </template>
                </place-edition-bid>

              </div>

            </div>

            <!-- wallet balance section -->
            <div class='pl-2 d-flex flex-column' style='width: 22%; min-width: 22%' v-if="mintMode" id='wallet' v-on:click="showAccountBalance">
              <button class='d-flex justify-content-center w-100 px-0 py-0 br-b-0 btn op-100 disabled' style='font-size: 1em'>
                <!--<span style='font-size:90%'>{{balance}} 💵</span>-->
                <u-s-d-price :price-in-wei="balance.token" :show-zero="true"></u-s-d-price>💵 
              </button>

              <button class="w-100 px-0 btn btn-primary br-t-0">
                🏦⇌💵
              </button>
            </div>




          </div>


        </div>
        <canvas id="color-canvas" class="mix-difference z-1 h-100" style="width: 110%;" height="1" width="1"></canvas>
      </div>

    </div>

  </div>
</template>

<script>
  import {mapGetters, mapState} from 'vuex';
  import * as _ from 'lodash';
  import * as mutation from '../../store/mutation';
  import * as actions from '../../store/actions';
  import Fluid from '../../assets/vendor/fluid';
  import { addToAnimationLoop, resetAnimationLoop, addResizeListener, waitFor, sleep } from '../../utils';
  import Hammer from 'hammerjs';
  import PlaceEditionBid from '../auction/PlaceEditionBid';
  import USDPrice from '../generic/USDPrice';
  import ETHPrice from '../generic/ETHPrice';
  import LoadingSpinner from '../../components/generic/LoadingSpinner';
  //import GooeyButton from '../../components/generic/GooeyButton';
  //import { ResizeSensor } from 'css-element-queries'  
  import { cachefetch, cachefetchVideo, cachefetchImage, cleanObjectUrls, cacheObj } from '../../services/workers/cachefetch.worker';
  //import EditionCell from './EditionCell'
  import EditionPage from './EditionPage'
  import {BID_EVENTS, TOKENS} from '../../gql/queries';
  import {fromWei, toBN} from 'web3-utils';
  import AccountBalance from '../modal/AccountBalance';
  import Giveaway from '../modal/Giveaway';
  //import Smartwallet from '../../services/smartwallet';

  import { BPopover } from 'bootstrap-vue';
  const bootstrap = { BPopover };
  //import SelfService from '../artist/tabs/SelfService';
  //import ResizeText from 'vue-resize-text';

  const q = (s) => document.querySelector(s)
  const qa = (s) => [...document.querySelectorAll(s)]
  window.q = q
  window.qa = qa

  let destinationTime = 0;
  let destinationSrc = ''

  //let fluid
  //const loadFluid = (c) => {
  //  const opts = { preventDefault: false, BACK_COLOR: {r:255, g:255, b:255} }
  //  //console.log(cvs.width, cvs.height)
  //  const fl = Fluid(c, opts)
  //  fl.bg('invert')
  //  return fl
  //}

  window.fluid = null

  export default {
    name: 'studio-picker',
    components: {
      PlaceEditionBid,
      USDPrice,
      ETHPrice,
      EditionPage,
      //GooeyButton
      LoadingSpinner,
      ...bootstrap
    },
    props: ['startPage'],
    data() {



      const gridSize = 25
      const editionFetchLimit = 1000

      const isMobile = /Mobi|Android/i.test(navigator.userAgent)
      const aspectRatio = window.innerWidth / window.innerHeight


      //const preventDefault = !this.landingPage;
      //console.log('preventDefault', preventDefault)
      const preventDefault = false

      //const editions = _.fill(Array(gridSize), {})
      //const editionsPage = editions.slice(gridSize)
      
      // page and curVideoGroup both start one less than their true start,
      // in order to be initialized with a pageNext() call

      // Weird video groups to filter out
      // 1371, 1374, 1376, 1378, 


      // Create editionsPages object with keys already set
      // That way, when we replace a key, vue is reactive and updates
      // This way we don't have to replace the entire pages object to get vue to react
      const editionsPages = _.zipObject(_.range(100))

      const videoCategory = 'cat'
      //const curVideoGroupIdx = 1349

      //selected: null,
      return {
        debug: 'debug',
        capturing: false,
        selectedIdx: null,
        overviewStats: null,
        userAgent: navigator.userAgent,
        timerInterval: null,
        now: null,
        gridSize: gridSize,
        editionsPages,
        editionFetchLimit: editionFetchLimit,
        locked: false,
        punctuation: '.',
        isMobile,
        preventDefault,
        aspectRatio,
        globalTouch: null,
        mintMode: false,
        grid: null,
        gridKey: 0,
        page: parseInt(this.startPage) || 0,
        destinationSrc: null,
        videoCategory,
        special: 0,
        isLoading: false,
        vidGrpStart: 1349,
        vidGrpEnd: 1479,
        inverted: false,
        giveawayAddress: null
      };
    },
    // // Disable graph integration for now because
    // // it's a little finicky and getting it to work with matic is
    // // also extra complication
    apollo: {
      // WIP moving to using graphql
      /*
      tokens: {
        query: TOKENS(),
        variables() {
          return {
            editionIds: this.pageEditionIds.map(e => e.toString()),
          }
        }
      },
      */
      bidEvents: {
        query: BID_EVENTS('query'),
        variables() {
          return {
            editionIds: this.pageEditionIds.map(e => e.toString()),
            eventTypes: ['BidAccepted', 'BidPlaced']
          }
        },
        subscribeToMore: {
          document: BID_EVENTS('subscription'),
          variables() {
            return {
              editionIds: this.pageEditionIds.map(e => e.toString()),
              eventTypes: ['BidAccepted', 'BidPlaced']
            }
          },
          updateQuery: (previousResult, {subscriptionData: { data }}) => {

            //console.log('bidEvents', data)
            //console.log(this.auction)
            // Always return the new data
            return data
            //console.log('updateQuery', previousResult, data)
          }
        }
      },
      //bidAcceptedEvents: {
      //  query: BID_ACCEPTED_EVENTS,
      //  pollInterval: 1000,
      //  variables() {
      //    return {
      //      editionIds: this.pageEditionIds.map(e => e.toString())
      //    }
      //  }
      //},
      //bidPlacedEvents: {
      //  query: BID_PLACED_EVENTS,
      //  pollInterval: 1000,
      //  variables() {
      //    return {
      //      editionIds: this.pageEditionIds.map(e => e.toString())
      //    }
      //  }
      //}

    },
    watch: {
      page(newPage) {
        const query = new URLSearchParams(window.location.search);
        // change 0-indexed page to 1-indexed for user comprehension
        query.set('page', newPage + 1)
        history.pushState(null, null, '?' + query.toString())
        //this.$router.push({query: {page: newPage}})
        //history.pushState(
        //  {},
        //  null,
        //  `${this.$route.path}?page=${newPage}`
        //)
      },
      //allEditions(val, oldVal) {
      //  console.log('watch allEditions', val.length, oldVal.length)
      //  if(oldVal.length == 0 && val.length > 0) {
      //    // Load active page once editions loaded
      //    this.page = this.activePage()
      //    this.loadPage(this.page)
      //  }
      //}
      //'$route.query.page': function(newPage) {
      //  this.page = newPage;
      //}
    },
    computed: {
      ...mapState([
        'infoService',
        'editionLookupService',
        'currentUsdPrice',
        'kodaV2',
        'account',
        'balance',
        'web3',
        'smartwallet',
        'smartwalletService',
        'TokenMarket',
        'allEditions'
      ]),
      ...mapState('auction', [
        //'auction',
        'bidState',
        'minBidAmount',
        'minBidAmountWei',
        'overtimeIncr',
        'artistAddress',
        'events'
      ]),
      ...mapState('kodaV2', {
        editionsMap: 'assets'
      }),
      ...mapGetters([
        'liveArtists',
        'findArtistsForAddress'
      ]),
      ...mapGetters('kodaV2', [
        'findEdition',
        //'editions'
      ]),
      ...mapGetters('auction', [
        'accountIsHighestBidder',
        'artistIsOwner',
        'accountIsOwner'
      ]),

      editions() {
        return _.sortBy(this.allEditions, 'edition')
      },
      auction() {
        return _(this.allEditions).pick('auction').keyBy('edition') 
      },
      tokensMap() {
        return _.keyBy(this.tokens, 'editionNumber')
      },
      bidAcceptedEvents() {
        return _.filter(this.bidEvents, {eventType: 'BidAccepted'})
      },
      bidPlacedEvents() {
        return _.filter(this.bidEvents, {eventType: 'BidPlaced'})
      },

      pageEditionIds() {
        const pids = _.map(this.pageEditions, 'edition')
        return pids
      },

      pageEditions() {
        const offset = this.page * this.gridSize;
        return this.editions.slice(offset, offset+this.gridSize);
      },


      numPages() {
        return this.vidGrpEnd - this.vidGrpStart
        //return parseInt(this.editions.length / this.gridSize)
      },
      curVideoGroupIdx() {
        return this.videoGroupIdx(this.page)
      },
      curVideoGroup() {
        return this.videoGroup(this.page)
      },
      artworksIssued() {
        return _.get(this.overviewStats, 'summary.items_issued');
      },
      editionCreated() {
        return _.get(this.overviewStats, 'summary.total_editions');
      },
      ethRaised() {
        return _.get(this.overviewStats, 'summary.total_volume');
      },
      totalArtists() {
        return this.liveArtists.length;
      },
      isFirefoxLinux() {
        let ua = window.navigator.userAgent
        return ua.includes('Firefox') && ua.includes('Linux')
      },
      artist() {
        return this.findArtistsForAddress(this.artistAddress)
      },
      selected() {
        const selected = this.editions[this.gridSize*this.page + this.selectedIdx]
        const highBid = this.editionHighBid(selected)

        // Uncomment for debugging
        console.log({selected})
        //console.log(selected, this.editionAuction(selected))
        //console.log(selected && selected.auction)

        return selected
      },
      mockNfts() {
        const hour = 60*60*1000
        //const now = Math.round(this.$store.state.now.getTime() / 1000)
        //const now = Math.round(this.now / 1000);
        //const now = Math.round((new Date()).getTime() / 1000)
        const now = new Date().getTime()
        //const auctionInterval = 15 * 60 * 1000 // seconds
        const auctionInterval = 15 * 60 * 1000 // seconds
        const auctionDuration = hour
        const numPastAuctions = 12;
        const firstAuction = now - (auctionInterval * numPastAuctions)

        const owners = ['ThatGal', 'TraderBruh', 'DoinStuff', 'Ganfreak']

        let startDate, endDate, startPrice, currentPrice, soldPrice, status, randomPrice, owner
        startPrice = 0.1
        let nfts = []
        for(let i = 0; i < 25; i++) {
          startDate = firstAuction + (i * auctionInterval)
          endDate = startDate + auctionDuration
          randomPrice = Math.random()*10
          currentPrice = randomPrice.toFixed(2)
          soldPrice = (randomPrice+10).toFixed(2)
          owner = _.sample(owners)
          //currentPrice = soldPrice = randomPrice
          /*
          if(endDate < now) {
            status = 'past'
            soldPrice = currentPrice = randomPrice
          } else if (startDate < now && endDate >= now) {
            status = 'active'
            currentPrice = randomPrice
            soldPrice = null
          } else {
            status = 'future'
            soldPrice = null
            currentPrice = null
          }
          */

          nfts.push({
            edition: i,
            startDate,
            endDate,
            startPrice,
            currentPrice,
            soldPrice,
            status,
            owner
          })
        }

        return nfts
      },
    },
    filters: {
      mod(val, num) {
        return val % num
      }
    },
    methods: {
      openGiveaway(edition) {
        const giveaway = edition.giveaway || {}
        const {gleam, url} = giveaway
        if(gleam) {
          this.$modal.show(Giveaway, {gleam}, {
            draggable: false,
            resizable: false,
            scrollable: true,
            adaptive: true,
            height: 'auto',
            width: '100%',
            maxWidth: 600,
            //maxWidth: '100%',
            //minWidth: 300,
          })
        } else if (url) {
          window.open(url, '_blank')
        } else {
          window.open('https://twitter.com/NotRealAI', '_blank')
        }
      },
      pageRoute() {
        return parseInt((new URLSearchParams(window.location.search)).get('page'))
      },
      activePage() {
        //return 9
        return this.findEditionPage(this.editions, 'active')
      },
      async sendGiveaway(address, edition) {
        // NOTE: Must be executed while connected directly to Matic

        const { TokenMarket, account } = this
        await this.$store.dispatch(`kodaV2/${actions.APPROVE_MARKET}`, {smartwallet: false}, {root: true})
        // Example address: 0xc7ceF4a1095Cb8ef03A780C9b7a218e9888F7AF6
        // We want to give token to smartwallet address just to keep everything consistent
        const destination = await this.smartwalletService.walletFor(address)
        const tokenId = edition.tokens[0].tokenId
        console.log('destination address & token', destination.address, tokenId)
        const result = await TokenMarket.methods.giveToken(tokenId, destination.address)
          .send({smartwallet: false, from: account})
          .finally(() => {
            this.$store.dispatch(`kodaV2/${actions.REFRESH_AND_LOAD_INDIVIDUAL_EDITION}`, {editionNumber: edition.edition}, {root: true});
          })

        console.log('give result', result)
      },
      async completedTour() {
        const { account } = this
        const completed = await cacheObj('completed-tour') || {}
        return completed[account]
      },
      async saveTourCompletion() {
        const { account } = this
        await cacheObj('completed-tour', {[account]: true}, {merge: true})
      },
      async startTour() {
        /*
          {
            element: '#toggle-market',
            intro: 'Start Here',
            tooltipClass: 'min-tooltip',
            position: 'top'
          },
        */
        const videoStep = {
            element: '.video-container',
            intro: `
            Ceci n'est pas un chat. 
            <br/><br/>
            It's AI-generated. It's 100% real, except actually the opposite of that.
            <br/><br/>
            You can't pet it, but you can admire it. And collect it.
            `,
        }

        const fluidStep = {
            element: '.grid-info',
            intro: `
            This is just for fun. 
            <br/><br/>
            👆 tap - splat colors
            <br/>
            👆⏳ tap & hold - invert colors
            <br/>
            👆👆 tap 2x - change style
            `
        }

        const auctionStep = {
            element: '#toggle-market',
            intro: `
            NotReal Cats are collectibles. 
            <br/><br/>
            <b>Minting</b>
            25 collectibles are minted via auction per day. 
            <br/><br/>
            <b>Curator</b>
            The first owner becomes curator and earns 2% of future sales. 
            <br/><br/>
            <b>Charity</b>
            20% of the first sale and 0.2% of future sales are given to charity. 
            <br/>
            <br/>
            `,
        }

        const marketSteps = [
          {
            element: '.grid-info',
            intro: "Whoa - that's a lot of fur. 😽 <br /><br /> This is the auction page. It shows you the status of every Not Real auction. <br /><br /> Just move your pointer around to choose an auction. Each page represents 24 hours of auctions.",
          },
          {
            element: '.d-grid:not(.offscreen) .grid-sq:first-child',
            intro: `This is an auction. Each auction lasts 48 hours. 
            <br/><br/>
            <b>Minimum</b>
            The minimum bid increment is .
            <br/><br/>
            <b>Overtime</b>
            Bids during the final 5 minutes trigger overtime. 
            <br/><br/>
            Overtime continues until 5 minutes pass without new bids. Also, overtime bids must be at least 5% higher.
            <br/><br/>
            This prevents sniping and gives everyone a fair chance to get bids in.
            `
          },
          {
            element: '.d-grid:not(.offscreen) .grid-sq:first-child p span',
            intro:`The corners show auction details.
            <br/><br/>
            <b>Emoji Meanings</b><br/><br/>
            🥇 <b>Current high bid</b><br/>
            🏆 <b>Auction won</b><br/>
            🌱 <b>Mint auction</b>
            The first auction. Being the first owner makes you the curator, earning you a <b>2% royalty</b> on every future sale.
            <br/><br/>
            🖼️ <b>Gallery</b>
            This has a proud owner. You can place bids, but it's up to the owner to accept.
            <br/><br/>
            📆 <b>Scheduled</b>
            The mint auction is scheduled for the future.
            <br/><br/>
            ❌ <b>Burned</b>
            This had no bids during the mint auction. It's gone now!
            <br/><br/>
            ⭐ <b>Giveaway</b>
            Fair giveaways help grow our community and ensure everyone gets a chance to be an owner.
            `,
          },
          {
            element: '#pagination',
            intro: 'Use these handy dandy buttons to move back and forth through auction pages',
          },
          {
            element: '.mint-control',
            intro: 'This area here is where you will place bids and also see details of the selected furball.',
          },
          {
            element: '#wallet',
            intro: 'This shows your current balance. Touch here to make a deposit and start playing!',
            position: 'left',
            tooltipClass: 'mt-n3'
          }
        ]


        // NOTE:
        // I have no idea why calling runTour twice with the setTimeout seems to be necessary
        // But for some reason, without this delay introJS is not able to find the ".grid-sq:first-child" element
        if (!this.mintMode) {
          this.runTour([videoStep, fluidStep, auctionStep], {doneLabel: 'Next'})
            .then(this.enableMintMode)
            .then(() => {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  this.runTour(marketSteps)
                  resolve(true)
                }, 1000)
              })

            })
            .then(this.saveTourCompletion)
        } else {
          this.runTour([videoStep, auctionStep], {doneLabel: 'Next'})
            .then(() => {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  this.runTour(marketSteps)
                  resolve(true)
                }, 1000)
              })
            }).then(this.saveTourCompletion)
          //this.runTour(marketSteps)
          //  .then(this.saveTourCompletion)
        }

      },
      runTour(steps, opts={}) {
        return new Promise((resolve, reject) => {
          this.intro = this.$intro()
          opts = {
            scrollToElement: false,
            exitOnOverlayClick: false,
            ...opts
          }
          this.intro.setOptions(opts)
          this.intro.addSteps(steps)
          this.intro.start()
          this.intro.oncomplete(() => {
            resolve(true)
          })

          this.intro.onexit(() => {
            resolve(true)
          })
        })
      },

      showAccountBalance() {
        //const {balance, account, web3} = this;
        //console.log('showbalance')
        this.$modal.show(AccountBalance, {}, {
          draggable: false,
          resizable: false,
          scrollable: true,
          adaptive: true,
          height: 'auto',
          width: '100%',
          maxWidth: 600,
          //maxWidth: '100%',
          //minWidth: 300,
        })
      },
      depositToken() {
      },
      withdrawToken() {
      },
      //acceptedBid(edition) {
      //  return edition.auction.highestBid;
      //},
      acceptedBid(edition) {
        const { events } = this;
        if(!edition || !events) { return }

        //const { bidAcceptedEvents } = this.events;
        const { bidEvents } = this;
        if(!bidEvents) { return }

        const bidAccepted = this.bidAcceptedEvents.find(e => e.editionId == edition.edition.toString())
        //const bidAccepted = _.filter(bidEvents, {eventType: 'BidAccepted', editionId: edition.edition.toString()})[0]

        if (!bidAccepted) { return }

        const amountWei =  _.get(bidAccepted, 'eventValueInWei');
        if (!amountWei) { return }

        return fromWei(amountWei, 'ether');
      },
      acceptHighBid() {
        const { selected } = this
        //return this.$store.dispatch(`kodaV2/${actions.APPROVE_MARKET}`);

        // NOTE: All of these parameters are probably unnecessary except editionAuction
        // TODO: cleanup
        const params = {
          bidInEther: this.editionHighBid(selected), 
          edition: selected.edition,
          token: _.get(selected, 'tokens[0].tokenId'),
          owner: this.editionOwner(this.selected),
          ...this.editionAuction(selected)
        };

        return this.$store.dispatch(`auction/${actions.ACCEPT_BID}`, params);
      },
      mintToken() {
        this.acceptHighBid()
        //const params = {
        //  bidInEther: this.editionHighBid(this.selected), 
        //  edition: this.selected.edition,
        //  token: this.selected.tokens[0].tokenId
        //};
        //return this.$store.dispatch(`auction/${actions.ACCEPT_BID}`, params);
      },
      initLoadData() {
        //if(this.$remoteConfig.version != 'nomarket') {
        //  // Turn off auto market toggle for now
        //  //this.toggleMintMode(true)
        //}

        //const loadData = async () => {
        //  this.loadContractData()
        //  // await this.loadArtistEditions()
        //  // await this.loadEditionsPage('active')
        //  console.log('activePage', this.page)
        //  console.log('editions', this.editions)

        //  this.page = this.activePage()
        //  this.loadPage(this.page)
        //  if(this.$remoteConfig.version != 'nomarket') {
        //    //this.toggleMintMode(true)
        //  }
        //}

        this.$store.watch(
          () => this.infoService.currentNetworkId,
          () => this.loadContractData()
        );

        this.loadContractData()

        // Immediately load a page
        this.loadPage(this.page)

        // if an explicit startPage wasn't provided,
        // wait for editions and then go to active page
        if(!this.startPage) {
          this.editionsLoaded().then(() => {
            this.page = this.activePage()
            this.loadPage(this.page)
          })
        }

        //if (this.infoService.currentNetworkId) {
        //  loadData()
        //}
      },
      editionsLoaded() {
        return new Promise((resolve, reject) => {
          if(this.allEditions.length > 0) {
            resolve(this.allEditions)
          } else {
            const unwatch = this.$store.watch(
              () => this.allEditions,
              () => {
                if(!this.allEditions.length) { return }
                unwatch()
                resolve(this.allEditions)
            })
          }
        })
      },
      findEditionPage(editions, status) {
        return parseInt(editions.findIndex(e => this.editionStatus(e) == status) / this.gridSize)
      },
      // This solution is pretty nice, but isn't perfect
      // The next page's imgs aren't cached until we actually see them in the dom
      // But paging back and forth is nice and buttery smooth
      // This problem could be hack-fixed by setting page to +1 where we want,
      // and then quickly hitting a pagePrev()
      cachedPageLabel(page,offset) {
        if (page%3 == 0) {
          switch(offset) {
            case -1: return 'prev';
            case 0: return 'cur';
            case 1: return 'next';
          }
        } else if (page%3 == 1) {
          switch(offset) {
            case -1: return 'next';
            case 0:  return 'prev';
            case 1:  return 'cur';
          }
        } else if (page%3 == 2) {
          switch(offset) {
            case -1: return 'cur';
            case 0:  return 'next';
            case 1:  return 'prev';
          }
        }
      },
      editionsPageCache(label) {
        switch(label) {
          case 'prev': return this.editionsPage(this.page-1);
          case 'cur': return this.editionsPage(this.page);
          case 'next': return this.editionsPage(this.page+1);
        }
      },
      editionsPage(page) {
        const start = this.gridSize * page
        const editions = this.editions.slice(start, start + this.gridSize) 
        //console.log(page, editions)
        return editions
      },
      loadFluid(canvas) {
        const opts = { preventDefault: this.preventDefault, BACK_COLOR: {r:255, g:255, b:255} }
        this.fluid = Fluid(canvas, opts);
        this.fluid.bg('invert')
        //window.fluid = this.fluid
        return this.fluid
      },
      videoGroupIdx(idx) {
        if (idx < 0) { return this.vidGrpStart }
        return idx + this.vidGrpStart
      },
      videoGroup(idx) {
        const original = `${this.videoCategory}/${_.padStart(this.videoGroupIdx(idx), 5, 0)}`
        const specials = [original, 'misc/01601', 'misc/01603', 'misc/01604']
        const vidGroup = specials[this.special % specials.length]
        return vidGroup;
      },
      vidPath(grp, res, mbps) {
        const path = `https://d150o341odfhk5.cloudfront.net/grid/${grp}/vids/vid${res}_${mbps}mbps.mp4`
        //console.log(path)
        return path
      },
      async loadVideo(grp, cacheOnly=false) {
        //console.log('loadVideo', grp)
        const lowRes = this.vidPath(grp, 414, 1)
        const highRes = this.vidPath(grp, this.targetRes, 12)
        this.isLoading = true
        try {
          // Parallel fetch
          const lowResPending  = cachefetchVideo(lowRes)
          const highResPending = cachefetchVideo(highRes)

          let src
          src = await lowResPending
          //let result,src,blob,url;
          //result = (await lowResPending)
          //[src, blob, url] = result
          

          if (!cacheOnly) {
            if (this.curVideoGroup != grp) { return }
            this.destinationSrc = src
          }

          src = await highResPending
          //result = (await highResPending);
          //[src, blob, url] = result

          if (!cacheOnly) {
            if (this.curVideoGroup != grp) { return }
            this.destinationSrc = src
          }
          this.isLoading = false
        } catch (err) {
          // Fall back to just setting the URL directly
          this.destinationSrc = lowRes
          this.isLoading = false
        }
            
        //const bitrates = [2, 6, 12, 20]


        ////let destinationSrc
        //let maxBytes = 0
        //// Fetch a lowres fast video to load
        //// Then progressively fetch and load higher bitrates
        //// destinationSrc seamlessly changes video inside animation loop
        //this.isLoading = true
        //cachefetchVideo(this.vidPath(grp, 414, 1)).then(([src, blob]) => {
        //  this.isLoading = false
        //  if (this.videoGroup(this.page) != grp) {
        //    // Race condition, video changed before we finished loading
        //    return
        //  }

        //  this.destinationSrc = src
        //  //console.log(src)
        //  //console.log('src', src)
        //  maxBytes = blob.size
        //  bitrates.forEach(mbps => {
        //    cachefetchVideo(this.vidPath(grp, this.targetRes, mbps)).then(([src, blob]) => {
        //      if (this.videoGroup(this.page) != grp) {
        //        // Race condition, video changed before we finished loading
        //        return
        //      }
        //      // Load the largest vid seen so far
        //      //console.log(mainVid.readyState)
        //      if (blob.size > maxBytes) {
        //        maxBytes = blob.size
        //        this.destinationSrc = src
        //      }
        //    })
        //  })
        //})
      },
      loadContractData() {
        return this.infoService.getContractStates()
          .then((results) => {
            this.overviewStats = results;
          });
      },
      loadArtistEditions(page) {
        return this.$store.dispatch(
          `kodaV2/${actions.LOAD_EDITIONS_FOR_ARTIST}`, 
          {artistAccount: this.artistAddress, subscribe: false}
        )
      },
      loadEditionsPage(page) {
        if (page == 'active') {
          page = this.findEditionPage(this.editions, 'active')
        }

        const offset = page * this.gridSize;
        const editionNumbers = _.map(this.editions.slice(offset, offset+this.gridSize), 'edition')

        //console.log('editionNumbers', editionNumbers)

        //console.log('setbidaccepted')
        //this.$apollo.queries.bidAcceptedEvents.setVariables({
        //  editionIds: ["100","200"]
        //})

        //console.log('GET_AUCTION_DETAILS_FOR_EDITIONS', editionNumbers)
        this.$store.dispatch(
          `auction/${actions.GET_AUCTION_DETAILS_FOR_EDITIONS}`, 
          {editionNumbers, subscribe: false}, {root: true}
        );

        //this.$store.dispatch(
        //  `auction/${actions.GET_AUCTION_DETAILS_FOR_ARTIST}`, 
        //  {artistAccount: this.artistAddress, limit: this.gridSize, offset}, {root: true}
        //);
      },
      loadPage(pageNum) {
        if(pageNum < 0) { return }
        if(pageNum > this.numPages) { return }

        // Cache in whatever direction we are headed
        // Page precaching unused for now
        // let precachePage
        // if (pageNum >= this.page) {
        //   precachePage = pageNum + 1
        // } else {
        //   precachePage = pageNum - 1
        // }

        this.page = pageNum
        this.loadEditionsPage(this.page)

        // Not sure why, but setTimeout(..., 0) ensures that the video 
        // definitely loads up on mobile. Otherwise the first load doesn't show up.
        setTimeout(() => {
          this.loadVideo(this.videoGroup(this.page))
        }, 0)

        //console.log('loading grp', vidGrp)
        //this.cacheEditionPage(pageNum)
        //this.loadVideo(this.videoGroup(precachePage, true))
        //if (this.mintMode) { this.cacheEditionPage(precachePage) }
      },
      pagePrev() {
        this.loadPage(this.page - 1)
      },
      pageNext() {
        this.loadPage(this.page + 1)
      },
      //cacheRect(rectEl) {
      //  rectEl.rect = rectEl.getBoundingClientRect()

      //  new ResizeSensor(rectEl, _.debounce(() => {
      //    rectEl.rect = rectEl.getBoundingClientRect()
      //  }, 200))

      //  return rectEl
      //},
      intersectRect(x,y, rect) {
        const {left, right, top, bottom} = rect 
        const xB = (x >= left && x <= right)
        const yB = (y >= top && y <= bottom)
        return xB && yB
      },
      headerClick(evt) {
        //console.log('header click', evt)
      },
      infoClick(evt) {
        const { clientX, clientY } = evt
        const inGrid = this.intersectRect(clientX, clientY, this.grid.rect)
        if (inGrid) {
          this.toggleLock()
        }
      },
      videoClick(evt) {
        if(!this.locked) { 
          // Change to next video
          this.pageNext() 
        } else if(this.mintMode) {
          // If locked and in mint mode, unlock it
          this.toggleLock() 
        }
      },
      enableMintMode() {
        return this.toggleMintMode(true)
      },
      disableMintMode() {
        return this.toggleMintMode(false)
      },
      toggleMintMode(mintMode = null) {
        return new Promise((resolve, reject) => {
          // Toggle or override
          this.mintMode = mintMode == null ? !this.mintMode : mintMode
          if(!this.clockInterval) { this.startClock() }

          // Log the user in
          this.$store.dispatch(actions.FORCE_LOGIN_WEB3, false);

          if (this.mintMode) {
            if (this.inverted) { this.invertCanvas(true) }
            //this.page = this.activePage()
            //this.loadPage(this.page)
            if (this.selectedIdx == null) { this.selectNft(0) }
            this.gridKey += 1

            // Hides the "Start Here" popover
            this.$root.$emit('bv::hide::popover', 'toggle-market')
            //this.$refs.popover.$emit('enable')
            //this.startTour('marketTour')
            //this.startTour()

            //q('body').classList.remove('hide-intro-overlay')
            //this.intro.setOption('overlayOpacity', 0.5)
          } else {
            //this.stopClock()
            this.locked = false
          }

          resolve(true)
        })
      },
      changeFluid() {
        if (!this.fluid) { return }
        if (!this.mintMode) {
          // Intuitively, 'change' also means change the video to users
          this.pageNext()
        }
        this.fluid()
      },
      downloadURI(filename, uri, cb) {
          let link = document.createElement('a');
          //link.download = filename;
          link.href = uri;
          link.target = '_blank'
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          if(cb) { cb() }
      },
      togglePreventDefault(e) {
        this.preventDefault = !this.preventDefault
        if (!this.fluid) { return }
        this.fluid.preventDefault(this.preventDefault)
      },
      togglePunctuation() {
        const p = ['.','?','!','…']
        this.punctuation = p[(p.indexOf(this.punctuation) + 1) % p.length]
      },
      editionOwner(edition) {
        return this.editionAuction(edition).owner;
        //const auctionDetails = this.auction[edition.edition] || {}
        //return auctionDetails.owner
        //return _.get(edition, 'tokens[0].owner');
        //if (!edition) { return }
        //const { tokens } = edition;
        //if (!(tokens && tokens[0] && tokens[0].owner)) { return }
        //return tokens[0].owner;
      },
      editionAuction(edition) {
        if(!edition) { return {} }

        return {
          ...edition.auction,
          artist: edition.artistAccount
        }
        //if(!edition) { return {} }
        //const auction = this.auction[edition.edition] || {}
        //return {
        //  ...auction,
        //  artist: edition.artistAccount
        //}
      },
      editionHighBid(edition) {
        //if(this.mintFinished(edition)) { return null }
        let { highestBid } = this.editionAuction(edition)
        if(!highestBid || highestBid == '0') { return null }
        return highestBid 
      },
      editionMinBid(edition) {
        let { highestBidWei } = this.editionAuction(edition)
        highestBidWei = highestBidWei || '0'

        let minBidIncr
        if (this.isOvertime(edition)) {
          minBidIncr = toBN(highestBidWei).divRound(toBN(100)).mul(toBN(this.overtimeIncr)).add(toBN(100))
        } else {
          minBidIncr = toBN(this.minBidAmountWei)
        }

        const minBid = toBN(highestBidWei).add(minBidIncr)

        const usdPrice = this.usdPrice(fromWei(minBid.toString(10), 'ether'))
        return parseFloat(usdPrice)
      },
      minBidUSD() {
        return this.usdPrice(fromWei(toBN(this.minBidAmountWei)))
      },
      editionHighBidder(edition) {
        const { highestBidder, highestBid } = this.editionAuction(edition)
        if(!highestBid || highestBid == '0') { return null }
        return highestBidder
      },
      accountIsEditionWinner(edition) {
        // Auctions in a 'mintsold' state have been won,
        // but not yet claimed by the winner. Thus we must
        // have a special case to show that the high bidder is
        // essentially the owner already
        const {smartwallet, account} = this;
        if(!smartwallet || !account) { return false }

        let owner;
        if(this.auctionStatus(edition) == 'mintsold') {
          owner = this.editionHighBidder(edition)
        } else {
          owner = this.editionOwner(edition)
        }

        const isWinner = RegExp(`${account}|${smartwallet.address}`, 'i').test(owner)

        return isWinner
      },
      // TODO: These are named TERRIBLY but I want to swap them out all at once
      // editionStatus -> editionTimeState
      // auctionStatus -> auctionState
      // auctionState -> 
      editionStatus(edition) {
        if (!edition) { return null }
        const {startDate} = edition;
        const endDate = this.editionEndDate(edition);
        if (!(startDate && endDate)) { return null }

        const now = this.now/1000

        // TODO: Need to add state for secondhand auctions
        // Those auctions will not be scheduled
        if(endDate < now) {
          return 'past'
        } else if (startDate < now && endDate >= now) {
          return 'active'
        } else {
          return 'future'
        }
      },
      //artistIsOwner(edition) {
      //  return this.editionOwner(edition) == this.artistAddress.toLowerCase();
      //},
      //accountIsOwner(edition) {
      //  if(!this.account) { return false }
      //  return this.editionOwner(edition) == this.account.toLowerCase();
      //},
      //editionWinner(edition) {
      //  return this.accountIsHighestBidder(edition.edition) || this.accountIsOwner(edition);
      //},
      mintFinished(edition) {
        return (this.editionStatus(edition) == 'past' && 
                this.editionHighBid(edition) &&
                this.artistIsOwner(edition))
      },
      // WIP moving to using graphql entirely
      accountIsTopBidder(edition) {
        console.log(this.tokens)
        console.log(this.tokensMap)
        //const token = this.tokensMap[edition]
        //console.log('tb', token)
        //return token.currentTopBidder == this.account
      },
      editionUnclaimed(edition) {
        return (this.mintFinished(edition) &&
                this.accountIsHighestBidder(edition) && 
                !this.accountIsOwner(edition))
      },
      //editionUnclaimed(edition) {
      //  if(!edition) { return false }

      //  return this.accountIsHighestBidder(edition.edition) || this.editionOwner(edition) 
      //},
      auctionStatus(edition, mock=false) {
        if (!edition) { return null }

        if(mock) { 
          edition.mockAuctionStatus = edition.mockAuctionStatus || _.sample(['mint', 'burned', 'scheduled', 'sold', 'auction']) 
          return edition.mockAuctionStatus
        }

        let pastStatus
        const highBid = this.editionHighBid(edition)

        const {startDate, endDate} = edition
        //console.log(highBid, this.mintFinished(edition), this.artistIsOwner(edition))
        if (startDate == 1 && endDate == 2 && this.artistIsOwner(edition)) {
          return 'giveaway'
        } else if (this.mintFinished(edition)) {
          pastStatus = 'mintsold'
        } else if(highBid || !this.artistIsOwner(edition)) {
          pastStatus = 'sold'
        } else {
          pastStatus = 'burned'
        }


        //if (highBid || !this.artistIsOwner(edition)) {
        //  pastStatus = 'sold'
        //} else {
        //  pastStatus = 'burned'
        //}

        const edst = this.editionStatus(edition)
        //console.log(highBid)
        const auctionStatusMap = {
          // The high bidder doesn't actually become the owner of the token
          // Until they go to their gallery and officially mint the token
          //'past': this.editionOwner(edition) ? 'sold' : 'burned',
          'past': pastStatus,
          'active': 'mint',
          'future': 'scheduled'
        }

        return auctionStatusMap[edst]
      },
      editionEndDate(edition) {
        return edition.endDate
        //const auction = this.auction[edition.edition] || {}
        //return auction.endDate || edition.endDate
      },
      isOvertime(edition) {

        const origEndDate = edition.endDate
        const auction = this.auction[edition.edition]
        if (auction) {
          return origEndDate != auction.endDate
        } else {
          return false
        }

        //const { endDate }  = auction 

        //console.log(auction, edition)
        //console.log(auction.endDate, edition.endDate)

        //console.log(edition, auction)
        //console.log(origEndDate, endDate)

        //return origEndDate != endDate
      },
      dateTimeStatus(edition) {
        const {startDate} = edition;
        const status = this.auctionStatus(edition)
        const endDate = this.editionEndDate(edition)

        // NOTE: Ummm... I'm not sure why edition here seems to have
        // the correct endDate, even for overtime bidding
        // I thought that the correct endDate would end up in auction details
        // But this seems to be working...
        //let { startDate, endDate } = edition;
        //const auction = (this.auction[edition.edition] || {})
        //if(auction.endDate && endDate != auction.endDate) { 
        //  console.log(endDate, auction.endDate)
        //}
        //console.log(endDate, this.auction[edition.edition].endDate)

        // We use endDate from auction details, which is
        // updated in realtime by graphql. This is to handle overtime bidding
        //const auction = this.auction[edition.edition]
        //const endDate = auction ? auction.endDate : edition.endDate
        //console.log(auction)

        if (status == 'mint' || status == 'auction') {
          return this.formatTimeDiff(endDate, 2)
        } else if (status == 'scheduled') {
          return this.formatTime(startDate)
        }
      },
      emojiStatus(edition) {
        const emojiStatusMap = {
          //'mint': "🌱",
          'mint': "🌱",
          'burned': "❌",
          'scheduled': "📆",
          'sold': "🖼️",
          'mintsold': "🖼️",
          'auction': "⚖️",
          'giveaway': "⭐"
        }


        return emojiStatusMap[this.auctionStatus(edition)]
      },
      emojiMintStatus(edition) {
        // Not using this right now
        return ''
        //if (this.auctionStatus(edition) == 'mint') { return "🌱"}
      },
      editionAuctionStatus(edition) {
        const auctionStatus = this.auctionStatus(edition)
        const emoji = this.emojiStatus(edition)

        return `${emoji}${auctionStatus}`
      },
      editionActive(edition) {
        const {startDate} = edition;
        const endDate = this.editionEndDate(edition)
        const now = this.now/1000
        if(endDate < now) {
          return 'past'
        } else if (startDate < now && endDate >= now) {
          return 'active'
        } else {
          return 'future'
        }
      },
      priceHeader(edition) {
        switch(this.editionActive(edition)) {
          case 'past':   return 'Sold For';
          case 'active': return 'High Bid';
          //case 'future': return 'Start Price';
          case 'future': return '';
        }
      },
      timeStatusHeader(edition) {
        switch(this.editionActive(edition)) {
          case 'past':   return 'Owner';
          case 'active': return 'Auction Ends';
          case 'future': return 'Auction Starts';
        }
      },
      priceStatus(edition) {
        switch(this.editionActive(edition)) {
          case 'past':   return '$' + this.editionHighBid(edition);
          case 'active': return '$' + this.editionHighBid(edition);
          //case 'future': return edition.startPrice;
          case 'future': return '';
        }
      },
      formatTime(time) {
        const tms = time*1000
        //return this.$moment(tms).format("MM/DD/YY h:mmA").replace(' ', "\n")
        return this.$moment(tms).format("M/DD h:mma").replace(' ', "\n")
        //return this.$moment(tms).format("dddd MM/DD/YY h:mmA").replace(' ', "\n")
      },
      formatTimeDiff(time, num=null, fixWidth=null) {

        // For testing purposes
        // time += 60*60*24*117 + 60*60*12

        const td = Object.entries(this.timeDiff(time))
        //const prefix = td.slice(-1)[0] < 0 ? 'ended ' : ''
        //console.log('td', td)

        // Format time parts into something like "14d17h" or "17h45m"
        let tds = td.map(e => {
          const [k,v] = e
          const d = Math.abs(v)
          if (d == 0) { return null }
          return `${d}${k}`
        })

        const start = tds.findIndex(t => !!t)
        let tstr = tds.slice(start, start + (num || td.length)).join('')
        
        if (fixWidth && tstr.length < fixWidth) {
          tstr = _.padStart(tstr, fixWidth, ' ')
        }

        return tstr || null
      },
      timeDiff(time) {
        //console.log(this.now, time)
        //const monthOffset = 31*24*60*60*1000
        //return this.$moment(time * 1000).to(this.$moment(this.now));
        const y  =  this.$moment(time * 1000).diff(this.now, 'years');
        const mo =  this.$moment(time * 1000).diff(this.now, 'months') % 12;
        const w  =  this.$moment(time * 1000).diff(this.now, 'weeks') % 4;
        const d  =  this.$moment(time * 1000).diff(this.now, 'days') % 7;
        const h  =  this.$moment(time * 1000).diff(this.now, 'hours') % 24;
        const m  =  this.$moment(time * 1000).diff(this.now, 'minutes') % 60;
        const s  =  this.$moment(time * 1000).diff(this.now, 'seconds') % 60;

        return {y,mo,w,d,h,m,s}
        //return this.$moment(this.now).to(this.$moment(time * 1000 + 3*monthOffset));

        //const hs = h > 0 ? `${h}h ` : ''
        //const ms = m > 0 ? `${m}m ` : ''
        //const ss = `${s}s `

        //const ts = `${hs}${ms}${ss}`;
        //return ts
      },
      usdPrice: function(ethPrice) {
        if (!ethPrice) { return }
        const value = this.currentUsdPrice * ethPrice;
        // We need to ensure we round 1 penny up so we don't accidentally go below minBid
        return (Math.ceil(value*100)/100).toFixed(2);
      },
      timeStatus(edition) {
        const endDate = this.editionEndDate(edition)
        switch(this.editionActive(edition)) {
          case 'past':   return this.editionOwner(edition);
          case 'active': return this.formatTimeDiff(endDate)
          case 'future': return this.formatTimeDiff(edition.startDate)
        }
      },
      startClock() {
        const setNow = () => {
          this.now = (Math.round((new Date()).getTime() / 1000) * 1000) - 1
        }

        this.clockInterval = setInterval(setNow, 1000)

        //this.now += 48*60*60*1000 // For testing purposes

        //const ivl = 1000
        //window.now = this.now
        //this.clockInterval = null
      },
      stopClock() {
        clearInterval(this.clockInterval)
      },
      selectNft(nftIdx, force) {
        const skipSelect = (nftIdx == this.selectedIdx) || this.locked || !this.editions
        if (skipSelect && !force) { return }
        this.selectedIdx = nftIdx || 0

        // TODO: selected.selected is a mess, I know
        //if (this.selected) { this.selected.selected = false }
        //this.selected = this.editions[this.gridSize*this.page + this.selectedIdx]
        //if (this.selected) { this.selected.selected = true }
      },
      setBid(bid) {
        const placeBid = this.$refs.placeBid
        if(placeBid) {
          placeBid.edition = this.findEdition('100')
          placeBid.form.bid = bid
        }
      },

      gridImgFile(x, y, side=27) {
        const idx = _.padStart((y*27 + x), 5, 0)
        const filename = `${idx}-${x}-${y}.png`
        return filename
      },

      gridCenterCoords(frameSide=27, gridSide=5) {
        let c = [];
        for (var i=1; i <= gridSide; i++) {
          c.push(i*gridSide - 1)
        }
        return c
      },

      editionDates(idx, cfg) {
        const start = cfg.start + (idx * cfg.offset)
        return [start, start + cfg.length]
      },

      async uploadGrid(nftType='cat') {
        const path = `http://localhost:8080/static/${nftType}/hres`
        // These indexes are chosen to select a 5x5 grid of images out of a 27x27 grid of frames.
        // The indexes are chosen to avoid edges which tend to look more "samey" then frames towards the center.
        //const coords = [4, 9, 13, 17, 22]
        //const coords = [9]
        const coords = this.gridCenterCoords(27, 5)
        let uploaded = []
        let x,y;
        let idx = 0;
        const batch = 2;
        const dryRun = false

        const timeCfg = {
          start: parseInt((new Date()).getTime() / 1000),
          offset: 15*60,
          length: 24*60*60
        }

        for (var i=0; i < coords.length; i++) {
          for (var j=0; j < coords.length; j++) {
            idx++
            x = coords[i]
            y = coords[j]
            const filename = this.gridImgFile(x,y,27)
            uploaded.push(filename)
            console.log('saving ', filename)

            const [startDate, endDate] = this.editionDates(idx, timeCfg)
            const edition = await this.saveEdition(path, filename, {
              name: `${nftType} #${batch}.${idx}`,
              description: `${nftType} #${batch}.${idx}`,
              priceInEther: '100',
              totalAvailable: 1,
              enableAuctions: true,
              tags: [nftType],
              startDate,
              endDate
            }, dryRun)
          }
        }

        this.$refs.selfService.saveEditionBatch()

        return uploaded
      },
      async saveEdition(path, filename, edition, dry=false) {
        const ss = this.$refs.selfService

        ss.edition = {
          ...edition,
          confirmArtworkOriginal: true,
          confirmOnlyOnKo: true,
          confirmNoInfringements: true
        }

        ss.setEditionScarcity()

        const img = `${path}/${filename}`

        if (dry) {
          console.log(ss.edition, img)
          return
        }


        return fetch(img)
          .then(res => res.arrayBuffer())
          .then(ar => {
            const file = new File([ar], filename, {type: 'image/png'})
            return ss.uploadFile(file)
          }).then(ipfsImg => {
            console.log(ipfsImg)
            return ss.createEdition({batch: true})
          })
      },
      toggleLock() {
        // Lock only in mint mode
        if (this.mintMode) {
          this.locked = !this.locked;
        }
      },
      fadeOut() {
        //ctx.drawImage(img, 0, 0, 300, 300);

        //var gradient = ctx.createLinearGradient(0, 0, 0,);

        //gradient.addColorStop(0, 'rgba(255,255,255,0)');
        //gradient.addColorStop(1, 'rgba(255,255,255,1)');

        //ctx.fillStyle = gradient;
        //ctx.globalCompositeOperation = 'destination-out';
        //ctx.fillRect(0, 0, 300, 300);
      },
      blend(src, mode, cb) {
        const dest = document.createElement('canvas')
        const ctx = src.getContext('2d')
        const {width, height} = src;
        dest.width = width
        dest.height = height
        ctx.globalCompositeOperation = mode
        if(cb) { cb(ctx, width, height) }
        dest.getContext('2d').putImageData(ctx.getImageData(0,0,width,height),0,0)
        return dest
      },
      scrollNextSection() {
        if (this.aspectRatio > 1) {
          q('.landing-heading').scrollIntoView({behavior: 'smooth'})
        } else {
          q('#landing-header').scrollIntoView({behavior: 'smooth'})
        }
        //window.scrollBy({top:window.innerHeight/2, behavior: 'smooth'})
      },
      captureScreenshot() {
        // Only one capture at a time
        if(this.capturing) { return }
        this.capturing = true

        let cvs
        const fluidCapture = this.fluid.captureScreenshot(q('#color-canvas').height)
        //const cvs = document.createElement('canvas')
        cvs = this.blend(fluidCapture, 'difference', (ctx, w, h) => {
          ctx.fillStyle = 'white'
          ctx.fillRect(0,0,fluidCapture.width,fluidCapture.height)
        })

        let ctx = cvs.getContext('2d')

        const textEls = qa('.landing-text span') 

        // fudge factor. Font coming out enlarged for some reason
        const ff = 0.9

        const fillText = (el,ctx) => {
          const { x, y, height } = el.getBoundingClientRect()
          const st = getComputedStyle(el)
          //ctx.font = st.font
          ctx.font = `${st.fontWeight} ${ff*parseInt(st.fontSize)}px / ${ff*parseInt(st.lineHeight)}px ${st.fontFamily}`
          ctx.fillStyle = st.color
          ctx.fillText(el.innerText, ff*x, ff*(y + height))
        }

        // Fill dodged element
        cvs = this.blend(cvs, 'color-dodge', (ctx, w, h) => {
          const dodgedEl = textEls.find(t => t.classList.contains('color-dodge'))
          fillText(dodgedEl, ctx)
        })

        ctx = cvs.getContext('2d')
        const el = textEls.find(t => !t.classList.contains('color-dodge'))
        fillText(el, ctx)



        let mixMode = q('.grid-info').classList.contains('mix-difference') ? 'difference' : 'source-over'
        if (mixMode == 'difference') {
          cvs = this.blend(cvs, mixMode, (ctx, w, h) => {
            ctx.fillStyle = 'white'
            ctx.fillRect(0,0,fluidCapture.width,fluidCapture.height)
          })
        }


        const vid = q('video[style*="visible"]')
        const vmin = Math.min(cvs.width, cvs.height)
        cvs = this.blend(cvs, 'source-over', (ctx, w, h) => {
          ctx.drawImage(vid, 0, 0, vmin, vmin)
        })


        cvs.toBlob(blob => {
          this.downloadURI('fluid.png', URL.createObjectURL(blob), () => {this.capturing = false})
        }, 'image/png')

      },
      captureVideo(canvas) {
        //let canvas = document.createElement('canvas') 
        let ctx = canvas.getContext('2d');
        let videos = document.querySelectorAll('video')
        let w, h
        for (let i = 0, len = videos.length; i < len; i++) {
            const v = videos[i]
            if (!v.src) continue // no video here
            try {
                w = v.videoWidth
                h = v.videoHeight
                canvas.width = w
                canvas.height = h
                ctx.fillRect(0, 0, w, h)
                ctx.drawImage(v, 0, 0, w, h)
                v.style.backgroundImage = `url(${canvas.toDataURL()})` // here is the magic
                v.style.backgroundSize = 'cover' 
                ctx.clearRect(0, 0, w, h); // clean the canvas
            } catch (e) {
                continue
            }
        }
      },
      invertCanvas(force=false) {
        if (this.mintMode && !force) { return }
        //console.log('INVERT CANVAS')
        //this.fluid.bg('invert')

        //const white = q('.landing-header .c-near-white')
        this.inverted = !this.inverted
        q('.grid-info').classList.toggle('mix-difference')

        const not = q('.landing-header .txt-not')
        const real = q('.landing-header .txt-real')
        if (not && real) {
          not.classList.toggle('color-dodge')
          real.classList.toggle('color-dodge')
        }

        // q('.control-bar').classList.toggle('invert')
        // This causes the "real" to not properly blend
        // q('.landing-header').classList.toggle('invert')

        qa('.video-container').forEach(el => el.classList.toggle('invert-divider'))
        //white.style.color = white.style.color ? '' : 'rgb(0,0,0)'

        //black.classList.toggle('c-near-black')
        //black.classList.toggle('c-near-white')
        //white.classList.toggle('c-near-black')
        //white.classList.toggle('c-near-white')
        // Temporary for dodging
        //black.style.color = black.style.color ? '' : 'rgb(250,250,250)'
        //black.classList.toggle('color-dodge')
        //white.classList.toggle('color-dodge')
      }
    },
    beforeDestroy() {
      document.removeEventListener('keydown', this.keydownListener)
      this.globalTouch.destroy()
      if (this.intro) {
        this.intro.exit()
        this.intro = null
      }
      //this.fluid = null
      //q('#color-canvas').remove()
      //Hammer(document).off('tap press pan')
    },
    destroyed() {
      clearInterval(this.clockInterval)
      resetAnimationLoop()
      if(this.fluid) { this.fluid.releaseCanvas() }
      this.fluid = null
      //window.fluid = null

      // Only turned off for development,
      // Otherwise memory leaks build up
      //cleanObjectUrls()

      //clearInterval(this.gridInterval)
    },
    beforeCreate() {
      //const canvas = q('#color-canvas')
      //fluid(canvas);
    },
    created() {
      //this.initLoadData()
    },
    mounted() {

      const canvas = q('#color-canvas')
      this.loadFluid(canvas)

      
      //setTimeout(() => {
      //  console.log('getting storage')
      //  navigator.storage.estimate().then(quota => {
      //    console.log(quota, 1)
      //    //const totalSpace = quota.quota;
      //    //const usedSpace = quota.usage;
      //    //console.log({quota, totalSpace, usedSpace})
      //  }).catch(err => {
      //    console.log(err)
      //  })
      //}, 1000)


      //q('body').classList.add('hide-intro-overlay')
      //this.$nextTick(this.startTour)
      //this.$tours['studioTour'].start()
      //console.log('balanceee', this.balance);
      //this.$apollo.queries.bidAcceptedEvents.setVariables({
      //  editionIds: ["100", "200", "300"]
      //})
      // this.editionIds = ["100", "200", "300"];
      //console.log('apollo', this.mostEth)
      //console.log('StudioPicker mounted')




      this.initLoadData()

      this.now = (new Date()).getTime() 

      // Simple timeout is the most consistent
      // way I've found to get the fluid simulator to start
      // behaving. Oh well!
      //setTimeout(() => {
      //  this.loadFluid(q('#color-canvas'))
      //}, 1500)

     // window.addEventListener("load", () => {
     //   console.log('asdsadasdsadsadasdas')
     // })

      //const ivl = setInterval(() => { 
      //  if (!cvs) { return }
      //  //const { width, height} = cvs.getBoundingClientRect()
      //  //console.log('canvas', width, height)
      //  //if (!width || !height) { return }
      //  clearInterval(ivl)
      //  this.loadFluid(cvs)
      //},100)

      const rootFontSize = () => {
        let vh = window.innerHeight * 0.01;
        let vw = window.innerWidth * 0.01;
        let vmx = Math.max(vw,vh)
        // Account for video not going above 60% of vmax
        let vmn = Math.min(Math.min(vw, vh), .6*vmx)
        let ar = window.innerWidth / window.innerHeight
      
        let fnt
        fnt = vmx-vmn

        document.documentElement.style.setProperty('--rfs', `${fnt}px`);
        /*
        if (ar <= 4/7) {
          fnt = vw
        } else if (ar <= 1) {
          //fnt = vw * (1/ar)
          fnt = vh - vw
        } else if (ar > 1) {
          fnt = vmx - 
        }
        */

        return fnt
      }

      const windowResized = () => { 
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        let vw = window.innerWidth * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        rootFontSize()
      
        //let fnt = rootFontSize()
        //const gi = q('.grid-info')
        //gi.style.fontSize = `${fnt}px`
      }

      windowResized()
      if(!this.isMobile) {
        // Resize on mobile is or should only be triggered by bottom bar disappearing
        // Listening to that event causes weird janky view updates so don't add a listener
        addResizeListener({windowResized})
      }

      //new ResizeSensor(document.documentElement, _.debounce(windowResized, 200))

      //console.log(this.auction)



      // No longer need this resetFluid, seems the problem was addEvtListeners
      //const resetFluid = () => {
      //  const canvas = q('#color-canvas')
      //  this.loadFluid(canvas)
      //}

      //// The true purpose of this is because the fluid sim
      //// randomly doesn't work sometimes :/
      //// But it also has the benefit of another visual effect 10 seconds in
      //setTimeout(resetFluid, 10000)
      ////window.onload = () => {
      ////  this.loadFluid(canvas)
      ////}

      //new ResizeSensor(document.documentElement, _.debounce(windowResized, 200))
      //windowResized()

      // Annoying resize when download bar appears causes jank...
      // Disable for now to prevent reflow
      // q('#studio-picker').style.height = q('#studio-picker').clientHeight


      const videoContainer = q('.video-container')
      var mainVid = document.getElementById('main-vid'); 
      var altVid = document.getElementById('main-vid-2'); 
      // I think controls off is the default but just to make sure
      mainVid.controls = false
      altVid.controls = false

      // pseudo-globals
      // seek destination for video


      // Get target resolution
      const allRes = [414,768,900,1080]
      let res = allRes.find(r => r >= Math.min(mainVid.clientWidth, mainVid.clientHeight)) 
      this.targetRes = res || Math.max(...allRes)

      //setTimeout(() => {
      //  this.loadVideo(this.videoGroup(this.page))
      //}, 100)

      // Mouse position ratio
      let xr, yr;

      let isSeeking = false

      // Allow scroll events if not on landing page
      //let fluid 
      // Note: Still some kind of race condition going on,
      // resulting in occasional non-loading of fluid canvas.
      // Very hard to repro, this onreadystatechange handler
      // may or may not be actually helping

      Math.dist = (p1, p2) => {
        let [xd, yd] = [p1[0] - p2[0], p1[1] - p2[1]]
        return Math.sqrt(xd*xd + yd*yd)
      }

      // Changing some pseudo-globals here
      const swapVids = () => {
        // Holy shit - This 3-step swap actually fixes the swap causing a flash issue on mobile iOS. Glory be!
        // Update: Sadly, the flash is back :(
        // Update 2: Was able to kinda get it working by precaching next page, but flash continutes to exist if 
        // attempting to swap to a video which is not locally cached
        altVid.style = 'visibility:visible; position:absolute; left:0; top:0;'
        mainVid.style = 'visibility: hidden; position: absolute;'
        altVid.style.position = 'relative'
        
        const swap = mainVid
        mainVid    = altVid
        altVid     = swap
        altVid.src = ''
        // Unload old video as it will continue to seek and impact performance otherwise
        // Complete way of unloading
        //altVid.removeAttribute('src')
        //altVid.pause()
        //altVid.removeAttribute('src')
        //altVid.load()
      }
      

      // UPDATE: Hmm - Not exactly sure now why this was necessary.
      // UX seems to be fine in low battery mode
      // suspend means autoplay denied
      // On iOS low battery mode, video will not play
      // without user interaction. meaning ios users face blank screen if not handled.
      // Also, an unavoidable play button shows up on top of video
      // Listen for a touch anywhere on the page and then immediately play/pause to remove it ASAP
      const onSuspend = ({target}) => {
        const playpause = (evt) => {
          // Calling play/pause immediately causes error logs but its the fastest way
          // I've found to switch tracks without actually playing the track
          const promise = target.play()
          if(promise) {
            promise.then(() => {
              target.pause()
            }).catch(() => {})
          } else {
            target.pause()
          }
          document.removeEventListener('touchstart', playpause)
        }

        document.addEventListener('touchstart', playpause, {passive:true})
        // We can't do offscreen swap, so swap element visibility even though there will be a flash
        //onSeeked()
      }

      const onLoaded = ({target}) => { 
        // console.log('canplaythrough')
        //target.requestFullscreen()
        // ios Safari won't load without a play event
        const promise = target.play()
        if(promise) {
          promise.then(() => {
            target.pause()
          }).catch(() => {})
        } else {
          target.pause()
        }

        swapVids()
        //target.currentTime = destinationTime
        //setTimeout(() => swapVids(), 0)
      }



      [mainVid, altVid].forEach(v => {
        // UPDATE: Hmm - Not exactly sure now why this was necessary.
        // UX seems to be fine in low battery mode
        // There are flashes as videos swap, but seems unavoidable
        // Called only on low battery iOS, will require user interaction to play
        v.addEventListener('suspend', onSuspend)

        // Source loaded
        //v.addEventListener('canplaythrough', onLoaded)
        v.addEventListener('loadeddata', onLoaded)
      })

      const initSeeking = () => addToAnimationLoop(() => {
        if (isSeeking) {
          if (this.destinationSrc && mainVid.src != this.destinationSrc && altVid.src != this.destinationSrc) {
            // If we have a destinationSrc that isn't already displayed (mainVid)
            // or isn't already loading (altVid), then attempt to load it
            altVid.src = this.destinationSrc || ''
            try {
              altVid.currentTime = mainVid.currentTime || destinationTime;
            } catch(e) {
              // error
            }
            // This additional time setting is necessary when transitioning between videos
            // so as to not jump from timestamp 0 to destinationTime

          }

          // This is to ensure the animation loop seeks to a destination time with limited precision
          // Otherwise it could keep seeking forever due to floating point differences
          if (destinationTime.toFixed(3) == mainVid.currentTime.toFixed(3)) {
            return
          }


          if (destinationTime < (mainVid.duration * .9999) && destinationTime > 0) {
            // For smooth transitions, always keep offscreen video synced with mainVid time
            // Not sure if this has a performance penalty but it makes the code way simpler
            try {
              mainVid.currentTime = destinationTime;
              altVid.currentTime = destinationTime;
            } catch(e) {
              //error
            }
          }
        }
      })

      const startSeeking = () => { isSeeking = true }
      const stopSeeking = () => { isSeeking = false }

      initSeeking()
      startSeeking()


      // NOTE: Setting the height in javascript is necessary to make sure
      // that there isn't a gap on mobile. However, it makes styling weird
      // Remember to check here if something with CSS isn't working
      //const navHeight  = q('.navbar').clientHeight
      const grid = q('.overlay-grid-outer'); 
      this.grid = grid
      const gridInfo = q('.grid-info')
      const landingHeader = q('.landing-header')

      // Landing header always visible, can get size
      const gridResized = () => { this.grid.rect = landingHeader.getBoundingClientRect() }
    
      addResizeListener({gridResized})

      const idxToPoint = (idx, side) => [(idx%side) + 0.5, parseInt(idx / side) + 0.5] 
      let xyToIdx = (xRatio, yRatio, sideX, sideY) => {
        if (!sideY) { sideY = sideX }
        xRatio = _.clamp(xRatio, 0, 1)
        yRatio = _.clamp(yRatio, 0, 0.9999) // When y overflows, keep selection in last row
        const x = Math.floor(xRatio * sideX)
        const y = Math.floor(yRatio * sideY)
        return (x+y*sideX)
      }

      let seek = (vid, time) => vid.currentTime = time
      let mouseYPct = (e) => e.clientY / window.innerHeight;
      let mouseXPct = (e) => e.clientX / window.innerHeight;
      //let scrollPct = (el, startY) => (startY - el.getBoundingClientRect().y) / startY;
      let seekPct = (vid, pct) => vid.duration * pct
      let requestSeek = (vid, time) => window.requestAnimationFrame(() => seek(vid, time))

      // let onMouseMove = (e) => requestSeek(mainVid, mainVid.duration * mouseYPct(e))

      //let clientHeight = document.documentElement.clientHeight
      //let clientWidth = document.documentElement.clientWidth

      //let startDraw = (fn) => {
      //  // A single rAF loop apparently collects garbage resulting in
      //  // resulting in GC pauses causing 7 FPS
      //  // Doing a 'ping-pong' loop like this magically solves that
      //  // https://stackoverflow.com/a/23129638
      //  // doAnimate is probably the greater FPS gain though
      //  let drawA = () => {
      //    if (!doAnimate) { return; }
      //    requestAnimationFrame(drawB);
      //    fn()
      //  }

      //  let drawB = () => {
      //    if (!doAnimate) { return; }
      //    requestAnimationFrame(drawA);
      //    fn()
      //  }
      //  drawA();
      //}

      //let mouse = {x:0, y:0};
      //// Nice slow ease. Prevents annoying flicker.
      //let ease = 0.04;
      //let doAnimate = false;

      //let vidLoaded = false;
      // Mobile browsers require some type of 
      // user interaction before a video will load
      /*
      let loadVid = (e) => {
        console.log('loading')
        if (!vidLoaded) {
          mainVid.play()
          // We don't actually want it to play,
          // we just want the browser to load it
          mainVid.pause()
          vidLoaded = true

          // TODO: Is this doing anything?
          document.removeEventListener('touchstart', loadVid)
          document.removeEventListener('mousedown', loadVid)
        }
      }
      */

      /*
      document.addEventListener('touchstart', loadVid)
      document.addEventListener('mousedown', loadVid)
      */



      let xRatio;
      let yRatio;
      let frame;
      let gFrame;

      let xyRatio = (evt, rect) => {
        let c = (evt.touches && evt.touches[0]) || evt
        xRatio = Math.max(c.clientX - rect.left, 0) / rect.width;
        //yRatio = Math.max(c.clientY - rect.top, 0) / rect.height;
        yRatio = Math.abs(c.clientY - rect.top) / rect.height;
        //console.log(rect)
        //console.log(c.clientX, c.clientY)
        return [xRatio, yRatio]
      }




      let setDestinationTime = (frame, side=27) => {
        let chunk = Math.round(mainVid.duration) / (side*side)
        // mainVid and destinationTime are pseudo-globals
        let t_seek = frame * chunk

        //let t = frame / (side*side)
        //let t_seek = t * mainVid.duration
        destinationTime = t_seek
        //console.log(frame, destinationTime)
      }

      // Grid Events 
      // el is the element that actually captures events
      // rectEl is the element that determines XY coordinates
      // They are separated like this because the absolutely positioned
      // canvas blending on top prevents events from getting through to rectEl

      // Ignore events that start with elements having these ids
      // This is so placing a bid doesn't change the selected NFT suddenly
      const ignoreIds = ['makeBidValue', 'makeBidButton', 'wallet']
      const seekOn = (evtType, el, rectEl, invert=false) => {
        if (rectEl == null) { rectEl = el }

        addResizeListener({[rectEl.id || rectEl.className]: () => {
          rectEl.rect = rectEl.getBoundingClientRect()
        }})
        //this.cacheRect(rectEl)
        
        el.addEventListener(evtType, (evt) => {
          if (this.preventDefault) { evt.preventDefault() }
          if (this.locked) { return }
          if (!evtType.includes('move')) { return }
          if (rectEl.width <= 0 || rectEl.height <= 0) { return }

          let [xr, yr] = xyRatio(evt, rectEl.rect)

          //console.log(xr, yr)

          // There are two main areas that cover the
          // entire XY grid of the video
          // we invert to prevent a sudden
          // jump when moving the mouse from one area to the other
          if (invert) {
            if (window.innerWidth > window.innerHeight) {
              xr = 1 - xr
            } else {
              yr = 1 - yr
            }
          }

          const frame = xyToIdx(xr, yr, 27)
          const gridIdx = xyToIdx(xr, yr, 5, 5)

          setDestinationTime(frame)

          if(this.mintMode) { this.selectNft(gridIdx) }
        })


      }


      const hintText = q('.horiz-hint');
      const evt = (el, e, cb) => el.addEventListener(e,cb)
      const preventDefaultFn = (enable) => {
        return () => {
          //if (enable) { alert(enable) }
          //console.log(this)
          this.preventDefault = enable
          this.fluid.preventDefault(enable)
        }
      }
      

      // Enable scrolling if the user is touching the hint text area at bottom
      // Otherwise, canvas and video are locked in place
      const enableScroll = preventDefaultFn(false)
      const disableScroll = preventDefaultFn(true)

      // Canvas is highest z-index over the grid area only,
      // hence the scroll is disabled only over the grid area
      evt(canvas, 'mousedown',  disableScroll)
      evt(canvas, 'touchstart', disableScroll)
      evt(canvas, 'mouseup',    enableScroll)
      evt(canvas, 'touchend',   enableScroll)


      // Without this, a tap on the bid button/pagination/etc. in the hint text area
      // will cause the selection on the grid to suddenly change unexepectedly
      const disablePropagation = (e) => { if(this.mintMode) { e.stopPropagation() } } 
      evt(hintText, 'touchmove', disablePropagation)
      evt(hintText, 'mousemove', disablePropagation)

      seekOn('mousemove', gridInfo, landingHeader)
      seekOn('touchmove', gridInfo, landingHeader)

      //seekOn('mousemove', gridInfo, gridInfo)
      //seekOn('touchmove', gridInfo, gridInfo)

      seekOn('mousemove', videoContainer, videoContainer, true)
      seekOn('touchmove', videoContainer, videoContainer, true)

      this.globalTouch = new Hammer.Manager(document)
      const globalTouch = this.globalTouch
      //const taps = [new Hammer.Tap({taps:3}), new Hammer.Tap({taps:2})]
      //// This stuff is required so that only one of tap, double tap, triple tap is recognized at a time
      //taps[0].recognizeWith(taps[1])
      //taps[1].requireFailure(taps[0])
      //globalTouch.add(taps)

      this.keydownListener = (evt) => {
        // We don't want to accidentally capture keystroke events on inputs and such
        if (evt.target.nodeName != 'BODY') { return }
        if (evt.key == 's') {
          this.captureScreenshot()
        } else if (evt.key == 'n') {
          this.special += 1
          this.loadVideo(this.curVideoGroup)
        }
      };

      document.addEventListener('keydown', this.keydownListener, {passive: true})

      const taps = [
        new Hammer.Tap({taps:2, event: 'doubletap', interval: 350}), 
        //new Hammer.Pan({pointers:1, event: 'pan', threshold: 0}),
        new Hammer.Pan({pointers:2, event: 'doubletouch', threshold: 1})
      ]
      //taps[0].recognizeWith(taps[1])
      //taps[2].recognizeWith(taps[1])
      globalTouch.add(taps)
      globalTouch.on('doubletap', () => {
        this.changeFluid()
        //this.fluid()
      })

      //globalTouch.on('panup', (evt) => {
      //  console.log(evt)
      //  const moveY = evt.srcEvent.movementY
      //  const {additionalEvent} = evt
      //  window.scrollBy(0, -(moveY /(1+window.scrollY)))
      //})

      // Remove pinch handler for now, no mobile screenshot
      //globalTouch.on('doubletouch', (evt) => {
      //  this.captureScreenshot()
      //})


      //globalTouch.on('tap', ({tapCount}) => {
      //  if (tapCount == 1) {
      //    // Splat, handled by fluid sim
      //    //console.log('tap')
      //  } else if (tapCount == 2) {
      //    // Changes the fluid sim
      //    this.fluid()
      //  } else if (tapCount == 3) {
      //    this.invertCanvas()
      //  }
      //})


      const press = [
        new Hammer.Press({time: 1001, event: 'press1'}), 
        //new Hammer.Press({time: 2001, event: 'press2'})
      ]

      //press[0].recognizeWith(press[1])
      //press[1].requireFailure(press[0])

      globalTouch.add(press)
      globalTouch.on('press1', (e) => {
        this.invertCanvas()
      })

      // refresh grid so background images render
      //this.gridKey += 1
      //this.toggleMintMode()
      

      //this.$nextTick(this.startTour)
      //this.startTour()

      setTimeout(() => {
        this.$store.dispatch(actions.BIND_FIREBASE);
      }, 4000)
      //this.$store.dispatch(actions.FORCE_LOGIN_WEB3, false);
    }
  };
</script>


<style>
  body {
    padding-top: 0;
  }

  .dg.ac {
    top: 40vh;
    right: 75vw;
  }

  .invert {
    filter: invert(1);
  }

  .blend-invert {
    filter: invert(1);
    mix-blend-mode: difference;
  }

  .color-dodge {
    mix-blend-mode: color-dodge;
  }

  .color-burn {
    mix-blend-mode: color-burn;
  }

  .unblend {
    mix-blend-mode: unset;
  }

  .blend-burn {
    mix-blend-mode: color-burn;
  }

  /* Inversed color from what we want to mix-blend with black canvas */
  header {
    background-color: #0e0b00
  }

  /*@media screen and (max-width: 767px) {*/
  @media screen and (max-aspect-ratio: 1/1) {

    .flex-portrait-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }

    .flex-portrait-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
  }

  .navbar-position {
    /*display: none;*/
  }


  /*@media screen and (min-width: 767px) {*/
  @media screen and (min-aspect-ratio: 1/1) {
    /*
    .navbar-position {
      position: fixed;
      top: 0;
      right: 0;
      left: auto;
      width: calc(100% - 100vmin);
      min-width: 35vmax;
      z-index: 1030;
    }
    */
    

      /* For iPad like screen, the video element is <100vmin width and height */
      /*width: calc(100vmax - 100vmin);*/
      /* Based on max width of 65vmax of video element */
      /*min-width: calc(100vmax - 66vmax);*/
      /*
      left: calc(100vmin);
      //border-bottom-left-radius: 40px;
      */

    .flex-landscape-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }

    .flex-landscape-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
  }


  .caps {
    text-transform: uppercase;
  }

  .josefin {
    font-family: 'Josefin Sans', sans-serif;
  }

  .roboto {
    font-family: 'Roboto', sans-serif;
  }


</style>

<style lang="scss">
  @import '../../ko-colours.scss';
  @import '../../styles/parallax.scss';
  @import '../../styles/utils.scss';
  //@import '../../styles/neumorphism.scss';
  //@import '../../../node_modules/neumorphism-ui/src/scss/neumorphism/variables.scss';
  //@import '../../../node_modules/neumorphism-ui/src/scss/neumorphism/variables.scss';
  //@import '../../../node_modules/neumorphism-ui/src/scss/neumorphism/components/buttons.scss';
  
  //.header-navbar {
  //  display: none;
  //}
  
  $nfsz: 3.5vmin;

  // Used for breaking flex layout
  .nft-info {
    hr {
      width: 100%;
      margin: 0;
      visibility: hidden;
    }
  }

  .recessed {
    padding: 4px;
    // box-shadow: inset 0px 0px 6px 2px rgba(0,0,0,0.5);
    // box-shadow: inset 3px 3px 8px 2px rgba(0, 0, 0, 0.5), inset -3px -3px 8px 2px rgba(255, 255, 255, 0.5);
    box-shadow: inset 1px 1px 5px 0px rgba(0, 0, 0, 0.5), inset -1px -1px 5px 0px rgba(255, 255, 255, 0.5);
    margin: 10px;
    border-radius: 10px;
    video {
      border-radius: 10px;
      border: 1px solid white;
    }
  }

  .floating {
    box-shadow: 0px 0px 6px 0px black;
    border-radius: 10px;
    z-index: 1;
    video {
      border-radius: 10px;
    }
  }

  // .notreal-logo {
  //   font-family: bodoni-urw;
  //   font-weight: 700;
  //   text-shadow: none;
  //   line-height: 0.9rem;
  //   font-size: 1.8rem;
  //   transform: scale(1, 1.3);
  //   /* 
  //     text-shadow: 
  //     0px 0.25px 2px rgba(255,255,255,0.75),
  //     -1px -1px 2px rgba(0,0,0,0.5);
  //   */
  //   & > *:first-child {
  //     color: black;
  //   }
  //   & > *:last-child {
  //     color: white;
  //     text-shadow: 0 0 2px rgba(0,0,0,0.5), 0 0 2px rgba(0,0,0,0.5), 0 0 2px rgba(0,0,0,0.5), 0 0 2px rgba(0,0,0,0.5);
  //   }
  // }

  //$clr: #000000ff;
  //$clr: #ffffffff;
  $clr: red;
  $w: 10px;
  .stroke {
    text-shadow: -$w -$w 0 $clr, $w -$w 0 $clr, -$w $w 0 $clr, $w $w 0 $clr;
  }

  .container-fluid[role="main"] {
    margin: 0;
  }

  .wh-100vmin {
    width: 100vmin;
    height: 100vmin;
  }

  .h-video {
    height: 100vmin;
    max-height: 65vmax;
  }

  .w-video {
    width: 100vmin;
    max-width: 65vmax;
  }

  .video-container {
    position: relative;
  }




    //background: linear-gradient(
    //  to left,
    //  hsla(0, 0%, 100%, 0),
    //  hsla(0, 0%, 100%, 0.002) calc(19%   /2),
    //  hsla(0, 0%, 100%, 0.008) calc(34%   /2),
    //  hsla(0, 0%, 100%, 0.021) calc(47%   /2),
    //  hsla(0, 0%, 100%, 0.042) calc(56.5% /2),
    //  hsla(0, 0%, 100%, 0.075) calc(65%   /2),
    //  hsla(0, 0%, 100%, 0.126) calc(73%   /2),
    //  hsla(0, 0%, 100%, 0.194) calc(80.2% /2),
    //  hsla(0, 0%, 100%, 0.278) calc(86.1% /2),
    //  hsla(0, 0%, 100%, 0.382) calc(91%   /2),
    //  hsla(0, 0%, 100%, 0.541) calc(95.2% /2),
    //  hsla(0, 0%, 100%, 0.738) calc(98.2% /2),
    //  hsla(0, 0%, 100%, 1) 0%, calc(100%  /2),
    //  hsla(0, 0%, 100%, 0.738) calc(19%   /2 + 50%),
    //  hsla(0, 0%, 100%, 0.541) calc(34%   /2 + 50%),
    //  hsla(0, 0%, 100%, 0.382) calc(47%   /2 + 50%),
    //  hsla(0, 0%, 100%, 0.278) calc(56.5% /2 + 50%),
    //  hsla(0, 0%, 100%, 0.194) calc(65%   /2 + 50%),
    //  hsla(0, 0%, 100%, 0.126) calc(73%   /2 + 50%),
    //  hsla(0, 0%, 100%, 0.075) calc(80.2% /2 + 50%),
    //  hsla(0, 0%, 100%, 0.042) calc(86.1% /2 + 50%),
    //  hsla(0, 0%, 100%, 0.021) calc(91%   /2 + 50%),
    //  hsla(0, 0%, 100%, 0.008) calc(95.2% /2 + 50%),
    //  hsla(0, 0%, 100%, 0.002) calc(98.2% /2 + 50%),
    //  hsla(0, 0%, 100%, 0)     calc(100%  /2 + 50%),
    //); 



  .w-vw-vh {
    width: calc(100vw - 100vh);
  }

  .f-vw-vh {
    font-size: calc((100vw - 100vh)*0.66);
  }
  .f-serif-title {
    //font-family: 'DM Serif Display', serif;

  }

  .btn {
    width: 10rem;
    font-size: 1rem;
    font-family: 'Roboto 400', sans-serif;
  }

  .btn-xlg {
    font-size: 2rem;
  }

  .header-image {
    height: 100vh;
    background-size: contain;
    background-position-x: 60px;
    background-repeat: no-repeat;
  }

  /*
  .not-real {
    width: calc(100vw - 100vh);
    & span {
      line-height: 96%;
    }
  }
  */

  .h-50 {
    height: 50%;
  }



  #cat {
    //transform: translate3d(0, 0, 0);
  }

  .ff-notice {
    // White because of invert filter applied to everything
    color: white;
    font-family: courier;
    font-style: italic;
    z-index: 10;
    text-align: center;
    margin: 0 auto;
    position: fixed;
    bottom: 0;
    left: 20px;
  }

  .stroke-text-white {
    -webkit-text-stroke: 1px rgba(255,255,255,0.12);
  }

  .stroke-text-black {
    -webkit-text-stroke: 1px rgba(0,0,0,0.35);
  }

  //.not {
  //  -webkit-text-stroke: 1px rgba(255,255,255,0.08);
  //}

  //.real {
  //  -webkit-text-stroke: 1px rgba(0,0,0,0.08);
  //}

  .y-scale-130 {
    transform: scale(1, 1.3);
  }

  .landing-header {
    font-family: bodoni-urw;
    /*font-weight: 700;*/
    font-style: normal;
    /*font-size: calc((100vmax - 100vmin) / 2.75);*/
    /*letter-spacing: 0.05em;*/
    /*line-height: .55em;*/
    user-select: none;
  }

  .bg-transparent {
    background: transparent;
  }



  .ptr-evts-none {
    pointer-events: none;
  }

  .ptr-evts-auto {
    pointer-events: auto;
  }

  .text-stroke {
    -webkit-text-stroke: 1px rgba(0,0,0,0.5);
  }

  //.not-real {
  //  //color: #100;
  //  color: rgba(0,0,0,0.08);
  //  font-family: bodoni-urw;
  //  font-weight: 700;
  //  font-style: normal;
  //  transform: scale(1, 1.3);
  //  // Set font size as portion of remaining space after video takes 100vh
  //  // Set all other spacing properties based on font size
  //  font-size: calc((100vmax - 100vmin) / 2.75);
  //  letter-spacing: 0.05em;
  //  line-height: .55em;
  //  //width: 2.5em;
  //  // Pass through mouse events to canvas
  //  pointer-events: none;
  //  // Prevent text highlight from lots of clicking
  //  user-select: none;
  //  margin-left: 0.25em;
  //  margin-bottom: 0.35em;
  //}
  //#not-real {
  //  font-size: 22vw;
  //  line-height: 33vh;
  //  font-family: 'DM Serif Display', serif;
  //}

  .hide-absolute {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    visibility: hidden !important;
  }
  /*@media screen and (max-width: 767px) {*/


  .grid-info {
    font-size: var(--rfs);
    position: relative;
    // Ensure that introjs doesn't change position property
    //position: static !important;
  }

  // PORTRAIT
  @media screen and (max-aspect-ratio: 1/1) {
    .w-vmin-sq {
      width: calc(100vmax - 100vmin - 62px);
    }

    /*$fadeOut: 8em;*/
    .grid-info {
      // It was a good shot but give up on trying to blend video and sim for now
      // &:before {
      //   content: "";
      //   position: absolute;
      //   z-index: 1;
      //   top: 0;
      //   background-image: linear-gradient(to bottom, transparent, white 90%);
      //   width:100%;
      //   height: 100vmin;
      // }

      height: calc(100vmax - 100vmin);

      // adds colorful little dividers around canvas area
      // height: calc(100vmax - 100vmin + 1em);
      // top: -0.5em;

      // Might put this back in later
      //max-height: calc(100vmax - 100vmin);
      //top: -3em;
    }

    .overlay-grid-outer {
      width: 100vmin;
      height: 100%;
      //height: calc((var(--vh) * 100) - 100vmin);
      //height: calc(100vmax - 100vmin);
      // Based on 65vmax limit for video
      // Also give a tiny bit of breathing room to prevent overflow
      //min-width: calc(35vmax - 60px);
      //min-height: calc(35vmax - 60px);
      //max-width:  50vmin;
      //max-height: 50vmin;

      /*width: 100%;*/
    }

    #color-canvas {
      /*
      height: 100%;
      width: 100%;
      */
      /*height: 100vh;*/

      // Height above 100% Adds extra strip of black to bottom that acts as cool divider line
      ////height: 116%; 
      width: 100vw;
      height: calc(100vmax - 100vmin);
      //height: 100%;
      //top: -8%;
      position: absolute;
    }

    // Fade between video and fluid sim
    // pointer-events: none, very important otherwise no events will get through
    .landing-header::after, .video-container::after {
      content: '';
      position:absolute;
      height: 100%;
      width: 100%;
      left: 0;
      pointer-events: none;
      z-index: 5;
    }

    // We can't directly manipulate a pseudoelement from javascript
    // So we have to create a special class for inverting the color
    .grid-info.invert-divider::after, .video-container.invert-divider::after {
      filter: invert(1);
    }

    .video-container::after {
      height: 30%;
      bottom: 0;
      background: linear-gradient(to top, scrimGradient(#fff));
    }

    .landing-header::after {
      height: 30%;
      top: 0;
      // Opposite color of video-container because of mix-difference filter
      background: linear-gradient(to bottom, scrimGradient(#000));
    }

  }


  // LANDSCAPE
  @media screen and (min-aspect-ratio: 1/1) {
    .horiz-hint {
      /*padding-left: 3rem !important;*/
    }
    .nft-info {
      height: 75%;
    }

    .w-vmin-sq {
      width: calc(100vmax - 100vmin);
    }

    .grid-info {
      //width: calc(100% - 100vmin);
      width: 100%;
      //padding-top: 60px;
    }

    // Removing the height: attribute fixes the grid overflowing on my widescreen monitor
    // It seems to do this without really affecting correct layout on other screen types
    // Still need to possibly revisit
    .overlay-grid-outer {
      /*width: calc(100vmax - 100vmin);*/
      //min-width: calc(100vmax - 66vmax);
      // Had to slightly alter min-height for landscape iphoneX
      //min-height: calc(100vmax - 75vmax);
      /*height: calc(100vmax - 100vmin);*/
      /*min-height: calc(100vmax - 66vmax);*/
      /*min-height: calc(100vmax - 100vmin);*/
      height: 100%;
      width: 100%;
    }

    #color-canvas {
      position: absolute;
      width: 100vw;
      right: 0;
      height: 100%;
    }

    // Fade between video and fluid sim
    // pointer-events: none, very important otherwise no events will get through
    .landing-header::after, .video-container::after {
      content: '';
      position:absolute;
      height: 100%;
      width: 100%;
      top: 0;
      pointer-events: none;
      z-index: 5;
    }

    // We can't directly manipulate a pseudoelement from javascript
    // So we have to create a special class for inverting the color
    .grid-info.invert-divider::after, .video-container.invert-divider::after {
      filter: invert(1);
    }

    .video-container::after {
      width: 20%;
      right: 0;
      background: linear-gradient(to left, scrimGradient(#fff));
    }

    .landing-header::after {
      width: 20%;
      left: 0;
      // Opposite color of video-container because of mix-difference filter
      background: linear-gradient(to right, scrimGradient(#000));
    }
  }


  // This combined with setProperty ensures that the height of the page does not
  // overflow beneath the bottom mobile navbar
  .h-100vh {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }

  .lock-icon {
    color: white;
    font-size: 2rem;
    z-index: 10;
  }





  .grid-overlay {
    height: 100vmin;
    width: 100vmin;
    position: absolute;
    margin: 0;
    background-image:
      url("data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs="),
      url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2NgYGBoAAAAhQCBtVE1xwAAAABJRU5ErkJggg=="),
      repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%),
      repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%);
    background-size: calc(100% / 5) calc(100% / 5);
  }

  $nav-h: 62px;


  .d-grid {
    display: grid;
  }

  .grid-2x2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .grid-3x3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .overlay-grid {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);

    /*grid-template-columns: repeat(4, 1fr);*/
    /*grid-template-rows: repeat(6, 1fr);*/
    border: 1px solid rgba(0,0,0,0.35);
    // border-radius: 6px;
    //overflow: hidden;
    //width: calc(100% + 2px);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .overlay-grid-outer {
    position: relative;
    //width: calc(100vmax - 100vmin - 62px);
    // width: calc(100% + 2px);
    //height: 100%;
    //padding-bottom: 100%;
    //height: 0;
    //margin-bottom: 62px;
    //margin-left: -2px;
  }


  .c-white {
    color: white;
  }

  .c-black {
    color: black;
  }

  .c-near-white {
    color: rgb(250,250,250);
  }

  .c-near-black {
    color: rgb(5,5,5);
  }

  .grid-sq {
    //z-index: 2;
    border: 1px solid rgba(255,255,255,0.35);
    background-size: cover;
    background-position: center;
    opacity: 0.8;
    /*background-opacity: 0.7;*/
    /*opacity: 0.7;*/
    font-size: 0.1em;
    font-family: 'Josefin Sans', sans-serif;

    p {
      margin: 0;
      //font-size: 100%
      //font-size: 150%;
      //font-size: 60%;
    }

    .status-info {
      opacity: 0.5;
      font-size: 150%;
    }

    .emoji-status {
      background: none;
    }

    &.grid-sq-hover {
      .emoji-status {
        background: rgba(255,255,255,0.2);
      }

      background-color: rgba(0,0,0,0.75);
      z-index: 2;
      filter: invert(1);
      opacity: 1;
      // border: 1px solid rgba(0,0,0,0.6);
      // transition: border 1s;
      // transition-timing-function: ease-in-out;

      .status-info {
        opacity: 1;
        font-weight: bolder;
        color: white;
      }
    }
  }

  .border-light {
    border: 1px solid rgba(255,255,255,0.05);
  }

  .grid-sq-border {
    //border: 1px solid rgba(0,0,0,0.05);
  }


  /*
  .grid-sq-hover {
    // background-color: rgba(255,255,255,0.4);
    // border: 1px solid rgba(255,255,255,0.6);
    // transition: border 1s;
    // transition-timing-function: ease-in-out;
  }
  */


 .blur-bg-invert {
    //backdrop-filter: blur(3.5rem);
    //-webkit-backdrop-filter: blur(3.5rem);
    background: rgba(255,255,255,0.5);
  }

  .frosted {
    background: hsla(210, 10%, 30%, 0.05);
    backdrop-filter: blur(1.5rem);
  }

  .mix-difference {
    mix-blend-mode: difference;
  }

  .mix-multiply {
    mix-blend-mode: multiply;
  }

  .info-card {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    height: 200px;
    width: 300px;
    color: white;
  }

  .bg-transparent {
    background: transparent;
  }

  .z-2000 {
    z-index: 2000;
  }

  .z-2 {
    z-index: 2 !important;
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }

  .m-1pct {
    margin: 1%;
  }

  .p-1pct {
    padding: 1%;
  }

  .vertical-hint {
    height: 100%;
    text-align: right;
    writing-mode: vertical-rl;
    text-orientation: upright;
    kerning: 0;
    letter-spacing: -0.5em;
    //animation: bounceY 1s ease-in-out infinite;
    //transform: translateY(-50%);
    right: 0.5em;
    top: -25%;
  }
  .horiz-hint {
    width: 100%;
    text-align: center;
    //bottom: 1em;
  }

  .bounce {
    animation: bounceX 1s infinite;
  }

  .bounceY {
    animation: bounceY 1s infinite;
  }
  
  @keyframes bounceX {
    0% {
      transform: translate3d(0, 0, 0);
    }

    50% {
      transform: translate3d(-4px, 0, 0);
    }
    
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes bounceY {
    0%, 100% {
      transform: translate3d(0, 0, 0);
    }

    50% {
      transform: translate3d(0, -4px, 0);
    }
  }

  .hide {
    visibility: hidden!important;
  }

  .isolate {
    isolation: isolate;
  }

  .usr-select-none {
    user-select: none;
  }

  .touch-action-auto {
    touch-action: auto;
  }

  .touch-action-y {
    touch-action: pan-y;
  }

  .touch-action-none {
    touch-action: none;
  }

  .pagination-arrow {
    font-size:150%;
    //color: rgba(50,50,50,0.8);
    color: $primary;
    opacity: 0.8;
    padding: 0;
    padding: 0 0.1em;
    //-webkit-text-stroke: 4px $primary-light;
    &:hover {
      color: rgba(10,10,10,1.0);
      -webkit-text-stroke: 4px $primary;
      cursor: pointer;
    }
  }

  .bg-after-none::after {
    background: none !important;
  }

  .edition-num {
    //paint-order: stroke fill;
    //stroke: #000;
    //stroke-width: 5px;
  }

  .moji {
    filter: invert(1)
  }

  .label-black {
    background-color: rgba(80,80,80,0.6);
  }

  .grid-sq-hover .label-white {
    background-color: rgba(255,255,255,0.8);
    opacity: 1;
  }

  .label-white {
    opacity: 0.75;
    background-color: rgba(255,255,255,0.75);
    span, p { color: rgba(20,20,20,0.85); }

    //span, p { color: rgba(0,0,0,0.45); }
    //opacity: 0.85;
  }

  .control-bar {
    filter: invert(1);
    overflow: hidden;
    //height: 60px;
    //background: #748ed04a;
    //backdrop-filter: blur(4px);
  }

  .video-container {
    background: white !important;
    //transform: translate3d(0,0,0);
  }

  video {
    background: white !important;
    //transform: translate3d(0,0,0);
  }

  .offscreen {
    position: absolute !important;
    left: -10000px !important;
  }

  .past-status {
    p {
      margin: 0;
      font-size: 100%;
    }
  }

  .status-overlay {
    position:absolute;
    background: rgba(255,255,255,0.75);
    opacity: 0.85;
    z-index: 10;

    &.tl {
      top:0;
      left:0;
      border-bottom-right-radius: 10px;
    }

    &.tr {
      top:0;
      right:0;
      border-bottom-left-radius: 10px;
    }
  }

  .br-r-0 {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    button, *[class*="input-group"] {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }

  .br-l-0 {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    button, *[class*="input-group"] {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
  }

  .br-t-0 {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    button, *[class*="input-group"] {
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }
  }

  .br-b-0 {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    button, *[class*="input-group"] {
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }

  .b-none {
    border: none;
  }

  .neumorph button {
    border: none;
  }

  .mint-control > * {
    width: 100%;
  }

  .v-tour__target--highlighted {
    box-shadow: 0 0 0 99999px rgba(0,0,0,.4);
  }

  .min-tooltip {
    .introjs-bullets {
      display: none;
    }
    .introjs-tooltipbuttons {
      display: none;
    }
    .introjs-tooltip-header {
      display: none;
    }
    text-align: center;
    min-width: auto !important;
    .introjs-tooltiptext {
      white-space: nowrap;
      padding: 5px;
    }
  }

  .introjs-overlay {
    pointer-events: none;
  }
  .introjs-helperLayer {
    pointer-events: none;
  }

  body.hide-intro-overlay {
    .introjs-overlay, .introjs-helperLayer {
      opacity: 0 !important;
    }
  }

  .introjs-showElement {
    z-index: initial !important;
  }

  .mt-n3 {
    margin-top: -1rem;
  }

  .header-visibility {
    display: none;
  }

  //.introjs-skipbutton {
  //  display: none;
  //}



</style>

<template>
  <div class='d-grid overlay-grid'>
    <!-- apparently using edition.lowResImg as the key was 
         causing the weird flash of cell bg image on updates -->
    <edition-cell v-for="edition in editionsPage" 
      :key="edition.edition"
      :edition="edition"
      :locked="locked"
      :selected="selected == edition"
      :accountIsEditionWinner="accountIsEditionWinner"
      :emojiStatus="emojiStatus"
      :editionStatus="editionStatus"
      :auctionStatus="auctionStatus"
      :editionHighBid="editionHighBid"
      :editionUnclaimed="editionUnclaimed"
      :acceptedBid="acceptedBid"
      :dateTimeStatus="dateTimeStatus">
    </edition-cell>
  </div>
</template>

<script>
  import EditionCell from './EditionCell'

  export default {
    name: 'edition-page',
    props: ['editionsPage','selected','emojiStatus','auctionStatus', 'editionStatus', 'editionUnclaimed', 'editionHighBid','dateTimeStatus', 'accountIsEditionWinner', 'acceptedBid', 'locked'],
    components: {
      EditionCell
    },
    mounted() {
      //console.log('re-mounted edition page')
      //console.log('mounted')
      //console.log(this.editionsPage)
    }
  }
</script>

<style scoped lang="scss">
</style>

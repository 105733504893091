<template>
  <div>
    <studio-picker></studio-picker>
  </div>
</template>
<script>
  import * as _ from 'lodash';
  import {mapState} from 'vuex';
  import * as mutation from '../store/mutation';
  import GalleryCard from '../components/cards/GalleryCard';
  import LoadingSpinner from '../components/generic/LoadingSpinner';
  import StudioPicker from '../components/studio/StudioPicker';

  export default {
    name: 'studio',
    components: {
      StudioPicker,
      LoadingSpinner,
      GalleryCard,
    },
    data() {
      return {
        latest: [],
        trending: [],
      };
    },
    methods: {},
    computed: {
      ...mapState([
        'feedService',
        'editionLookupService',
      ]),
      ...mapState('kodaV2', [
        'assets',
      ]),
    },
    created() {

      /////////
      // API //
      /////////

      const loadEventData = () => {

        this.feedService.latestCreations(15)
          .then((results) => {
            if (results.success) {
              const {data} = results;
              this.latest = data;
              this.$store.commit(`kodaV2/${mutation.SET_EDITIONS}`, data);
            }
          });

        this.feedService.trendingEditions(12)
          .then((results) => {
            // console.log(results);
            if (results.success) {
              const {data} = results;
              this.trending = _.uniq(_.map(data, 'edition'));
              this.$store.commit(`kodaV2/${mutation.SET_EDITIONS}`, data);
            }
          });
      };

      // Turn this off for now, loading editions handled by inner StudioPicker component
      /*
      this.$store.watch(
        () => this.feedService.currentNetworkId,
        () => loadEventData()
      );

      if (this.feedService.currentNetworkId) {
        loadEventData();
      }
      */
    },
  };
</script>

<style>
.header-navbar {
  /* display: none !important; */
}
</style>
<style scoped lang="scss">
  @import '../ko-colours.scss';
  @import '../ko-card.scss';

  .btn {
    width: 10rem;
    font-size: 1rem;
    font-family: 'Roboto 400', sans-serif;
  }

  .btn-sm {
    width: 7rem;
    font-size: 0.8rem;
    font-family: 'Roboto 400', sans-serif;
  }

  h1 {
    color: $realBlack;
  }

  h2 {
    color: $realBlack;
  }

  h6 {
    color: $realBlack;
  }

  .join-banner {
    background-image: url('../../public/static/KOJoin_patt.svg');
    background-size: auto;
  }


</style>

<script>
import * as _ from 'lodash';
import ArtistApiService from '../../services/artist/ArtistApiService'
const escapeMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;',
    '`': '&#x60;',
    '=': '&#x3D;'
};


export default {
  name: 'instafeed',
  props: {
    username: { type: String },
    tag: { type: String },
    sort: { type: String, default: 'timestamp' },
    sortOrder: { type: String, default: 'desc' },
    types: { type: Array, default: () => ['feed', 'clips'] },
    onFetch: { type: Function },
    limit: { type: Number, default: 25 }
    //types: { type: Array, default: ['feed', 'igtv'] }
  },
  data() {
    return {
      host: 'https://www.instagram.com',
      info: {},
      media: [],
    }
  },
  created() {
    this.fetchPosts()
  },
  methods: {
    async fetchPostsTest() {
      ////
      const service = new ArtistApiService()
    },
    async fetchPosts() {
      const token = 'IGQVJYWWN3ZAjJCSkZA1cFEzOVN6UmZAhSkFlejRmeDllVmZAyVkl6SGVrY1B3NS13elhnbUw4QlFRcnNSR25iUUx3T3pOM2QzcTh5ZAHFFRldDVkFSN2o0T2dXcEJ2MXE3R0pYNkFHcHFQdHBrY2tUaEFZAMwZDZD'
      const host   = 'https://graph.instagram.com'
      const user   = 'me'
      const edge   = 'media'
      const fields = 'id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username,children'

      const endpoint = `${host}/${user}/${edge}?fields=${fields}&access_token=${token}`
      const result = (await (await fetch(endpoint)).json())
      this.media = result.data.map(this.getMedia).slice(0,this.limit)
      if(this.onFetch) { this.onFetch(this.media) }
    },
    async fetchPostsOld() {
      const path = this.username || `explore/tags/${this.tag}`
      const url = `${this.host}/${path}`

      try {
        if (!window.fetch) {
          throw new Error(
            'This browser does not support the Fetch API natively. A polyfill is needed.'
          )
        }

        const endToken = ';</scr'+'ipt>'

        // Hard-coded feed method
        //const res = await fetch('./static/feed.html')

        // URL fetch
        // Seems like Instagram has some sort of rate limit for fetching the feed publicly
        // Sometimes it flakes out and breaks. If this isn't working, fallback to hard-coded feed method.
        // Sometimes the hard-coded feed will need be periodically re-downloaded due to out-of-date URL signatures
        const res = await fetch(url)
        if (!res.ok) { throw res }
        const text = await res.text()
        // WEIRD ALERT: If you don't split the script tag into two parts, the browser freaks out while parsing
        const data = JSON.parse(text.split("window._sharedData = ")[1].split(endToken)[0])

        const page = data.entry_data.ProfilePage || data.entry_data.TagPage
        const feed = page[0].graphql.user || page[0].graphql.hashtag

        feed.followers = feed.edge_followed_by.count
        feed.following = feed.edge_follow.count
        const info = _.omitBy(feed, (v,k) => k.startsWith('edge'))
        this.info = _.mapKeys(info, (v,k) => _.camelCase(k))

        const mediaEdges = ['edge_owner_to_timeline_media', 'edge_hashtag_to_media', 'edge_felix_video_timeline'];
        const nodes = _.flatten(Object.values(_.pick(feed, mediaEdges)).map(o => o.edges))
        this.media = nodes.map(this.getMedia).filter(({type}) => this.types.includes(type))

        this.media.sort((a,b) => {
          let s1, s2
          if (this.sortOrder == 'asc') {
            [s1, s2] = [a, b]
          } else {
            [s1, s2] = [b, a]
          }
          switch (this.sort) {
            case 'timestamp':
              return s1.timestamp - s2.timestamp;
            case 'likes':
              return s1.likes - s2.likes;
            case 'views':
              return s1.views - s2.views;
          }
        })

      } catch (err) {
        this.onError(err)
      }
    },
    onError(err) {
      console.error(err)
    },
    getMedia(node) {
      return {
        likes: null,
        views: null,
        caption: node.caption,
        image: node.thumbnail_url || node.media_url,
        video: node.media_url,
        timestamp: new Date(node.timestamp),
        url: node.permalink,
        type: node.media_type
      }
    },
    getMediaOld(nodeParent) {
      return {
        likes: this.getLikes(nodeParent),
        views: this.getViews(nodeParent),
        caption: this.getCaption(nodeParent),
        image: this.getImage(nodeParent),
        timestamp: nodeParent.node.taken_at_timestamp,
        url: `https://www.instagram.com/p/${nodeParent.node.shortcode}`,
        type: nodeParent.node.product_type
      }
    },
    escapeString(str){
        return str.replace(/[&<>"'`=/]/g, function (char) {
            return escapeMap[char];
        });
    },
    getViews(nodeParent) {
      try {
        // IGTV stats incorrect
        if (nodeParent.node.product_type !== 'igtv') {
          return nodeParent.node.video_view_count
        }
      } catch (err) {
        this.onError(err)
        return ''
      }
    },
    getLikes(nodeParent) {
      try {
        return nodeParent.node.edge_liked_by.count
      } catch (err) {
        this.onError(err)
        return ''
      }
    },
    getImage(nodeParent, imageIdx=4) {
      try {
        let image
        switch (nodeParent.node.__typename) {
            case "GraphSidecar":
                image = nodeParent.node.thumbnail_resources[imageIdx].src;
                break;
            case "GraphVideo":
                image = nodeParent.node.thumbnail_src
                break;
            default:
                image = nodeParent.node.thumbnail_resources[imageIdx].src;
        }

        return image
      } catch (err) {
        this.onError(err)
        return ''
      }
    },
    getCaption (igobj) {
      try {
        if (
            typeof igobj.node.edge_media_to_caption.edges[0] !== "undefined" && 
            typeof igobj.node.edge_media_to_caption.edges[0].node !== "undefined" && 
            typeof igobj.node.edge_media_to_caption.edges[0].node.text !== "undefined" && 
            igobj.node.edge_media_to_caption.edges[0].node.text !== null
        ) {
            return igobj.node.edge_media_to_caption.edges[0].node.text;
        }

        if (
            typeof igobj.node.title !== "undefined" &&
            igobj.node.title !== null &&
            igobj.node.title.length != 0
        ) {
            return igobj.node.title;
        }

        if (
            typeof igobj.node.accessibility_caption !== "undefined" &&
            igobj.node.accessibility_caption !== null &&
            igobj.node.accessibility_caption.length != 0
        ) {
            return igobj.node.accessibility_caption;
        }

        return ''
      } catch {
        return ''
      }
    }
  },
  render() {
    return this.$scopedSlots.default({
      info: this.info,
      media: this.media
    })
  },
}
</script>

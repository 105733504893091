<template>
  <div class='instafeed'>
    <instafeed :username="username" :tag="tag" sort="timestamp" :on-fetch="onFetch" :limit="18">
      <template v-slot="{ info, media }">
        <div style="width: 90vw; margin: 0 auto;">
          <div class='d-flex py-2'>
            <div class='d-flex justify-content-between flex-grow-1 p-0' :class='{"col-4": !isMobile}'>
              <span class='text-left'>
                <div class='h6r mb-1' style='padding-left:1px'>
                  <svg class="icon icon-instagram"><use xlink:href="#icon-users"></use></svg>
                  6510
                </div>
                <a :href="`https://instagram.com/notreal.ai`" class="h6r">
                  <svg class="icon icon-instagram"><use xlink:href="#icon-instagram"></use></svg>
                  @notreal.ai 
                </a>

              </span>

              <span class="align-self-end text-left">
                <a href="https://twitter.com/NotRealAI" class="h6r">
                  <font-awesome-icon :icon="['fab', 'twitter']"></font-awesome-icon>
                  @notrealAI
                </a>
              </span>

              <span class="align-self-end text-left h6r position-relative">
                <span class='position-absolute new-icon mr-auto'>new!</span>
                <a href="https://discord.gg/P77P66TdmB" class="position-relative">
                  <font-awesome-icon :icon="['fab', 'discord']"></font-awesome-icon>
                  discord
                </a>
              </span>
            </div>

            <span v-if="!isMobile" class='lh-1 align-self-end ml-auto text-muted futura-light'>🎵 videos play on hover</span>
          </div>

          <swiper class="swiper pb-4" :options="swiperOption" :auto-update="false" ref="swiper">
            <swiper-slide v-for="m in media" :key="m.url">
              <template v-if='isVideo(m)'>
                <a :href="m.url" target="_blank" v-if="!isMobile">
                  <!--<img class="mb-1 w-100 img-fluid rounded" :src="m.image" />-->
                  <video class="mb-1 w-100 img-fluid rounded" preload="auto" loop
                         @mouseover="play"
                         @mouseleave="pause"
                         :src="m.video" 
                         :poster="m.image">
                  </video>
                </a>

                <video class="mb-1 w-100 img-fluid rounded" preload="auto" loop playsinline v-if="isMobile"
                       @click="playpause"
                       :src="m.video" 
                       :poster="m.image">
                </video>
              </template>
              <template v-else>
                <a :href="m.url" target="_blank">
                  <img class='mb-1 w-100 img-fluid rounded' :src="m.image" />
                </a>
              </template>
              <a class='d-none' :href="m.url"><span>♡ {{m.likes}}</span></a>
              <span style='font-size: 10px' v-if="isMobile">▶ tap video to play</span>
              <span v-if="m.views">▶ {{m.views}}  </span>

              <a :href="m.url" target="_blank">
                <span class="float-right">♡ {{m.timestamp.toDateString()}}</span>
              </a>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </template>
    </instafeed>

    <svg style="position: absolute; width: 0; height: 0; overflow: hidden" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <symbol id="icon-instagram" viewBox="0 0 32 32">
      <path d="M16 2.881c4.275 0 4.781 0.019 6.462 0.094 1.563 0.069 2.406 0.331 2.969 0.55 0.744 0.288 1.281 0.638 1.837 1.194 0.563 0.563 0.906 1.094 1.2 1.838 0.219 0.563 0.481 1.412 0.55 2.969 0.075 1.688 0.094 2.194 0.094 6.463s-0.019 4.781-0.094 6.463c-0.069 1.563-0.331 2.406-0.55 2.969-0.288 0.744-0.637 1.281-1.194 1.837-0.563 0.563-1.094 0.906-1.837 1.2-0.563 0.219-1.413 0.481-2.969 0.55-1.688 0.075-2.194 0.094-6.463 0.094s-4.781-0.019-6.463-0.094c-1.563-0.069-2.406-0.331-2.969-0.55-0.744-0.288-1.281-0.637-1.838-1.194-0.563-0.563-0.906-1.094-1.2-1.837-0.219-0.563-0.481-1.413-0.55-2.969-0.075-1.688-0.094-2.194-0.094-6.463s0.019-4.781 0.094-6.463c0.069-1.563 0.331-2.406 0.55-2.969 0.288-0.744 0.638-1.281 1.194-1.838 0.563-0.563 1.094-0.906 1.838-1.2 0.563-0.219 1.412-0.481 2.969-0.55 1.681-0.075 2.188-0.094 6.463-0.094zM16 0c-4.344 0-4.887 0.019-6.594 0.094-1.7 0.075-2.869 0.35-3.881 0.744-1.056 0.412-1.95 0.956-2.837 1.85-0.894 0.888-1.438 1.781-1.85 2.831-0.394 1.019-0.669 2.181-0.744 3.881-0.075 1.713-0.094 2.256-0.094 6.6s0.019 4.887 0.094 6.594c0.075 1.7 0.35 2.869 0.744 3.881 0.413 1.056 0.956 1.95 1.85 2.837 0.887 0.887 1.781 1.438 2.831 1.844 1.019 0.394 2.181 0.669 3.881 0.744 1.706 0.075 2.25 0.094 6.594 0.094s4.888-0.019 6.594-0.094c1.7-0.075 2.869-0.35 3.881-0.744 1.050-0.406 1.944-0.956 2.831-1.844s1.438-1.781 1.844-2.831c0.394-1.019 0.669-2.181 0.744-3.881 0.075-1.706 0.094-2.25 0.094-6.594s-0.019-4.887-0.094-6.594c-0.075-1.7-0.35-2.869-0.744-3.881-0.394-1.063-0.938-1.956-1.831-2.844-0.887-0.887-1.781-1.438-2.831-1.844-1.019-0.394-2.181-0.669-3.881-0.744-1.712-0.081-2.256-0.1-6.6-0.1v0z"></path>
      <path d="M16 7.781c-4.537 0-8.219 3.681-8.219 8.219s3.681 8.219 8.219 8.219 8.219-3.681 8.219-8.219c0-4.537-3.681-8.219-8.219-8.219zM16 21.331c-2.944 0-5.331-2.387-5.331-5.331s2.387-5.331 5.331-5.331c2.944 0 5.331 2.387 5.331 5.331s-2.387 5.331-5.331 5.331z"></path>
      <path d="M26.462 7.456c0 1.060-0.859 1.919-1.919 1.919s-1.919-0.859-1.919-1.919c0-1.060 0.859-1.919 1.919-1.919s1.919 0.859 1.919 1.919z"></path>
      </symbol>
      <symbol id="icon-users" viewBox="0 0 24 24">
      <path d="M18 21v-2c0-1.38-0.561-2.632-1.464-3.536s-2.156-1.464-3.536-1.464h-8c-1.38 0-2.632 0.561-3.536 1.464s-1.464 2.156-1.464 3.536v2c0 0.552 0.448 1 1 1s1-0.448 1-1v-2c0-0.829 0.335-1.577 0.879-2.121s1.292-0.879 2.121-0.879h8c0.829 0 1.577 0.335 2.121 0.879s0.879 1.292 0.879 2.121v2c0 0.552 0.448 1 1 1s1-0.448 1-1zM14 7c0-1.38-0.561-2.632-1.464-3.536s-2.156-1.464-3.536-1.464-2.632 0.561-3.536 1.464-1.464 2.156-1.464 3.536 0.561 2.632 1.464 3.536 2.156 1.464 3.536 1.464 2.632-0.561 3.536-1.464 1.464-2.156 1.464-3.536zM12 7c0 0.829-0.335 1.577-0.879 2.121s-1.292 0.879-2.121 0.879-1.577-0.335-2.121-0.879-0.879-1.292-0.879-2.121 0.335-1.577 0.879-2.121 1.292-0.879 2.121-0.879 1.577 0.335 2.121 0.879 0.879 1.292 0.879 2.121zM24 21v-2c-0.001-1.245-0.457-2.385-1.215-3.261-0.652-0.753-1.528-1.311-2.529-1.576-0.534-0.141-1.081 0.177-1.222 0.711s0.177 1.081 0.711 1.222c0.607 0.161 1.136 0.498 1.528 0.952 0.454 0.526 0.726 1.206 0.727 1.952v2c0 0.552 0.448 1 1 1s1-0.448 1-1zM15.752 4.099c0.803 0.206 1.445 0.715 1.837 1.377s0.531 1.47 0.325 2.273c-0.176 0.688-0.575 1.256-1.105 1.652-0.314 0.235-0.675 0.409-1.063 0.511-0.534 0.14-0.854 0.687-0.713 1.221s0.687 0.854 1.221 0.713c0.637-0.167 1.232-0.455 1.752-0.844 0.884-0.66 1.552-1.613 1.845-2.758 0.342-1.337 0.11-2.689-0.542-3.788s-1.725-1.953-3.062-2.296c-0.535-0.137-1.080 0.186-1.217 0.721s0.186 1.080 0.721 1.217z"></path>
      </symbol>
    </defs>
    </svg>
  </div>
</template>
<script>
  import Instafeed from './Instafeed';
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import 'swiper/css/swiper.css'

  export default {
    name: 'instafeed-gallery',
    props: {
      username: { type: String },
      tag: { type: String }
    },
    components: {
      Instafeed,
      Swiper,
      SwiperSlide
    },
    data() {
      return {
        isMobile: /Mobi|Android/i.test(navigator.userAgent),
        swiperOption: {
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            dynamicBullets: true,
            dynamicMainBullets: 12
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            1024: {
              slidesPerView: 4,
              spaceBetween: 40
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 10
            }
          }
        },
        swiperOptionThumbs: {
          loop:true,
          loopedSlides: 5,
          spaceBetween: 18,
          centeredSlides: true,
          slidesPerView: 'auto',
          touchRatio: 0.2,
          slideToClickedSlide: true
        }
      };
    },
    methods: {
      isVideo(media) {
        return media.type == 'VIDEO'
      },
      onFetch(media) {
        this.$nextTick(() => this.swiper.update())
      },
      playpause(evt) {
        if(evt.target.paused) {
          evt.target.play()
        } else {
          evt.target.pause()
        }
      },
      play(evt) {
        const el = evt.target
        el.volume = 0.2
        el.play()
        //if(!(!this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2)) {this.play()}
      },
      pause(evt) {
        const el = evt.target
        el.pause()
        //onmouseout="this.pause();this.currentTime=0;"
      }
    },
    computed: {
      swiper() {
        return this.$refs.swiper.$swiper
      }
    },
    created() {
    },
    mounted() {
      //setTimeout(() => {
      //  this.swiper.update()
      //}, 5000)
    }
  };
</script>
<style scoped lang="scss">
 .icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  } 

  .instafeed {
    font-family: initial;
    font-size: initial;
  }

  .new-icon {
    background-color: red;
    color:white;
    border-radius: 2px;
    z-index: 0;
    font-size: 60%;
    top: -60%;
    left: 0;
    padding: 1px;
    font-family: arial;
  }
</style>



<template>
  <div class="place-edition-bid" v-if="shouldShowOffersPanel">

    <!-- Auction - Bid complete -->
    <div v-if="hasOpenBid && !inline">
      <div v-if="accountIsHighestBidder(edition)" class="text-center text-success h6r">
        <strong>Your bid is the highest!</strong>
      </div>
    </div>

    <fieldset>
      <form class="mx-auto">

        <template v-if="inline">
          <div v-visible="accountIsHighestBidder(edition)" class="text-center text-success lh-1 pt-1 t-0 d-none">
            <strong>Your bid is the highest!</strong>
          </div>


          <div class="input-group form-inline input-group-lg">
            <slot name="prepend"></slot>

            <template v-if="button == 'left'">
              <div class="input-group-prepend">
                <button class="btn btn-secondary btn-sm pull-right px-4 py-0 width-fit"
                        id="makeBidButton"
                        v-on:click="placeBid"
                        :disabled="!bid || txInFlight">
                  Bid

                  <font-awesome-icon v-if="isAuctionTriggered(edition.edition)" :icon="['fas', 'spinner']" spin></font-awesome-icon>
                </button>
              </div>

              <div class="input-group-prepend">
                <div class="input-group-text px-1 py-0">$</div>
              </div>

              <input type="number" 
                     class="form-control h-auto hide-arrows py-0 px-1 text-left" 
                     id="makeBidValue"
                     v-model="bid"/>

              <div class="input-group-append" v-if="showAlt">
                <div class="input-group-text px-1 py-0">
                  <u-s-d-price v-if='!inUsd' :price-in-ether="bid"></u-s-d-price>
                  <e-t-h-price v-if='inUsd' :price-in-usd="bid"></e-t-h-price>
                </div>
              </div>
            </template>

            <template v-if="button == 'right'">

              <div class="input-group-prepend" v-if="showAlt">
                <div class="input-group-text px-1 py-0">
                  <u-s-d-price v-if='!inUsd' :price-in-ether="bid"></u-s-d-price>
                  <e-t-h-price v-if='inUsd' :price-in-usd="bid"></e-t-h-price>
                </div>
              </div>


              <input type="number" 
                     :class="{'text-danger': bid < minBid}"
                     class="form-control h-auto hide-arrows py-0 px-1 text-left" 
                     id="makeBidValue"
                     v-model="bid"/>

              <!--
              <div class="input-group-append">
                <div class="input-group-text px-1 py-0">$</div>
              </div>
              -->

              <div class="input-group-append d-flex">
                <button class="btn btn-secondary btn-sm pull-right px-4 py-0 width-fit"
                        id="makeBidButton"
                        v-on:click="placeBid"
                        :disabled="!bid || isAuctionTriggered(edition.edition) || bid < minBid">
                  Bid

                  <font-awesome-icon v-if="isAuctionTriggered(edition.edition)" :icon="['fas', 'spinner']" spin></font-awesome-icon>
                </button>
              </div>
            </template>

            <slot name="append"></slot>
          </div>

        </template>

        <div class="input-group input-group-sm" v-if="!inline">


          <input type="number"
                 class="form-control"
                 id="makeBidValue"
                 :step="minBidAmount"
                 v-model="bid"/>

          <div class="input-group-append">
            <div class="input-group-text">ETH</div>
          </div>

        </div>

        <button class="btn btn-sm btn-secondary pull-right btn-block"
                v-if="!inline"
                v-on:click="placeBid"
                id="makeBidButton"
                :disabled="!bid || bid < minBidAmount">
          Bid 
          <u-s-d-price v-if="bid && bid >= minBidAmount" :price-in-ether="bid"></u-s-d-price>
          <span v-if="bid && bid < minBidAmount">(Min {{minBidAmount}}ETH)</span>
        </button>


      </form>
    </fieldset>


    <!-- Auction - Bid submitted -->
    <div v-if="!inline" class="text-sm-center">

      <!-- The triggered/started/failed bid states were originally commented out. Not sure the reason -->
      <div v-if="isAuctionTriggered(edition.edition)">
        <hr/>
        <div class="">
          Your bid has been submitted.<br/>Please be patient. Blockchains need to be mined.
          <font-awesome-icon :icon="['fas', 'cog']" spin></font-awesome-icon>
        </div>
        <div class="text-muted">
          <clickable-transaction
            :transaction="getTransactionForEdition(edition.edition, account)"></clickable-transaction>
        </div>
      </div>

      <div v-if="isAuctionStarted(edition.edition)">
        <hr/>
        <div class="h6r">
          Your bid is being confirmed.
          <font-awesome-icon :icon="['fas', 'cog']" spin></font-awesome-icon>
        </div>
        <div class="text-muted">
          <clickable-transaction
            :transaction="getTransactionForEdition(edition.edition, account)"></clickable-transaction>
        </div>
      </div>

      <div v-if="isAuctionFailed(edition.edition)">
        <hr/>
        <span class="card-text text-danger ">Your bid failed!</span>
        <img src="../../../public/static/Failure.svg" style="width: 25px"/>
      </div>

      <!-- Hide this for now -->
      <div class="text-center mb-2 d-none" v-if="isAuctionSuccessful(edition.edition, account)">
        <hr/>
        <small class="text-muted">
          <clickable-transaction
            :transaction="getTransactionForEdition(edition.edition, account)"></clickable-transaction>
        </small>
      </div>


    </div>

  </div>
</template>

<script>
  import {mapGetters, mapState} from 'vuex';
  import _ from 'lodash';
  import * as actions from '../../store/actions';
  import ClickableAddress from '../generic/ClickableAddress';
  import PriceInEth from '../generic/PriceInEth';
  import USDPrice from '../generic/USDPrice';
  import ETHPrice from '../generic/ETHPrice';
  import ClickableTransaction from '../generic/ClickableTransaction';
  import LoadingSpinner from '../generic/LoadingSpinner';
  import AccountBalance from '../modal/AccountBalance';

  export default {
    name: 'placeEditionBid',
    components: {
      AccountBalance,
      LoadingSpinner,
      ClickableTransaction,
      PriceInEth,
      ClickableAddress,
      USDPrice,
      ETHPrice
    },
    props: {
      edition: Object,
      inline: Boolean,
      show: {type: Boolean, default: true},
      inUsd: Boolean,
      showAlt: {type: Boolean, default: false},
      button: {type: String, default: "right"},
      minBid: {type: Number, default: null}
    },
    data() {
      return {
        form: {
          bid: this.minBid
        }
      };
    },
    computed: {
      ...mapState([
        'account',
        'auctionsService',
        'currentUsdPrice',
        'balance',
        // Removed usage of txInFlight for now,
        // as it is too stringent and causes button to be perma-disabled
        // when a tx doesn't go through, particularly on mobile
        'txInFlight'
      ]),
      ...mapGetters('auction', [
        'accountIsHighestBidder',
        'isEditionAuctionEnabled',
        'isAuctionTriggered',
        'isAuctionStarted',
        'isAuctionSuccessful',
        'isAuctionFailed',
        'getTransactionForEdition',
      ]),
      ...mapState('auction', [
        'auction',
        'bidState',
        'minBidAmount',
      ]),
      ...mapGetters('loading', [
        'isLoading'
      ]),
      bid: {
        set(newBid) {
          // Reset to minBid if form emptied
          if(!newBid && this.minBid) { this.form.bid = this.minBid }
          else { this.form.bid = newBid } 
        },
        get() {
          return this.form.bid
        }
        //get() {
        //  return parseFloat(this.form.bid).toFixed(2)
        //}
      },
      hasOpenBid() {
        return this.auction[this.edition.edition] && this.auction[this.edition.edition].highestBid !== '0';
      },
      shouldShowOffersPanel() {
        if (this.show) { return true }

        return this.isEditionAuctionEnabled(this.edition.edition);
      },
      bidEth() {
        const eth = this.form.bid / (this.inUsd ? this.currentUsdPrice : 1)
        return eth.toFixed(18)
      }
    },
    methods: {
      showAccountBalance() {
        this.$modal.show(AccountBalance, {}, {
          draggable: false,
          resizable: false,
          scrollable: true,
          adaptive: true,
          height: 'auto',
          width: '100%',
          maxWidth: 600,
          //minWidth: 300,
        })
      },
      placeBid(e) {
        e.preventDefault();
        // noop
        if(!this.form.bid) { return }

        // Show transfer dialog if no funds in app
        //if (!this.balance || !this.balance.token || this.balance.token == '0') {
        //  this.showAccountBalance()
        //  return false;
        //}

        let event, action;
        event = 'place-bid'
        action = `auction/${actions.PLACE_BID}`
        //if(this.accountIsHighestBidder(this.edition)) {
        //  event = 'increase-bid'
        //  action = `auction/${actions.INCREASE_BID}`
        //} else {
        //  event = 'place-bid'
        //  action = `auction/${actions.PLACE_BID}`
        //}

        let dispatchBid = () => {
          //console.log('dispatch bid')
          this.$ga.event('purchase-flow', 'auction', event);
          this.$store.dispatch(action, {
            edition: this.edition.edition,
            // Assuming 1 token per edition for now
            token: _.get(this.edition, 'tokens[0].tokenId'),
            value: this.bidEth.toString(),
          });
        }

        if (this.account) {
          dispatchBid()
        } else {
          this.$store.dispatch(actions.WALLET_CONNECT_LOGIN)
        }
      }
    },
    watch: {
      //'smartwallet': function (newVal, oldVal) {
      //  if (newVal && (newVal !== oldVal)) {
      //    if (this.pendingBid) {
      //      this.pendingBid()
      //      this.pendingBid = null
      //    }
      //  }
      //},
      'minBid': function() {
        this.form.bid = this.minBid
      }
    },
    created() {
      //console.log(this.inline, 'this.inline')
      //console.log('PlaceEditionBid')

      // NOTE 1: Need to get bid component off old auction module and start using Realtime firebase `allEditions` instead
      // NOTE 2: Remove these dispatches as they are wasteful since minBid fetched at global level, details at page level
      //const loadData = () => {
      //  this.$store.dispatch(`auction/${actions.GET_AUCTION_MIN_BID}`);
      //  this.$store.dispatch(`auction/${actions.GET_AUCTION_DETAILS}`, this.edition.edition);
      //};

      //this.$store.watch(
      //  () => this.auctionsService.currentNetworkId,
      //  () => loadData()
      //);

      //if (this.auctionsService.currentNetworkId) {
      //  loadData();
      //}

    },
  };
</script>

<style scoped lang="scss">

  .auction-info {
    font-size: 0.75rem;
  }

  .hide-arrows::-webkit-outer-spin-button,
  .hide-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  .hide-arrows[type=number] {
    -moz-appearance: textfield;
  }

  // .form-inline  {
  //   height: 30px;
  //   input::before {
  //     content: '$';
  //     position: absolute;
  //     left: 0;
  //   }
  //   .btn {
  //     border: none;
  //     box-shadow: none;
  //   }
  // }

</style>

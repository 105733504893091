<template>
  <div style='overflow: hidden;' class='h-100'>
    <studio-picker :start-page='startPage'></studio-picker>
    <div class="feed-container gutter px-4 pt-4" >
      <landing v-if="$remoteConfig.version != 'nomarket'"></landing>

      <div class="row">
        <div class="d-flex flex-column w-100 justify-content-between align-items-center">
          <div class='display-3r sohne-var text-center d-block d-flex flex-column lh-1 w-100'>

            <instafeed-gallery class="mt-4 text-left" username="notreal.ai"></instafeed-gallery>

            <div class='d-flex justify-content-center py-4'>ai art is <div class='rotate-text'>
                <span>cool</span>
                <span>kook</span>
                <span>neat</span>
                <span>fun</span>
                <span>trip</span>
                <span>drip</span>
                <span>dope</span>
                <span>zany</span>
                <span>swag</span>
              </div> af<span class='op-50'>.</span>
            </div>

            <hr class='d-block p-0 w-25' />

            <div class='text-center my-1 py-2 align-self-center font-weight-light futura-light' style='font-size: 50%; width: 15em; line-height: 0.75;'>
              Stunning digital and AI art posted daily. 
              <br /><br />
              Follow us for updates and community.
              <br />
            </div>

            <email-form class='mx-auto'></email-form>

            <!--
            <b-input-group class='col-4 mx-auto'>
              <input v-model='form.email' class='form-control' placeholder='your email here' />
              <b-button @click="saveEmail()" type="button" variant="primary">
                Join the club 
              </b-button>
            </b-input-group>
            <span class='h6r futura-light font-weight-light mt-4 text-muted'>max 1 - 4 emails/month. unsubscribe anytime.</span>
            -->

          </div>
        </div>
      </div>

      <div class='row mt-3 mb-5' v-if="$remoteConfig.version == 'nomarket'">
        <div class='display-4r sohne-var text-center lh-1 w-100'>
          and coming soon...
        </div>
      </div>

      <landing v-if="$remoteConfig.version == 'nomarket'"></landing>


      <!-- commented out for now but this is important reference code for image gallery -->
      <div class='d-none'>
        <!-- Start: Latest -->
        <div class="row editions-wrap">
          <div class="col-12">
            <h2 class="mb-4 mt-5 header-title">
              <span title="New artworks landing on the platform">
                Latest
              </span>
            </h2>
          </div>
          <div class="mx-auto mt-3">
            <loading-spinner v-if="(latest || []).length === 0"></loading-spinner>
          </div>
          <div class="card-deck" v-if="(latest || []).length > 0 && assets">
            <div class="col-sm-4 mb-5"
                 v-for="edition in latest" :key="edition.edition">
              <gallery-card :edition="edition" :edition-number="edition.edition"></gallery-card>
            </div>
          </div>
        </div>

        <div class="row editions-wrap">
          <div class="col mx-auto col-auto" v-if="(latest || []).length > 0 && assets">
            <router-link :to="{ name: 'gallery' }" class="btn btn-outline-primary">
              View all
            </router-link>
          </div>
        </div>

        <!-- Start: Trending -->
        <div class="row editions-wrap">
          <div class="col-12">
            <h2 class="mb-4 mt-5 header-title">
              <span title="Whats hot on the platform, a selection of recent sales and bids">
                Recent activity
              </span>
            </h2>
          </div>
          <div class="mx-auto mt-3">
            <loading-spinner v-if="(trending || []).length === 0"></loading-spinner>
          </div>
          <div class="card-deck" v-if="(trending || []).length > 0 && assets">
            <div class="col-sm-4 mb-5"
                 v-for="editionNumber in trending" :key="editionNumber"
                 v-if="assets[editionNumber] && assets[editionNumber].active">
              <gallery-card :edition="assets[editionNumber]" :edition-number="editionNumber">
              </gallery-card>
            </div>
          </div>
        </div>

        <div class="row editions-wrap">
          <div class="col col-auto mx-auto mb-5" v-if="(trending || []).length > 0 && assets">
            <router-link :to="{ name: 'activity' }" class="btn btn-outline-primary">
              View all
            </router-link>
          </div>
        </div>

      </div>

      <div class="row editions-wrap d-none">
        <div class="col-auto mx-auto text-center p-5 col-10 col-lg-6">
          <h1>We are The Not Real Company</h1>
          <h2>We're building an A.I. art marketplace</h2>
          <h3>Create. Collect. Sell. Trade up.</h3>
          <!-- temp for demo -->
          <p class='d-none'>
            <router-link :to="{ name: 'gallery' }" class="btn btn-primary">
              Start collecting
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import * as _ from 'lodash';
  import {mapState} from 'vuex';
  import * as mutation from '../store/mutation';
  import * as localforage from 'localforage'
  import GalleryCard from '../components/cards/GalleryCard';
  //import GalleryCard from '../components/cards/GalleryCard';
  import LoadingSpinner from '../components/generic/LoadingSpinner';
  //import HeaderBanner from '../components/feed/HeaderBanner';
  import Landing from '../components/feed/Landing';
  import StudioPicker from '../components/studio/StudioPicker';
  import InstafeedGallery from '../components/insta/InstafeedGallery';
  import EmailForm from '../components/feed/EmailForm';

  //import { ResizeSensor } from 'css-element-queries'  
  import * as actions from '../store/actions';

  //import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  //import 'swiper/css/swiper.css'

  import { BInputGroup, BButton } from 'bootstrap-vue';

  const q = (s) => document.querySelector(s)
  const qa = (s) => [...document.querySelectorAll(s)]

  export default {
    name: 'feed',

    components: {
      //HeaderBanner,
      EmailForm,
      Landing,
      LoadingSpinner,
      GalleryCard,
      StudioPicker,
      InstafeedGallery,
      //Swiper,
      //SwiperSlide,
      ...{ BInputGroup, BButton }
    },
    props:['startPage'],
    data() {
      return {
        latest: [],
        trending: [],
        form: {
          email: null
        },
        //swiperOption: {
        //  loop: true,
        //  pagination: {
        //    el: '.swiper-pagination',
        //    type: 'bullets'
        //  },
        //  navigation: {
        //    nextEl: '.swiper-button-next',
        //    prevEl: '.swiper-button-prev',
        //  },
        //  scrollbar: {
        //    el: '.swiper-scrollbar',
        //  },
        //  breakpoints: {
        //    1024: {
        //      initialSlide: 1,
        //      slidesPerView: 3,
        //      spaceBetween: 10
        //    },
        //    768: {
        //      initialSlide: 1,
        //      slidesPerView: 3,
        //      spaceBetween: 10
        //    },
        //    640: {
        //      initialSlide: 1,
        //      slidesPerView: 1,
        //      spaceBetween: 10
        //    },
        //    320: {
        //      initialSlide: 1,
        //      slidesPerView: 1,
        //      spaceBetween: 10
        //    }
        //  }
        //},
        //swiperOptionThumbs: {
        //  loop:true,
        //  loopedSlides: 5,
        //  spaceBetween: 18,
        //  centeredSlides: true,
        //  slidesPerView: 'auto',
        //  touchRatio: 0.2,
        //  slideToClickedSlide: true
        //}
      };
    },
    methods: {
      saveEmail() {
        this.$firebase.auth().signInAnonymously()
          .then((r) => {
            // console.log('signin', r)
          })
          .catch((error) => {
            // console.log('signin error', error)
          })

        this.$firebase.auth().onAuthStateChanged(user => {
          // console.log('authstatechanged', user)
          const random = (+new Date * Math.random()).toString(36).substring(0,20)
          const credential = this.$firebase.auth.EmailAuthProvider.credential(this.form.email,random);
          user.linkWithCredential(credential)
            .then(usercred => {
              // console.log('linked', usercred)
            }).catch(err => {
              // console.log('linkederror', err)
            })
        })
      },
      // This is duplicated in studio picker too. Oh well.
      windowResized() { 
        let fnt = this.rootFontSize()
        q('.feed-container').style.fontSize = `${fnt}px`
      },
      rootFontSize() {
        let vh = window.innerHeight * 0.01;
        let vw = window.innerWidth * 0.01;
        let ar = window.innerWidth / window.innerHeight
      
        let fnt
        if (ar <= 4/7) {
          fnt = vw
        } else if (ar <= 1) {
          fnt = vw * (1/ar)
        } else if (ar > 1) {
          fnt = vw - vh
        }

        return fnt
      },
    },
    computed: {
      ...mapState([
        'feedService',
        'editionLookupService',
        'currentUsdPrice'
      ]),
      ...mapState('kodaV2', [
        'assets',
      ]),
    },
    mounted() {
      //console.log(this.latest.length)
    },
    createdX() {
      //window.refs = this.$refs
      //new ResizeSensor(document.documentElement, _.debounce(this.windowResized, 200))
      //let store = localforage.createInstance({name: 'rankings'})
      this.formatRankings()

      /////////
      // API //
      /////////
      //
      //


      const loadEventData = () => {

        this.editionLookupService.getGalleryEditions({orderBy: 'edition', order: 'asc', limit: 24, active: true})
          .then((results) => {
            if (results.success) {
              const {data} = results;
              this.latest = data;
              this.$refs.editionSwiper.swiperInstance.slideToLoop(this.latest.length)
              this.$store.commit(`kodaV2/${mutation.SET_EDITIONS}`, data);
            }
          }).catch(e => {
          })


        // Old code loading latest/trending editions
        /*
        this.feedService.latestCreations(15)
          .then((results) => {
            if (results.success) {
              const {data} = results;
              this.latest = data;
              // This is a necessary hack to get vue/swiper to render the card like buttons without interaction
              this.$refs.editionSwiper.swiperInstance.slideToLoop(this.latest.length)
              this.$store.commit(`kodaV2/${mutation.SET_EDITIONS}`, data);
            }
          }).catch(e => {
            //alert(JSON.stringify(e))
          })

        this.feedService.trendingEditions(12)
          .then((results) => {
            // console.log(results);
            if (results.success) {
              const {data} = results;
              this.trending = _.uniq(_.map(data, 'edition'));
              this.$store.commit(`kodaV2/${mutation.SET_EDITIONS}`, data);
            }
          });
          */
      };

      this.$store.watch(
        () => this.feedService.currentNetworkId,
        () => loadEventData()
      );

      if (this.feedService.currentNetworkId) {
        loadEventData();
      }
    },
  };
</script>
<style lang="scss">
  nav.navbar {
    display: none;
  }
  .navbar-brand {
    display: none;
  }

  
  $feed-bg: #f6f9fc;
  body {
    background: $feed-bg;
  }
</style>
<style scoped lang="scss">
  @import '../ko-colours.scss';
  @import '../ko-card.scss';
  @import '../styles/utils.scss';

  @mixin portrait {
    @media screen and (max-aspect-ratio: 1/1) {
      @content;
    }
  }

  @include portrait {
    .feed-container {
      padding-bottom: 3rem;
      margin-bottom: 3rem;
    }
  }


  .btn {
    width: 10rem;
    font-size: 1rem;
    font-family: 'Roboto 400', sans-serif;
  }

  .btn-sm {
    width: 7rem;
    font-size: 0.8rem;
    font-family: 'Roboto 400', sans-serif;
  }


  .join-banner {
    background-image: url('../../public/static/KOJoin_patt.svg');
    background-size: auto;
  }

  
  .rotate-text {
    display: inline;
    position: relative;
    width: 3em;
    overflow: hidden;
    height: 1.1em;
    span {
      position: absolute;
      opacity: 0;
      width: 100%;
      left:0;

      // Having an animation delay of 0 on the first child causes it to start slightl out of step with the other animations, and they start colliding
      // 0.001s fixes this

      &:nth-child(1) { @include animation('rotateWord 36s ease-in-out 0.001s infinite normal  both running'); }
      &:nth-child(2) { @include animation('rotateWord 36s ease-in-out 4s infinite normal  both running'); }
      &:nth-child(3) { @include animation('rotateWord 36s ease-in-out 8s infinite normal  both running'); }
      &:nth-child(4) { @include animation('rotateWord 36s ease-in-out 12s infinite normal both running'); }
      &:nth-child(5) { @include animation('rotateWord 36s ease-in-out 16s infinite normal both running'); }
      &:nth-child(6) { @include animation('rotateWord 36s ease-in-out 20s infinite normal both running'); }
      &:nth-child(7) { @include animation('rotateWord 36s ease-in-out 24s infinite normal both running'); }
      &:nth-child(8) { @include animation('rotateWord 36s ease-in-out 28s infinite normal both running'); }
      &:nth-child(9) { @include animation('rotateWord 36s ease-in-out 32s infinite normal both running'); }
    }
  }

  @include keyframes(rotateWord) {
    0% {      opacity: 0; -webkit-transform: translate3d(0, -125%, 0); transform: translateY(0, -125%, 0 ); }
    1.332% {  opacity: 1; -webkit-transform: translate3d(0, -125%, 0); transform: translateY(0, -125%, 0 ); }

    /* slide in */
    1.333% {  opacity: 1; -webkit-transform: translate3d(0, -125%, 0); transform: translateY(0, -125%, 0 ); }

    /* pause */
    3.333% {  opacity: 1; -webkit-transform: translate3d(0, 0px, 0);   transform: translateY(0,   0px, 0 );}
    11.333% { opacity: 1; -webkit-transform: translate3d(0, 0px, 0);   transform: translateY(0,   0px, 0 ); }

    /* slide out */
    13.333% { opacity: 1; -webkit-transform: translate3d(0,  125%, 0); transform: translateY(0,  125%, 0 ); }
    /* Flip to start */
    13.334% { opacity: 0; -webkit-transform: translate3d(0, -125%, 0); transform: translateY(0, -125%, 0 ); }
    13.335% { opacity: 0; -webkit-transform: translate3d(0, -125%, 0); transform: translateY(0, -125%, 0 ); }
    100% {    opacity: 0; -webkit-transform: translate3d(0, -125%, 0); transform: translateY(0, -125%, 0 ); }
  }

  $feed-bg: #f6f9fc;

  .feed-container {
    background: $feed-bg;
  }




</style>

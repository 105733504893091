<template>
  <div class="grid-sq h6r align-items-center 
            overflow-hidden justify-content-between position-relative" 
     :style="{'background-image': `url(${cacheImageSrc})`}"
     :class="{ 'grid-sq-hover': selected, 'filter-grayscale': auctionStatus(edition) == 'burned' }">

    <div v-if="editionUnclaimed(edition)" class='winner-overlay'>
      <span>🎁</span>
    </div>


    <!-- TOP -->
    <p class='position-absolute justify-content-start align-self-start lh-1 w-100 d-flex align-items-start' style='white-space: pre; font-size: 85%;'>
      <span class='d-flex flex-column label-white pl-1'>
        <!-- Edition number -->
        <span style='line-height: 0.8'>
          <span :class="{strikethrough: auctionStatus(edition) == 'burned'}">
            <span style='font-size: 65%'>№</span>
            <span style='font-size: 75%'>{{ edition && edition.pseudoEdition }}</span>
          </span>
          <span class='mix-multiply' style='font-size: 55%'>
            {{ emojiStatus(edition) }}
          </span>
          <!--<i style='font-size:70%' v-if="editionStatus(edition) != 'past' && accountIsHighestBidder(edition.edition)">🥇</i>-->
          <i style='font-size:55%' v-if="account && accountIsEditionWinner(edition)">🏆</i>
        </span>

        <!-- Price it sold for -->
        <u-s-d-price class='futura-light' v-if="auctionStatus(edition) == 'sold'"  style='font-size:50%;' :price-in-ether="acceptedBid(edition)" :show-zero="false"></u-s-d-price> 
        <u-s-d-price class='futura-light' v-else-if="auctionStatus(edition) == 'mintsold'"  style='font-size:50%;' :price-in-ether="editionHighBid(edition)" :show-zero="false"></u-s-d-price> 

        <!-- Auction timer -->
        <span class='b612 auction-timer'  v-else-if="editionStatus(edition) == 'active'">
          <p style='font-size: 50%; vertical-align: middle;'>{{ dateTimeStatus(edition) }}</p>
        </span>


      </span>

      <span class='ml-auto d-flex flex-column align-items-end'>
        <!-- Lock indicator -->
        <span class='text-left lh-1_2' style='padding: 1% 1%; height: auto;'>
          <span v-show="selected && locked" class='p-1'><font-awesome-icon class='text-white' size='xs' :icon="['fa', 'lock']"></font-awesome-icon></span>
          <span v-show="selected && !locked" class='p-1'><font-awesome-icon class='text-white' size='xs' :icon="['fa', 'lock-open']"></font-awesome-icon></span>
          <!-- moved countdown timer to top of status -->
          <!--
          <span class='b612'  v-if="editionStatus(edition) != 'past'">
            <p class='label-white' style='font-size: 60%; vertical-align: middle;'>{{ dateTimeStatus(edition) }}</p>
          </span>
          -->
        </span>


        <!--
        <span class='label-white lh-1_2'>
          <span class='mix-multiply' style='font-size: 65%'>
            {{ emojiStatus(edition) }}
          </span>
          <i style='font-size:70%' v-if="editionStatus(edition) != 'past' && accountIsHighestBidder(edition.edition)">🥇</i>
          <i style='font-size:70%' v-if="account && accountIsEditionWinner(edition)">🏆</i>
        </span>
        -->

        <!--
        <u-s-d-price class='label-white futura-light' v-if="auctionStatus(edition) == 'sold'"  style='font-size:75%;' :price-in-ether="acceptedBid(edition)" :show-zero="false"></u-s-d-price> 
        <u-s-d-price class='label-white futura-light' v-if="auctionStatus(edition) == 'mintsold'"  style='font-size:75%;' :price-in-ether="editionHighBid(edition)" :show-zero="false"></u-s-d-price> 

        <span class='b612'  v-if="editionStatus(edition) != 'past'">
          <p class='label-white p-1' style='font-size: 60%; vertical-align: middle;'>{{ dateTimeStatus(edition) }}</p>
        </span>
        -->




      </span>
    </p>

    <!-- BOTTOM -->
    <p class='position-absolute b-0 justify-content-start align-self-start lh-1 w-100 d-flex align-items-end' style='white-space: pre; font-size: 85%;'>
      <!-- Auction timer -->
      <span class='b612 label-white p-1'  v-if="editionStatus(edition) == 'future'">
        <p style='font-size: 50%; vertical-align: middle;'>{{ dateTimeStatus(edition) }}</p>
      </span>
      <span class='bg-white op-75 pl-1 pt-1' style='font-size: 65%;' v-if="['sold','mint'].includes(auctionStatus(edition)) && editionHighBid(edition)">
        <u-s-d-price :price-in-ether="editionHighBid(edition)" :show-zero="auctionStatus(edition) =='mint'"></u-s-d-price> 
        <span v-if='accountIsHighestBidder(edition) && !accountIsOwner(edition)'>🥇</span>
        <!--<span style='font-size: 75%'>Ξ{{ editionHighBid(edition) }}</span>-->
      </span>



    </p>

  </div>
</template>

<script>
  import USDPrice from '../generic/USDPrice';
  import { cachefetchImage } from '../../services/workers/cachefetch.worker';
  import { ipfs32 } from  '../../utils'
  import {mapGetters, mapState} from 'vuex';

  export default {
    name: 'edition-cell',
    props: ['edition','selected','emojiStatus','auctionStatus', 'editionStatus', 'editionHighBid', 'dateTimeStatus', 'editionUnclaimed', 'accountIsEditionWinner', 'acceptedBid', 'locked'],
    computed: {
      ...mapState([
        'account'
      ]),
      ...mapGetters('auction', [
        'accountIsHighestBidder',
        'accountIsOwner',
        'artistIsOwner'
      ]),
    },
    components: {
      USDPrice
    },
    methods: {
      showBidPrice(edition) {
        this.editionHighBid(edition) 
        return this.editionStatus(edition) == 'active'
      },

    },
    mounted() {
      cachefetchImage(ipfs32(this.edition.lowResImg)).then(cached => {
        this.cacheImageSrc = cached
      })
    },
    data() {
      return {cacheImageSrc: this.edition.lowResImg}
    }
  }
</script>

<style scoped lang="scss">
  .label-white {
    //opacity: 0.5;
  }
  .grid-sq-hover .label-white {
    opacity: 1;
  }

  .winner-overlay {
    position:absolute;
    height: 100%;
    width: 100%;
    z-index: 10;
    font-size: 220%;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // Not actually styled, but here just to indicate
  // that it is significant as it is used as a target for onboarding walkthrough
  .auction-timer { }
</style>
